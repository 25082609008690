// Attaches user details to the Logrocket session recording.
import LogRocket from 'logrocket'


const useSetLogrocketIdentifiers = user => {
    user && LogRocket.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        company: user.companyId
    })
}


export default useSetLogrocketIdentifiers

