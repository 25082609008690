import { createContext, useState, useContext } from 'react'
import { googleEventTracker } from '../shared/GoogleAnalyticsTracking'
import { authAxios } from '../shared/CustomAxios'

const snapshotBaseURL = '/v1/ioc/snapshots'
const snapshotBaseURLv2 = '/v2/ioc/snapshots'

const SnapshotContextV2 = createContext()
export const useSnapshots = () => useContext(SnapshotContextV2)

export const SnapshotsProviderV2 = ({ children }) => {
    const [usersSnapshots, setUsersSnapshots] = useState([])
    const [groupedSnapshots , setGroupedSnapshots] = useState([])
    const [recentSnapshots , setRecentSnapshots] = useState([])
    const [mostRecentSelfEvaluation, setMostRecentSelfEvaluation] = useState({})
    const [canTakeSnapshot , setCanTakeSnapshot] = useState(false)
    const [snapshotMapData , setSnapshotMapData] = useState([])
    const [dateOfLastSnapshot, setDateOfLastSnapshot] = useState('')
    const [nextSelfAssessmentDate, setNextSelfAssessmentDate] = useState('')
    const [availableSnapshotCohortList, setAvailableSnapshotCohortList] = useState([])
    // Feedback & Reminder Emails
    const [isShowingSnapshotEmailModal , setIsShowingSnapshotEmailModal] = useState(false)
    const [isFeedbackEmail , setIsFeedbackEmail ] = useState(false)
    const [isReminderEmail , setIsReminderEmail ] = useState(false)
    const [emailBody, setEmailBody] = useState('')
    const [emailSubject, setEmailSubject] = useState('')
    const [emailRecipient, setEmailRecipient] = useState([])
    const [emailStatus, setEmailStatus] = useState('')
    const [emailSent, setEmailSent] = useState(false)

    
    // // LMS Snapshot Report
    // const [lmsSnapshotsList , setLmsSnapshotsList] = useState([])
    // const [lmsPdfData , setLmsPdfData] = useState([])
    // const [lmsPdfTextResponses , setLmsPdfTextResponses] = useState([])
    // const [lmsReportLoading , setLmsReportLoading] = useState(true)
    // const [lmsReportError , setLmsReportError] = useState(false)
    // const [lmsReportErrorMessage , setLmsReportErrorMessage] = useState('')
    // const [lmsReportDate , setLmsReportDate] = useState('')
    // const [lmsFooterInfo , setLmsFooterInfo] = useState('')
    // const [snapshotsRespondents , setSnapshotsRespondents] = useState(0)
    // // Module Rework
    // // Cohort/Group Snapshots
    // const [availableSnapshotCohortList , setAvailableSnapshotCohortList] = useState([])
    // const [isGroupSnapshot , setIsGroupSnapshot] = useState(false)
    // // IOC Snapshot Report
    // const [snapshotReportData , setSnapshotReportData] = useState([])
    // const [overallReportScores , setOverallReportScores] = useState([])
    // const [snapshotReportCritiqueComments , setSnapshotReportCritiqueComments] = useState([])
    // const [snapshotReportComplimentComments , setSnapshotReportComplimentComments] = useState([])
    // const [snapshotReportLoading , setSnapshotReportLoading] = useState(true)
    // const [snapshotReportError , setSnapshotReportError] = useState(false)
    // const [snapshotReportErrorMessage , setSnapshotReportErrorMessage] = useState('')
    // const [snapshotReportInfo , setSnapshotReportInfo] = useState({})

    const getUsersSnapshots = async (userId, snapshotVersion) => {
        try {
            let res = await authAxios.get(`${snapshotBaseURL}/survey-questions?userId=${userId}&surveyVersion=${snapshotVersion}`)
            if(res.data.length > 0){
                setUsersSnapshots(res.data)
                let snapshotGroups = await groupUsersSnapshots(res.data)
                setGroupedSnapshots(snapshotGroups)
                setRecentSnapshots(snapshotGroups.slice(0, 3))
            } else {
                // if there are no snapshots they can take it.
                setCanTakeSnapshot(true)
            }
        } catch (err) {
            setCanTakeSnapshot(false)
            console.log(err)
        }
    }

    // check the last snapshot taken and see if the appropriate amount of time has passed
    const checkSnapshotAvailability = async (userId, snapshotVersion) => {
        try {
            let res = await authAxios.get(`${snapshotBaseURLv2}/snapshot-availability?userId=${userId}&surveyVersion=${snapshotVersion}`)
            setCanTakeSnapshot(res.data.isAvailable)
            setDateOfLastSnapshot(res.data.lastTaken)
            setNextSelfAssessmentDate(res.data.nextAvailable)
        } catch (err) {
            setCanTakeSnapshot(false)
            console.log(err)
        }
    }

    const groupUsersSnapshots = async (snapshots) => {
        return Object.values(
            await snapshots.reduce((r, o) => {
                (r[o.containerId] = r[o.containerId] || []).push(o)
                return r;
            }, {})
        )
    }

    const getCohortsSnapshots = async (user, snapshotVersion) => {
        let product = snapshotVersion === 1 ? 'hasCoaching' : 'hasGrowCoaching'
        let url = `${snapshotBaseURLv2}/group-report-list`
        if(!user.isIodSuperAdmin){
            url = `${url}?adminId=${user.id}&product=${product}`
        } else {
            url = `${url}?product=${product}`
        } 
        // use the userIds from tagged participants to find snapshots taken since the tag was created and sort by date. Grabbing only since tag was created will filter out older snapshots the user may have taken.
        try {
            let res = await authAxios.get(url)
            let tagsWithParticipants = res.data.filter(tag => tag.TaggedParticipants.length > 0)
            setAvailableSnapshotCohortList(tagsWithParticipants)
        } catch (err) {
            console.log(err)
        }
    }

    const initializeSnapshotMapData = async (snapshotGroup) => {
        let mapData = []
        if(snapshotGroup.length > 0){
            // Used on recent map or full history map
            mapData = await snapshotGroup.map(group => {
                // find the amount of responses before filtering
                let responses = group.length - 1
                // filter out to only have the self assessments - there will always be 1
                let filteredSnapshots = group.filter(snapshot => snapshot.isSelfEvaluation)[0]
                // add responses key back in
                filteredSnapshots.responses = responses
                return filteredSnapshots
            })
        }

        let lastSnapshot = mapData[0] ? mapData[0] : []
        let lastSnapshotDate = lastSnapshot.createdAt ? lastSnapshot.createdAt : new Date()
        let formattedLastSnapshot = new Date(lastSnapshotDate).toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"})

        setMostRecentSelfEvaluation(lastSnapshot)
        setDateOfLastSnapshot(formattedLastSnapshot)
        setSnapshotMapData(mapData)
        // use the last date taken to find out when the next snapshot will be available
        setNextSnapshotAvailable(lastSnapshotDate)
    }

    const clearSnapshotData = () => {
        setUsersSnapshots([])
        setGroupedSnapshots([])
        setRecentSnapshots([])
        setMostRecentSelfEvaluation({})
        setCanTakeSnapshot(false)
        setSnapshotMapData([])
        setDateOfLastSnapshot('')
        setNextSelfAssessmentDate('')
        setAvailableSnapshotCohortList([])
    }

    const setNextSnapshotAvailable = (lastSnapshotDate) => {
        let today = new Date()
        let dateToCheckAgainst = new Date(lastSnapshotDate)
        let nextAvailable = new Date(dateToCheckAgainst.setMonth(dateToCheckAgainst.getMonth()+6))
        let canTakeSelfAssessment = +nextAvailable <= +today

        const formattedNextSnapshot = new Date(nextAvailable).toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"})

        setCanTakeSnapshot(canTakeSelfAssessment)
        setNextSelfAssessmentDate(formattedNextSnapshot)
    }

    const toggleSnapshotEmailReminderTemplate = (e) => {
        e.preventDefault()
        googleEventTracker('Snapshot', 'User opened send feedback reminder email modal', 'Feedback Reminder')

        setIsReminderEmail(true)
        setIsShowingSnapshotEmailModal(true)
    }

    const toggleSnapshotEmailTemplate = () => {
        googleEventTracker('Snapshot', 'User opened ask for feedback email modal', 'Ask for Feedback')
        setIsFeedbackEmail(true)
        setIsShowingSnapshotEmailModal(true)
    }

    const closeSnapshotEmailModal = () => {
        setIsReminderEmail(false)
        setIsFeedbackEmail(false)
        setIsShowingSnapshotEmailModal(false)
        setEmailStatus('')
        setEmailSent(false)
        setEmailRecipient([])
    }

    const getMostRecentContainerId = async (userId, snapshotVersion) => {
        try {
            let { data } = await authAxios.get(`${snapshotBaseURL}/survey-questions?userId=${userId}&surveyVersion=${snapshotVersion}&isSelfEvaluation=1`)
            if(data.length > 0){
                return data[0].containerId
            } else {
                return null
            }
        } catch (error) {
            console.log(error)
        }
    }

    const sendSnapshotEmail = async (snapshotVersion, user) => {
        let emailPurpose = ''
        if(isReminderEmail) emailPurpose = 'snapshot reminder email'
        if(isFeedbackEmail) emailPurpose = 'snapshot feedback email'
        // get containerId from most recent self assessment
        let containerId = await getMostRecentContainerId(user.id, snapshotVersion)

        const body = { 
            snapshotVersion: snapshotVersion,
            containerId: containerId,
            emailRecipient: emailRecipient, 
            emailSender: user.email, 
            emailBody: emailBody, 
            emailSubject: emailSubject, 
            emailPurpose 
        }

        if(user.id && emailRecipient){
            try {
                let res = await authAxios.post(`${snapshotBaseURL}/request-feedback/`, body)
                setEmailStatus(res.data.msg)
                setEmailSent(true)
            } catch (error) {
                setEmailStatus(error.response.data.msg)
                throw error
            }
        } else {
            setEmailStatus('Unable to send email.  Please try again and contact InsideOut Technical support if this problem persists.')
        }
    }

    const setDefaultEmailMessages = (subject, body) => {
        setEmailSubject(subject)
        setEmailBody(body)
    }

    const handleMultipleEmailChange = (items) => {
        setEmailRecipient(items)
    }


    return (
        <SnapshotContextV2.Provider 
            value={{
                // State & setState
                usersSnapshots,
                groupedSnapshots,
                mostRecentSelfEvaluation,
                recentSnapshots,
                snapshotMapData,
                canTakeSnapshot,
                dateOfLastSnapshot,
                nextSelfAssessmentDate,
                availableSnapshotCohortList,
                isShowingSnapshotEmailModal,
                isFeedbackEmail,
                isReminderEmail,
                emailBody,
                emailSubject,
                emailRecipient,
                emailStatus,
                emailSent,
                setEmailSubject,
                setEmailBody,
                // Functions
                getUsersSnapshots,
                checkSnapshotAvailability,
                getCohortsSnapshots,
                initializeSnapshotMapData,
                toggleSnapshotEmailReminderTemplate,
                toggleSnapshotEmailTemplate,
                closeSnapshotEmailModal,
                sendSnapshotEmail,
                setDefaultEmailMessages,
                handleMultipleEmailChange,
                clearSnapshotData
            }}>
            { children }
        </SnapshotContextV2.Provider>
    )
}
