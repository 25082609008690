import { useState, createContext, useContext } from 'react'


const UtilityContext = createContext()
export const useUtility = () => useContext(UtilityContext)

export const UtilityProvider = ({ children }) => {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

    const toggleConfirmModal = () => {
        setIsConfirmModalOpen(!isConfirmModalOpen)
    }


    return (
        <UtilityContext.Provider 
            value={{
                isConfirmModalOpen,
                toggleConfirmModal
            }}>
            { children }
        </UtilityContext.Provider>
    )
}
