import { useState, createContext, useContext } from 'react'
import { authAxios } from '../shared/CustomAxios'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'
import moment from 'moment'
import pulseReminderEmail from '../shared/EmailTemplates/pulseReminderEmail'
import sendNodeMailer from '../shared/HelperFunctions/sendNodeMailer'
import { iodFrontEndUrl } from '../config/Config'


const pulseBaseURL = '/v1/api/a2b/pulse'
const PulseContext = createContext()
export const usePulseV2 = () => useContext(PulseContext)

export const PulseProviderV2 = ({ children }) => {
    const [pulseHistory, setPulseHistory] = useState([])
    const [historyDates, setHistoryDates] = useState([])
    const [historyPulse, setHistoryPulse] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [initialPulse, setInitialPulse] = useState({})
    const [urlPulse, setUrlPulse] = useState({})
    const [pulseScore, setPulseScore] = useState(0)
    const [pulseQuestion, setPulseQuestion] = useState('')
    const [pulseMessage, setPulseMessage] = useState('')
    const [pulseError, setPulseError] = useState(null)
    const [departmentPulses, setDepartmentPulses] = useState([])
    const [viewingAnalytics, setViewingAnalytics] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [loggedInUsersPulseInfo, setLoggedInUsersPulseInfo] = useState({
        id: '',
        reviewedEmployees: false
    })
    // Analytics
    const [allFilteredPulses, setAllFilteredPulses] = useState([])
    const [completionCounts, setCompletionCounts] = useState({})
    const [averageDates, setAverageDates] = useState([])
    const [averagePulses, setAveragePulses] = useState([])
    const [totalAverage, setTotalAverage] = useState(0)
    const [displayPulses, setDisplayPulses] = useState([])
    const [availableWeeks, setAvailableWeeks] = useState([])
    const [displayWeekOf, setDisplayWeekOf] = useState('')
    const [availableDepartments, setAvailableDepartments] = useState([])
    const [initialDepartments, setInitialDepartments] = useState([])
    const [displayDepartment, setDisplayDepartment] = useState('')

    const getUsersHistoryTotal = (userId) => {
        authAxios.get(`${pulseBaseURL}/?userId=${userId}`)
        .then(res => {
            const totalRecords = res.data.length
            const totalPages = Math.ceil(totalRecords / recordsPerPage)
            setTotalPages(totalPages - 1)
        })
        .catch((err) => {
            throw err;
        })
    }

    const getUsersPulseHistory = (userId) => {
        authAxios.get(`${pulseBaseURL}/history?userId=${userId}&page=${currentPage}&pageSize=${recordsPerPage}`)
        .then(res => {
            let weeks = []
            let pulse = []
            res.data.forEach(week => {
                if(week.pulseScore !== null){
                    weeks.push(moment(week.weekOf).format("MMMM Do"))
                    pulse.push(week.pulseScore)
                }
            })
            setPulseHistory(res.data)
            setHistoryDates(weeks)
            setHistoryPulse(pulse)
            getUsersHistoryTotal(userId)
        })
        .catch((err) => {
            throw err;
        })
    } 

    const getUrlPulse = (id) => {
        authAxios.get(`${pulseBaseURL}/${id}`)
        .then(res => setUrlPulse(res.data[0]))
        .catch((err) => {
            throw err;
        })
    }
    
    const getSundayOfCurrentWeek = () => {
        const today = moment()
        const nextWeek = today.add(1, 'w');
        const upcomingSunday = nextWeek.startOf('week')
        return upcomingSunday
    }

    const getFridayOfCurrentWeek = () => moment().day(5)

    const createInitialPulse = (userId) => {
        const newPulse = {
            userId: userId,
            weekOf: getSundayOfCurrentWeek(),
            dueDate: getFridayOfCurrentWeek(),
        }
        authAxios.post(`${pulseBaseURL}`, newPulse)
        .then(res => setInitialPulse(res.data))
        .catch((err) => {
            throw err;
        })
    }

    const nextPage = () => setCurrentPage(currentPage + 1 )
    
    const previousPage = () => currentPage > 0 && setCurrentPage(currentPage - 1)

    const updatePulseScore = (score) => setPulseScore(score)
    
    const addPulseQuestion = () => {
        if(pulseScore <= 3) setPulseQuestion( 'Happens to all of us. Anything you need?' )
        if(pulseScore > 3 && pulseScore <= 5) setPulseQuestion('Is there anything blocking you that you’d like addressed?' )
        if(pulseScore > 5 && pulseScore <= 7) setPulseQuestion('Anything to add?' )
        if(pulseScore > 7 && pulseScore < 10) setPulseQuestion('Smooth and steady! Anything to add?' )
        if(pulseScore === 10) setPulseQuestion('Cue the dance party! What led to you feeling so good?' )
    }

    const submitSurvey = (e, id) => {
        e.preventDefault()

        const data = {  
            pulseScore: pulseScore,
            pulseMessage: pulseMessage.length === 0 ? ' ' : pulseMessage,
            submittedOn: new Date()
        }

        authAxios.put(`${pulseBaseURL}/${id}`, data)
            .then(() => setPulseError(false))
            .catch(() => setPulseError(true))
    }

    const clearSurvey = () => {
        setPulseScore(0), 
        setPulseQuestion('') 
        setPulseMessage('')
        setPulseError(null) 
    }

    const updateAvailableWeeks = (directReports) => {
        let weeks = []
        directReports.forEach(employee => weeks.push(employee.weekOf))
        let filteredWeeks = [...new Set(weeks)]
        setAvailableWeeks(filteredWeeks)
        setDisplayWeekOf(filteredWeeks[0])
    }

    const updateAvailableDepartments = (directReports, loggedInEmployeeData) => {
        let departments = []
        directReports.forEach(employee => departments.push(employee.Employee.department))
        let filteredDepartments = [...new Set(departments)]
        let defaultDepartment = ''
        if(loggedInEmployeeData.canViewAll) defaultDepartment = loggedInEmployeeData.department === 'CEO' ? 'Leadership' : 'CompanyWide'
        setAvailableDepartments(filteredDepartments)
        setDisplayDepartment(defaultDepartment)
    }

    const updateInitialDepartments = (employees) => {
        let departments = []
        employees.forEach(employee => departments.push(employee.department))
        let filteredDepartments = [...new Set(departments)]
        setInitialDepartments(filteredDepartments) 
    }

    const filterPulses = (directReports) => {
        // filter based on week
        let filteredWeekPulses = directReports.filter(pulse => pulse.weekOf === displayWeekOf)
        // filter based on department
        let filteredDepartmentPulses = []
        if(displayDepartment === 'CompanyWide') filteredDepartmentPulses = filteredWeekPulses
        if(displayDepartment === 'Leadership') filteredDepartmentPulses = filteredWeekPulses.filter(pulse => pulse.Employee.isLeadershipTeam)
        // if the user cant filter it means they don't have view all access and shouldn't be able to see anyone other than their direct reports.
        displayDepartment === '' 
        ? 
        filteredDepartmentPulses = filteredWeekPulses
        :
        // filter function to work with departments rather than direct reports if the user has access to the department dropdown
        filteredDepartmentPulses = filteredWeekPulses.filter(pulse => pulse.Employee.department === displayDepartment)
        setDepartmentPulses(filteredDepartmentPulses)
    }

    const updateLoggedInUsersPulseInfo = async (userId) => {
        try {
            let res = await authAxios.get(`${pulseBaseURL}?userId=${userId}&weekOf=${displayWeekOf}`)
            let data = res.data[0]
            setLoggedInUsersPulseInfo({
                id: data.id,
                reviewedEmployees: data.reviewedEmployees
            })
        } catch(err) {
            console.log(err)
            setLoggedInUsersPulseInfo({
                id: null,
                reviewedEmployees: false
            })
        }
    }

    const markEmployeesReviewed = () => {
        const data = { reviewedEmployees: true }

        authAxios.put(`${pulseBaseURL}/${loggedInUsersPulseInfo.id}`, data)
            .then(() => {
                setLoggedInUsersPulseInfo({
                    id: loggedInUsersPulseInfo.id,
                    reviewedEmployees: true
                })
            })
            .catch((err) => {
                throw err;
            })
    }

    const sendPulseReminderEmail = (user, recipient) => {
        const pulseReminderData = { 
            emailRecipient: recipient, 
            iodFrontEndUrl: iodFrontEndUrl,
        }
        
        let pulseReminderBody = pulseReminderEmail(pulseReminderData) 

        let senderBody = { 
            recipient: recipient.user.email, 
            subject: "Pulse Reminder", 
            emailTemplate: pulseReminderBody, 
            emailLogId: '',
            carbonCopy: user.email 
        }
        let logBody = { 
            senderEmail: user.email, 
            receiverEmail: recipient.user.email, 
            purpose: 'Admin Pulse Nudge', 
            userId: user.id
        }

        sendNodeMailer(senderBody, logBody)
    }

    const isViewingAnalytics = (value) => {
        setViewingAnalytics(value)
        displayDepartment('')
        displayWeekOf('')
    }

    // Analytics

    const getAnalyticsData = async (data) => {
        let pulses = []
        data.forEach(user => {
            user.Pulses.length && pulses.push(user.Pulses)
        })
        let combinedPulses = pulses.flat()

        let results = await Promise.all([
            runCompletionCounts(combinedPulses),
            combinePulses(combinedPulses)
        ])

        return results
    }

    
    // Data used for Trends and Analytics, runs all analytics functions below
    const getPulseAnalytics = async () => {
        let showAll = displayDepartment === 'CompanyWide' || displayDepartment === ''
        let showLeadership = displayDepartment === 'Leadership'
        // Fix table joins to be able to use the department of who they report to and check for CEO instead of id
        let query = showAll ? '' : showLeadership ? '?isLeadershipTeam=1' : `?department=${displayDepartment}` 

        try {
            let response = await authAxios.get(`${pulseBaseURL}/analytics${query}`)
            let res = response.data
            res.forEach(user => {
                user.Pulses.forEach(({pulseScore}) => {
                    if(pulseScore !== null) pulseScore = parseInt(pulseScore)
                })
            })
            // !!! looping
            // this.setState({ allFilteredPulses: res }, () => {
            //     this.getAnalyticsData(res)
            // })
            setAllFilteredPulses(res)
            getAnalyticsData(res)
        } catch(err) {
            console.error(err)
            throw err;
        }
    }


    const runCompletionCounts = (pulses) => {
        let completionObject = {}
        let countOnTime = 0
        let countLate = 0
        let countMissing = 0

        pulses.forEach(record => {
            if(record.submittedOn === null){ countMissing++ }
            if(record.submittedOn > record.weekOf){ countLate++ }
            if(record.submittedOn <= record.weekOf){ countOnTime++ }
        })

        completionObject.onTime = countOnTime
        completionObject.late = countLate
        completionObject.missing = countMissing

        setCompletionCounts(completionObject)
    }

    const getPulseAverages = (averages) => {
        const totalAverage = averages.length > 0 && averages.reduce((acc, curr) => acc + curr) / averages.length;
        const roundAverages = Math.round((totalAverage + Number.EPSILON) * 100) / 100
        setTotalAverage(roundAverages)
    }
    
    const combinePulses = (pulses) => {
        let weeks = []
        let averages = []
        const combinedArray = Object.values(pulses.reduce((acc, curr) => {
            curr.pulseScore !== null &&
            (acc[curr.weekOf] || (acc[curr.weekOf] = {weekOf: curr.weekOf, pulseArray: []})).pulseArray.push(curr.pulseScore);
            return acc;
        }, {}));
        // Sort the Array by weekOf
        combinedArray.sort((a, b) => new Date(a.weekOf) - new Date(b.weekOf))

        // Find the value of each average array
        combinedArray.forEach(week => {
            weeks.push(moment(week.weekOf).format("MMMM Do"))
            let pulseAverage = week.pulseArray.reduce((a,b) => a + b, 0) / week.pulseArray.length
            averages.push(pulseAverage)
        })
        //!!! causes loop
        this.setState({
            averageDates: weeks, 
            averagePulses: averages}
            , () => {
            getPulseAverages(averages)
        });
    }

    // Send reminder email to everyone who hasn't completed it
    const massEmailReminder = async () => {
        try {
            await authAxios.get(`${pulseBaseURL}/manual-email-send/all-users`)
            return console.log('Emails Sent')
        } catch(err) {
            console.error(err)
            throw err;
        }
    }

    const pulseOnChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: sanitizeData(value) })
    }

    return (
        <PulseContext.Provider 
            value={{
                // state
                pulseHistory, 
                setPulseHistory, 
                historyDates, 
                setHistoryDates, 
                historyPulse, 
                setHistoryPulse, 
                currentPage, 
                setCurrentPage, 
                recordsPerPage, 
                setRecordsPerPage, 
                totalPages, 
                setTotalPages, 
                initialPulse, 
                setInitialPulse, 
                urlPulse, 
                setUrlPulse, 
                pulseScore, 
                setPulseScore,
                pulseQuestion, 
                setPulseQuestion,
                pulseMessage, 
                setPulseMessage, 
                pulseError, 
                setPulseError, 
                departmentPulses, 
                setDepartmentPulses, 
                viewingAnalytics, 
                setViewingAnalytics, 
                emailError, 
                setEmailError, 
                loggedInUsersPulseInfo, 
                setLoggedInUsersPulseInfo,
                allFilteredPulses, 
                setAllFilteredPulses, 
                completionCounts, 
                setCompletionCounts, 
                averageDates, 
                setAverageDates, 
                averagePulses, 
                setAveragePulses, 
                totalAverage, 
                setTotalAverage, 
                displayPulses, 
                setDisplayPulses, 
                availableWeeks, 
                setAvailableWeeks,
                displayWeekOf, 
                setDisplayWeekOf, 
                availableDepartments, 
                setAvailableDepartments,
                initialDepartments, 
                setInitialDepartments,
                displayDepartment, 
                setDisplayDepartment,
                // funcs
                getUsersHistoryTotal, 
                getSundayOfCurrentWeek, 
                getFridayOfCurrentWeek, 
                runCompletionCounts, 
                combinePulses,
                getUsersPulseHistory,
                getUrlPulse,
                nextPage,
                previousPage,
                updatePulseScore,
                addPulseQuestion,
                pulseOnChange,
                submitSurvey,
                clearSurvey,
                isViewingAnalytics,
                getPulseAverages,
                getPulseAnalytics,
                getAnalyticsData,
                markEmployeesReviewed,
                sendPulseReminderEmail,
                massEmailReminder,
                filterPulses,
                updateAvailableWeeks,
                updateAvailableDepartments,
                updateLoggedInUsersPulseInfo,
                createInitialPulse,
                updateInitialDepartments,
            }}>
            { children }
        </PulseContext.Provider>
    )
}
