// Begins recording and capture for a user session in Logrocket.

import { logrocketId } from '../../../config/Config'
import LogRocket from 'logrocket'

const useLogRocketRecord = () => { 
    LogRocket.init(logrocketId)
}

export default useLogRocketRecord
