import axios from 'axios'
import React, { Component } from 'react'
import { authAxios } from '../shared/CustomAxios'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'

const baseURL = '/v1/api/global/companies'
const CompaniesContext = React.createContext()

class CompaniesProvider extends Component {
    constructor(){
        super()
        this.state = {
            companies : [],
            companySearchInput: '',
            companyAddUserInput: '',
            selectedCompany : {},
            isSearchingCompany : false, 
            isAddingUserToCompany: false,
            modalIsOpen: false
        }
    }

    handleCompanyChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: sanitizeData(value) })
    }

    setModalIsOpen = () => {
        this.setState({
            modalIsOpen: !this.state.modalIsOpen
        })
    }

    getAllCompanies = () => {
        authAxios.get(`${baseURL}`)
        .then( res => {
            this.setState({ companies : res.data })
        })
        .catch( err => {
            throw err
        })
    }

    searchCompanies = (inputType) => {
        let companySearch;
        if(inputType.isSearching) {
            this.setState({isSearchingCompany : true, isAddingUserToCompany: false})
            companySearch = this.state.companySearchInput
        }
        if(inputType.isAddingUser){
            this.setState({isSearchingCompany : false, isAddingUserToCompany: true})
            companySearch = this.state.companyAddUserInput
        }
            
        authAxios.get(`${baseURL}?companyName=${companySearch}`)
        .then( res => {
            if(res.data.length > 1){
                this.setState({ companies : res.data, selectedCompany: {} })
            } else {
                this.setState({ companies : res.data, selectedCompany: res.data[0] })
            }
        })
        .catch( err => {
            this.setState({ companies : [], selectedCompany: {} })
            throw err
        })
    }

    searchSalesforceCompanies = (companyNameSearch) => {
        axios.get(`/v1/salesforce/webhooks/company/lookup?name=${companyNameSearch}`)
        .then(res => {
            this.setIsSearchingCompanies(false)
            this.setState({ companies : res.data })
        })
        .catch(err => {
            this.setIsSearchingCompanies(false)
            console.log(err)
        })
    }

    setSelectedCompany = (selectedCompany) => {
        // These first 3 IF statements support SQL Company Selection Setting
        if(this.state.companySearchInput.length > 0 && this.state.companyAddUserInput.length === 0){
            this.setState({ selectedCompany: selectedCompany, 
                            companySearchInput: selectedCompany.companyName, 
                            companyAddUserInput: '' })
        }
        if(this.state.companySearchInput.length === 0 && this.state.companyAddUserInput.length > 0 ){
            this.setState({ selectedCompany: selectedCompany, 
                            companyAddUserInput: selectedCompany.companyName, 
                            companySearchInput: '' })
        }
        if(this.state.companySearchInput.length > 0 && this.state.companyAddUserInput.length > 0 ){
            this.setState({ selectedCompany: selectedCompany, 
                            companyAddUserInput: selectedCompany.companyName, 
                            companySearchInput: selectedCompany.companyName })
        }
        // This IF statement support SalesForce Company Selection Setting
        if(this.state.companySearchInput.length === 0 && this.state.companyAddUserInput.length === 0 ){
            this.setState({ selectedCompany: selectedCompany })
        }
    }

    clearCompanySearch = () => {
        this.setState({
            companySearchInput: '',
            companyAddUserInput: '',
            selectedCompany : {}
        })
    }

    setIsSearchingCompanies = (value) => this.setState({ isSearchingCompany: value })

    toggleSearching = () => {
        this.setState({
            isSearchingCompany: false,
            isAddingUserToCompany: false
        })
    }


    render(){
        return (
            <CompaniesContext.Provider 
                value={{
                    ...this.state,
                    handleCompanyChange: this.handleCompanyChange,
                    getAllCompanies: this.getAllCompanies,
                    searchCompanies: this.searchCompanies,
                    searchSalesforceCompanies: this.searchSalesforceCompanies,
                    setSelectedCompany: this.setSelectedCompany,
                    clearCompanySearch: this.clearCompanySearch,
                    toggleSearching: this.toggleSearching,
                    setModalIsOpen: this.setModalIsOpen,
                    setIsSearchingCompanies: this.setIsSearchingCompanies
                }}>
                { this.props.children }
            </CompaniesContext.Provider>
        )
    }
}

export default CompaniesProvider

export const withCompanies = C => props => (
    <CompaniesContext.Consumer>
        {value => <C {...props} {...value}/>}
    </CompaniesContext.Consumer>
)

