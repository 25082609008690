import { useState, useEffect } from 'react'
import { withAuth } from '../../Context/AuthProvider'
import { withSnapshots } from '../../Context/SnapshotProvider'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../GlobalComponents/Loader/Loader'

const SnapshotEmailModal = ({ emailSent, handleMultipleEmailChange, isReminderEmail, user, getContainerId, setSnapshotUser, getUserFromToken, isFeedbackEmail, closeSnapshotEmailModal, isShowingSnapshotEmailModal, emailStatus, setDefaultEmailMessages, handleSnapshotChange, sendSnapshotEmail, containerId, snapshotVersion }) => {
    const [recipientEmailInput, setRecipientEmailInput] = useState('')
    const [emailList, setEmailList] = useState([])
    const [error, setError] = useState(null)
    const [isSending, setIsSending] = useState(false)

    const emailHeader = isFeedbackEmail ? 'Coaching Snapshot Respondents - Feedback:' : isReminderEmail ? 'Coaching Snapshot Respondents - Reminder: ' : ''

    const defaultBody = isFeedbackEmail ? 
    `Hello, 
    \nI’m participating in a coaching program (GROW Coaching™) as part of my ongoing leadership development. To help me prepare, I’d like your feedback about my current coaching approach.  
    \nThis survey shouldn't take more than 5 minutes to complete and is completely anonymous and confidential. 
    \nI will use this data to identify areas of improvement and current skills that will help me focus on the right things.  \nThank you in advance for your willingness to provide your input.`
    : isReminderEmail ? 
    `Hello, \nThis is a friendly reminder that I am attending the InsideOut Coaching Workshop as part of my ongoing leadership development. \nTo help me prepare, I’d like to invite you to provide me with feedback on my current coaching approach by participating in a survey. \nThe survey shouldn’t take more than 5-6 minutes to complete and is completely anonymous and confidential.
    \nI will use this data to further develop my coaching process and leadership. \nThank you in advance for your willingness to provide me with your observations and insights.`
    : ''

    const defaultSubject = isFeedbackEmail ? (`I need your feedback`) : isReminderEmail ? (`Don't forget: I need your feedback`) : ''

    useEffect(() => {
        user === undefined && getUserFromToken()
    }, [user, getUserFromToken])

    useEffect(() => {
        setSnapshotUser(user)
        // getEventId(user.email)
    }, [user, setSnapshotUser])

    useEffect(() => {
        user.id !== undefined && getContainerId(user.id)
    }, [getContainerId, user.id, containerId])

    useEffect(() => {
        setDefaultEmailMessages(defaultSubject, defaultBody)
    }, [setDefaultEmailMessages, defaultBody, defaultSubject])

    useEffect(() => {
        handleMultipleEmailChange(emailList)
    }, [handleMultipleEmailChange, emailList])

    useEffect(() => {
        if(!isShowingSnapshotEmailModal) {
            setEmailList([])
            setRecipientEmailInput('')
            setError(null)
        }
    }, [isShowingSnapshotEmailModal])

    useEffect(() => {
        if(emailSent) setIsSending(false)
    }, [emailSent])

    const handleKeyDown = e => {
        if (["Enter", "Tab", ","].includes(e.key)) {
        e.preventDefault();

        const newValue = recipientEmailInput.trim();
            if (newValue && isValid(newValue)) {
                setEmailList([...emailList, newValue])
                setRecipientEmailInput('')
            }
        }
    };

    const handleAdd = (e) => {
        e.preventDefault();
        const newValue = recipientEmailInput.trim();
        if (newValue && isValid(newValue)) {
            setEmailList([...emailList, newValue])
            setRecipientEmailInput('')
        }
    }

    const handleChange = e => {
        setRecipientEmailInput(e.target.value)
        setError(null)
    };

    const handleDelete = email => {
        setEmailList(emailList.filter(i => i !== email))
    };

    const handlePaste = e => {
        e.preventDefault();
    
        const paste = e.clipboardData.getData("text");
        const emails = paste.match(/[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/g);
    
        if (emails) {
            const toBeAdded = emails.filter(email => !isInList(email));
            setEmailList([...emailList, ...toBeAdded])
        }
    };
    
    const isValid = (email) => {
        let error = null;
        if (isInList(email)) {
            error = `${email} has already been added.`;
        }
        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }
        if (error) {
            setError(error);
            return false;
        }
        return true;
    }
    
    const isInList = (email) => {
        return emailList.includes(email);
    }
    
    const isEmail = (email) => {
        return /[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/.test(email);
    }

    const initSendEmail = (e) => {
        e.preventDefault()
        setIsSending(true)
        sendSnapshotEmail(snapshotVersion)
    }

    return (
        <Modal
            isOpen={isShowingSnapshotEmailModal}
            contentLabel='Edit User'
            modalStyle='modalStyle'
            className='snapshot-email-modal main-modal'
            overlayClassName='main-modal-overlay'
            ariaHideApp={false}
        >
            <div className='main-modal-body'>
                <div className='close-icon-wrapper'>
                    <FontAwesomeIcon icon={"times-circle"} className='close-icon' onClick={closeSnapshotEmailModal} style={{ color: '#2E5597' }}/>
                </div>
                <h3>{emailHeader}</h3>

                {!emailSent ?
                    <div>
                        <form onSubmit={initSendEmail}>
                            <div>
                                <label>Recipient Email: </label>
                                <aside>
                                    <input
                                        name='emailRecipient'
                                        value={recipientEmailInput}
                                        placeholder="Input email then press `Enter` or `Tab`..."
                                        onKeyDown={handleKeyDown}
                                        onChange={handleChange}
                                        onPaste={handlePaste}
                                        required={emailList.length < 1}
                                        autoComplete="off"
                                    />
                                    <button onClick={handleAdd} style={{margin: '0'}}>ADD</button>
                                </aside>
                            </div>
                            

                            {error &&
                                <div>
                                    <label className='violator-text' >Error: </label>
                                    {error && <p className='violator-text' style={{ margin: '0px 0px' }}>{error}</p>}
                                </div>
                            }

                            <div>
                                <label>Recipient(s): </label>
                                <section className='snapshot-emailChips'>
                                    {emailList.map((item, i) => (
                                        <aside className='snapshot-emailChips-items' key={i}>
                                            <p>{item} </p>
                                            <FontAwesomeIcon icon={"times-circle"} onClick={() => handleDelete(item)} style={{ color: '#2E5597' }}/>
                                        </aside>
                                    ))}
                                </section>
                            </div>

                            <div>
                                <label>Subject: </label>
                                <input type="text" name='emailSubject' onChange={handleSnapshotChange} defaultValue={defaultSubject} autoComplete="off" required />
                            </div>
                            <div>
                                <label>Body: </label>
                                <textarea name='emailBody' style={{whiteSpace: 'pre-line', height: '177px'}} onChange={handleSnapshotChange} defaultValue={defaultBody} autoComplete="off" />
                            </div>
                            {
                                !isSending &&
                                <button type='submit' disabled={emailList.length < 1}>Send Email</button> 
                            }
                            <p className='violator-text'>{emailStatus}</p>
                            
                        </form>
                        {
                            isSending && <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}> 
                                <Loader/> 
                            </div>
                                
                        }
                    </div>
                    :
                    // Email Sent Notice
                    <div className="modal-email-sent-notification">
                        <header>Your email has been successfully sent!</header>
                        <p>All emails were successfully sent. You have been CC'd.</p>
                    </div>
                }
            </div>   
        </Modal>
    )
}

export default withAuth(withSnapshots(SnapshotEmailModal))