/* eslint-disable no-undef */
let iodFrontEndUrl;
let logrocketId;
let rootDomain;

if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'development'){
    iodFrontEndUrl = 'http://localhost:3000'
    logrocketId = 'tqx5ak/testing-zs3ei'
    rootDomain = 'http://localhost:3000'
} else if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'test'){
    iodFrontEndUrl = 'https://app-dev.insideoutdev.com'
    logrocketId = 'tqx5ak/testing-zs3ei'
    rootDomain = 'https://dev.insideoutdev.com'
} else if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'){
    iodFrontEndUrl = `https://app.insideoutdev.com`
    logrocketId = 'tqx5ak/iod-rebuild-production'
    rootDomain = 'https://insideoutdev.com'
}

module.exports = {
    iodFrontEndUrl: iodFrontEndUrl,
    logrocketId: logrocketId,
    nodeEnvironment: process.env.REACT_APP_CUSTOM_NODE_ENV,
    googleAnalyticsTag: process.env.REACT_APP_GOOGLE_ANALYTICS_TAG,
    emailSenderAddress: process.env.REACT_APP_MAILER_ADDRESS,
    highfiveEmailCC: process.env.REACT_APP_HIGHFIVE_CC_EMAIL,
    valueCoinEmailCC: process.env.REACT_APP_VALUECOIN_CC_EMAIL,
    bounceHitEmailCC: process.env.REACT_APP_BOUNCEHIT_CC_EMAIL,
    leadershipEmail: process.env.REACT_APP_LEADERSHIP_EMAIL,
    rootDomain: rootDomain
}
