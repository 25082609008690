import React, { Component } from 'react'
import { authAxios } from '../shared/CustomAxios'

const burstBaseURL = '/v1/api/digital-foundations/user-bursts'
const DigitalFoundationsContext = React.createContext()

const defaultDFState = {
    dfUser: [],
    pagesAvailable: 0,
    maxSteps: 5,
    isAdmin: false,
    feedbackModalIsOpen: false,
    stepsCompleted: 0,
    allUsersBursts: [],
    usersBurst: {},
    currentBurstId: ''
}

class DigitalFoundationsProvider extends Component {
    constructor(){
        super()
        this.state = defaultDFState
    }

    setDFUser = (user, community) => {
        const isSuper = user.isIodSuperAdmin 
        let hasAccess = false

        switch(community) {
            case 'iocdf':
                hasAccess = isSuper || user.isIocDfAdmin
                break;
            case 'iocedu':
                hasAccess = isSuper || user.isIocEduAdmin
                break;
            default:
                break;
        }
        this.setState({ dfUser: user, isAdmin: hasAccess })
    }

    getAllUsersBursts = (community) => {
        authAxios.get(`${burstBaseURL}?product=${community}`)
        .then(res => {
            this.setState({ allUsersBursts: res.data })
        })
        .catch((err) => {
            throw err;
        })
    }

    getPagesAvailable = (community) => {
        authAxios.get(`${burstBaseURL}?userId=${this.state.dfUser.id}&product=${community}`)
        .then(res => {
            const isGated = res.data[0].isGated
            const adminOrUser = this.state.isAdmin || !isGated ? this.state.maxSteps : res.data[0].availableSteps
            this.setState({ pagesAvailable: adminOrUser, stepsCompleted: res.data[0].lastStepCompleted, currentBurstId: res.data[0].id  })
        })
        .catch((err) => {
            throw err;
        })
    }

    activateNextStep = async (id, step) => {
        let newStep = step < this.state.maxSteps ? step + 1 : step
        let body = {
            availableSteps: newStep
        }
        try {
            await authAxios.put(`${burstBaseURL}/${id}`, body)
        } catch (error) {
            console.log(error)
        }
    }

    completeBurst = (step, history) => {
        let updateIfNotLastStep = this.state.pagesAvailable < this.state.maxSteps ? this.state.pagesAvailable + 1 : this.state.maxSteps

        let body = {
            availableSteps: updateIfNotLastStep,
            lastStepCompleted: step,
            lastStepCompletedOn: new Date()
        }

        authAxios.put(`${burstBaseURL}/${this.state.currentBurstId}`, body)
            .catch((err) => {
                throw err;
            }
        );
        // setState to rerun useEffect and unlock next burst without refreshing
        this.setState({ stepsCompleted: step, pagesAvailable: updateIfNotLastStep }, history.push(`/digital-foundations/iocedu/bursts/${updateIfNotLastStep}`))
    }

    startBursts = (history) => {
        let body = {
            availableSteps: 1,
            lastStepCompletedOn: new Date()
        }

        authAxios.put(`${burstBaseURL}/${this.state.currentBurstId}`, body)
            .catch((err) => {
                throw err;
            }
        );
        this.setState({ pagesAvailable: 1 }, history.push(`/digital-foundations/iocedu/bursts/1`))
    }

    activateAllSteps = (id) => {
        let body = {
            availableSteps: this.state.maxSteps
        }
        authAxios.put(`${burstBaseURL}/${id}`, body)
        .catch((err) => {
            throw err;
        });
    }

    removeStep = async (id, step) => {
        let newStep = step > 0 ? step - 1 : step
        let body = {
            availableSteps: newStep
        }
        try {
            await authAxios.put(`${burstBaseURL}/${id}`, body)
        } catch (error) {
            console.log(error)
        }
    }

    setFeedbackModalIsOpen = () => {
        this.setState({ feedbackModalIsOpen: !this.state.feedbackModalIsOpen })
    }

    deleteBurstRecord = (burstId) => {
        console.log(burstId)
        authAxios.delete(`${burstBaseURL}/${burstId}`)
            .then( res => res )
            .catch(err => {throw err} );
    }

    clearBursts = () => {
        this.setState(defaultDFState)
    }

    noAccessMessage =                 
        <div className="bursts-error">
            <p className="bursts-resources-step"> You do not currently have access to this Digital Foundations Burst</p>
            <p style={{ color: '#454545' }}> 
                If you believe this is a mistake please email {' '}
                <a className='dashboard-contact-link' href='mailto: info@insideoutdev.com'>  info@insideoutdev.com</a>
                , call 1-888-262-2448 to speak with a representative, or contact your company administrator for assistance. 
            </p>
        </div> 

    render(){
        return (
            <DigitalFoundationsContext.Provider 
                value={{
                    ...this.state,
                    noAccessMessage: this.noAccessMessage,
                    setDFUser: this.setDFUser,
                    getPagesAvailable: this.getPagesAvailable,
                    getAllUsersBursts: this.getAllUsersBursts,
                    activateNextStep: this.activateNextStep,
                    removeStep: this.removeStep,
                    setFeedbackModalIsOpen: this.setFeedbackModalIsOpen,
                    deleteBurstRecord: this.deleteBurstRecord,
                    completeBurst: this.completeBurst,
                    startBursts: this.startBursts,
                    clearBursts: this.clearBursts
                }}>
                { this.props.children }
            </DigitalFoundationsContext.Provider>
        )
    }
}

export default DigitalFoundationsProvider

export const withBursts = C => props => (
    <DigitalFoundationsContext.Consumer>
        {value => <C {...props} {...value}/>}
    </DigitalFoundationsContext.Consumer>
)
