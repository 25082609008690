import { createContext, useState, useContext } from 'react'
import { authAxios } from '../shared/CustomAxios'


const TagsContext = createContext()
export const useTags = () => useContext(TagsContext)

const tagBaseURL = '/v1/api/tags'
const tagParticipantBaseURL = '/v1/api/tagged-participants'


export const TagsProvider = ({ children }) => {
    const [allTags, setAllTags] = useState([])
    const [participantsTags, setParticipantsTags] = useState([])
    const [duplicateTag, setDuplicateTag] = useState(false)
    const [tagSubmitted, setTagSubmitted] = useState(null)
    const [tagFormData, setTagFormData] = useState({
        newTag: '',
        newTagAdmin: '',
        editedTag: ''
    })
    const [tagStatus, setTagStatus] = useState({
        created: false, 
        updated: false, 
        deleted: false
    })

    const getAllTags = async (user) => {
        // get a list of all the admins tags with the amount of participants
        let url = tagBaseURL
        if(!user.isIodSuperAdmin){ url = `${url}?companyId=${user.companyId}&adminId=${user.id}`}

        try {
            let res = await authAxios.get(url)
            setAllTags(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    // newTag, newTagAdmin, editedTag
    const tagOnChange = (e) => {
        const { name, value } = e.target
        setTagFormData({ ...tagFormData, [name]: value })
    }

    const createTag = async (user, companyId) => {
        let body = {
            tagName: tagFormData.newTag,
            adminId: tagFormData.newTagAdmin.length > 0 ? tagFormData.newTagAdmin : user.id,
            companyId: companyId ? companyId: user.companyId
        }

        // make sure the tag dosent already exist for the company
        try {
            let res = await authAxios.get(`${tagBaseURL}?companyId=${companyId}&tagName=${tagFormData.newTag}`)
            if(res.data.length === 0){
                // create the tag
                try {
                    await authAxios.post(tagBaseURL, body)
                    setTagFormData({...tagFormData, newTag: ''}) 
                    setTagStatus({...tagStatus, created: true}) 
                    setDuplicateTag(false)
                } catch (error) {
                    console.log(error)
                }
            } else {
                setDuplicateTag(true)
            }
        } catch (error) {
            setTagStatus({...tagStatus, created: false}) 
            console.log(error)
        }
    }
    
    const updateTag = async (id) => {
        let body = {
            tagName: tagFormData.editedTag
        }

        try {
            await authAxios.put(`${tagBaseURL}/${id}`, body)
            setTagStatus({...tagStatus, updated: true})  
        } catch (error) {
            setTagStatus({...tagStatus, updated: true}) 
            console.log(error)
        }
    }
    
    const deleteTag = async (id) => {
        if(window.confirm(`Deleting this tag will remove it for all participants. Are you sure you want to continue?`)) {
            try {
                await authAxios.delete(`${tagBaseURL}/${id}`)
                // let updatedTags = allTags.filter(tag => tag.id !== id)
                setTagStatus({...tagStatus, deleted: true})   
            } catch (error) {
                setTagStatus({...tagStatus, deleted: true})  
            }
            window.alert(`Tag successfully deleted`)
        } else { 
            window.alert(`Unable to delete tag`)
        }
    }

    const tagParticipants = async (tag, participantsToAdd) => {
        let participantsList = []
        // dont add empty records
        if(tag.length > 0){
            await participantsToAdd.map(participant => {
                let userId = participant.firstName === undefined ? null : participant.id
                let data = {
                    tagId: tag,
                    userId: userId,
                    firstName: participant.firstName,
                    lastName: participant.lastName,
                    email: participant.email,
                }
                participantsList.push(data)
            })
    
            try {
                let res = await authAxios.post(`${tagParticipantBaseURL}/tag-participants`, participantsList)
                setTagSubmitted(true)
                return res.data[0].id
            } catch (error) {
                console.log(error)
            }
        }
    }

    const removeTagFromParticipant = async (id) => {
        try {
            await authAxios.delete(`${tagParticipantBaseURL}/${id}`)
        } catch (error) {
            console.log(error)
        }
    }

    const getParticipantsTags = async (userId) => {
        try {
            let res = await authAxios.get(`${tagParticipantBaseURL}/${userId}`)
            return res.data
        } catch (error) {
            console.log(error)
        }
    }

    // changeed from setParticipantsTags to applyParticipantsTags
    const applyParticipantsTags = (initialTags) => {
        setParticipantsTags(initialTags)
    }


    return (
        <TagsContext.Provider 
            value={{
                // State
                allTags,
                participantsTags,
                duplicateTag, 
                tagSubmitted,
                tagFormData,
                tagStatus,
                // Functions
                getAllTags,
                tagOnChange,
                createTag,
                updateTag,
                deleteTag,
                tagParticipants,
                removeTagFromParticipant,
                getParticipantsTags,
                applyParticipantsTags
            }}>
            { children }
        </TagsContext.Provider>
    )
}
