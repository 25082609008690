import { useState, useContext, createContext } from 'react'
import { authAxios } from '../shared/CustomAxios'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'
import { highfiveEmail, valueCoinEmail, bounceHitEmail } from '../shared/EmailTemplates/awardEmails'
import sendNodeMailer from '../shared/HelperFunctions/sendNodeMailer'
import { leadershipEmail, iodFrontEndUrl, highfiveEmailCC, valueCoinEmailCC, bounceHitEmailCC } from '../config/Config'

const highFivesBaseURL = '/v1/api/a2b/highfive'
const valueLikeBaseURL = '/v2/api/a2b/valuelikes'
const highFiveCommentBaseURL = '/v2/api/a2b/highfivecomments'
const AwardsContext = createContext()



export const useAwards = () => useContext(AwardsContext)

export const AwardsProvider = ({ children }) =>  {
    const [allHighFives, setAllHighFives] = useState([])
    const [receivedAwards, setReceivedAwards] = useState({
        highfives: 0,
        coins: 0
    })
    const [awardsFormData, setAwardsFormData] = useState({
        externalName: '',
        externalEmail: '',
        selectedCoin: '',
        message: '',
    })

    const [validationErrors, setValidationErrors] = useState([])
    const [awardSubmitted, setAwardSubmitted] = useState(null)
    const [awardsUpdated, setAwardsUpdated] = useState(false)

    const getAllHighFives = async (view) => {
        // reset the update state after it runs. Only used in dependency array
        setAwardsUpdated(false)
        let query = view ? `?view=${view}` : ""

        try {
            let res = await authAxios.get(`${highFivesBaseURL}${query}`)
            setAllHighFives(res.data)
        } catch (err) {
            console.error(err)
        }    
    }

    const getAllAwardCounts = async (user) => {
        try {
            const userName = `${user.firstName} ${user.lastName}`
            let res = await authAxios.get(`${highFivesBaseURL}/counts?name=${userName}`)
            setReceivedAwards({
                highfives: res.data.highfives,
                coins: res.data.coins
            })
        } catch (err) {
            console.error(err)
        }
    }

    const awardsOnChange = (e) => {
        const { name, value } = e.target
        setAwardsFormData({...awardsFormData, [name]: sanitizeData(value)})
    }

    const validationCheck = (award, recipients) => {
        let errors = []
        // All use message and recipients
        if(awardsFormData.message.length < 1){
            errors.push('message')
        }

        if(recipients.length === 0){
            errors.push('recipients')
        }
        // Coin uses selectedCoin
        if(awardsFormData.selectedCoin.length < 1 && award === 'valueCoin'){
            errors.push('valueCoin')
        }

        if(errors.length > 0){
            setValidationErrors(errors)
            return false
        } else {
            return true
        }
    }

    const highfiveEmailLoop = (user, recipients) => {
        recipients.forEach(recipient => {
            const highfiveData = { 
                emailRecipient: recipient, 
                iodFrontEndUrl: iodFrontEndUrl,
                emailSender: `${user.firstName} ${user.lastName}`,
                highfiveMessage: awardsFormData.message,
            }

            let highfiveBody = highfiveEmail(highfiveData) 

            let senderBody = { 
                recipient: recipient.email, 
                subject: `${user.firstName} ${user.lastName} gave you a High Five!`, 
                emailTemplate: highfiveBody, 
                emailLogId: '',
                carbonCopy: [user.email,highfiveEmailCC]
            }

            let logBody = { 
                senderEmail: user.email, 
                receiverEmail: recipient.email, 
                purpose: 'Given High Five', 
                userId: user.id
            }

            sendNodeMailer(senderBody, logBody)
        })
    }

    const submitHighFive = async (e, user, selectedUsers) => {
        e.preventDefault()
        const url = highFivesBaseURL
        const award = 'highfive'

        const isValid = validationCheck(award, selectedUsers)
        if(isValid){
            const data = {  
                recipients: selectedUsers.map(names => names['label']).join(","),
                message: awardsFormData.message,
                award: award
            }
    
            try {
                await authAxios.post(`${url}`, data)
                highfiveEmailLoop(user, selectedUsers)
                setAwardSubmitted(true)
            } catch (error) {
                setAwardSubmitted(false)
                console.error(error)
            }
        }
    }

    // COINS
    const valueCoinEmailSend = (user, data, recipient) => {
        const valueCoinData = { 
            iodFrontEndUrl: iodFrontEndUrl,
            emailSender: `${user.firstName} ${user.lastName}`,
            message: data.message,
            valueCoin: data.valueCoin
        }

        let valueCoinBody = valueCoinEmail(valueCoinData) 

        let envCC = valueCoinEmailCC.split(',')
        let carbonCopy = [user.email, envCC].flat()

        let senderBody = { 
            recipient: recipient.email, 
            subject: `${user.firstName} ${user.lastName} sent you a Value Coin!`, 
            emailTemplate: valueCoinBody, 
            emailLogId: '',
            carbonCopy: carbonCopy
        }

        let logBody = { 
            senderEmail: user.email, 
            receiverEmail: recipient.email, 
            purpose: 'Given Value Coin', 
            userId: user.id
        }

        sendNodeMailer(senderBody, logBody)
    }

    const submitValueCoin = async (e, user, employee) => {
        e.preventDefault()
        const url = highFivesBaseURL
        const award = 'valueCoin'

        const isValid = validationCheck(award, employee)
        if(isValid){
            // There will only ever be one selectedUser for Value Coins
            const recipientObj = employee[0]
    
            const data = {  
                recipients: recipientObj.label,
                valueCoin: awardsFormData.selectedCoin,
                message: awardsFormData.message,
                award: award
            }
    
            // Upon successful submission an email is sent to the recipient and both the sender and Julia are CC'd. Displays as a public awards, no need for approval.
            try {
                await authAxios.post(`${url}`, data)
                valueCoinEmailSend(user, data, recipientObj)
                setAwardSubmitted(true)
            } catch (error) {
                setAwardSubmitted(false)
                console.error(error)
            }
        }
    }

    // BOUNCE-HIT
    const bounceHitEmailSend = (user, data, recipient) => {
        const bounceHitData = { 
            emailRecipient: data.recipients,
            emailSender: `${user.firstName} ${user.lastName}`,
            message: data.message,
        }

        let bounceHitBody = bounceHitEmail(bounceHitData) 

        let senderBody = { 
            recipient: leadershipEmail,
            subject: `${data.recipients} has been nominated for a Bounce-Hit award`, 
            emailTemplate: bounceHitBody, 
            emailLogId: '',
            carbonCopy: [bounceHitEmailCC]
        }

        let logBody = { 
            senderEmail: user.email, 
            receiverEmail: recipient.email, 
            purpose: 'Bounce-Hit Nomination', 
            userId: user.id
        }

        sendNodeMailer(senderBody, logBody)
    }

    const submitBounceHit = async (e, user, employee) => {
        e.preventDefault()
        const url = highFivesBaseURL
        const award = 'bounceHit'

        const isValid = validationCheck(award, employee)
        if(isValid){
            // There will only ever be one selectedUser for Bounce-Hit awards
            const recipientObj = employee[0]
    
            const data = {  
                recipients: recipientObj.label,
                message: awardsFormData.message,
                award: award
            }
    
            // POST in database just for records, they are not displayed anywhere
            // Upon submission the data is send to Julia and the Leadership Team, they will order the awards and ship them out if approved.
            try {
                await authAxios.post(`${url}`, data)
                bounceHitEmailSend(user, data, recipientObj)
                setAwardSubmitted(true)
            } catch (error) {
                setAwardSubmitted(false)
                console.error(error)
            }
        }

    }

    // SOCIAL
    const handleAddComment = async ( highFiveId, comment, userId ) => {
        const data = {
            comment: comment,
            userId: userId,
        }

        try {
            await authAxios.post(`${highFiveCommentBaseURL}/${highFiveId}`, data)
            setAwardsUpdated(true)
        } catch (error) {
            console.error(error)
        }
    }

    const checkIfUserLiked = (array, id, highFiveId, value) => {
        let liked = false
        array.forEach((item) => {
            if (item.highFiveId === highFiveId && item.userId === id && item.value === value) {
                liked = true
            }
        })
        return liked
    }

    const handleValueLike = async (id, value, userId) => {
        const data = {
            value: value,
            userId: userId,
        }

        try {
            await authAxios.post(`${valueLikeBaseURL}/${id}`, data)
            setAwardsUpdated(true)
        } catch (error) {
            console.error(error)
        }
    }

    const clearAward = () => {
        setAwardsFormData({...awardsFormData, message:''}) 
        setAwardSubmitted(null)
        setAwardsUpdated(false)
        setValidationErrors([])
    }

    return (
        <AwardsContext.Provider 
            value={{
                allHighFives,
                receivedAwards,
                awardsFormData,
                awardSubmitted,
                awardsUpdated,
                validationErrors,
                getAllHighFives,
                getAllAwardCounts,
                awardsOnChange,
                submitHighFive,
                submitValueCoin,
                submitBounceHit,
                handleAddComment,
                checkIfUserLiked,
                handleValueLike,
                clearAward
            }}>
            { children }
        </AwardsContext.Provider>
    )
}
