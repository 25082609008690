import axios from 'axios'

async function sendNodeMailer(senderBody, logBody) {
    function emailLogger(body) { 
        let emailLoggerResponse = axios.post('/v1/email-logs', body)
        .then(res => {        
            return res.data.id
        })
        .catch(() => {
            return null
        })
        return emailLoggerResponse
    }
    

    const emailLoggerResponse = await emailLogger(logBody)

    senderBody.emailLogId = emailLoggerResponse
    if(!senderBody.carbonCopy) senderBody.carbonCopy = null

    axios.post('/v1/email-sender', senderBody)
}

export default sendNodeMailer;
