import 'react-app-polyfill/ie9' // Enables react to work on IE 9 and above
import 'react-app-polyfill/stable'
// import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import './styles/main.scss'
import './sass/main.scss'
import App from './App'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import AuthProvider from './Context/AuthProvider'
import SnapshotProvider from './Context/SnapshotProvider'
import { SnapshotsProviderV2 } from './Context/SnapshotsProviderV2'
import UserProvider from './Context/UsersProvider'
import CompanyProvider from './Context/CompanyProvider'
import FeedbackProvider from './Context/FeedbackProvider'
import FacilitatorProvider from './Context/FacilitatorProvider'
import CoachingSessionsProvider from './Context/Sessions/CoachingSessionsProvider'
import DFSessionsProvider from './Context/Sessions/DFSessionsProvider'
import SessionsProvider from './Context/Sessions/SessionsProvider'
import SessionParticipantsProvider from './Context/Sessions/SessionParticipantsProvider'
import DigitalFoundationsProvider from './Context/DigitalFoundationsProvider'
import EmailSubscriptionProvider from './Context/EmailSubscriptionsProvider'
import PulseProvider from './Context/PulseProvider'
import { UtilityProvider } from './Context/UtilityProvider'
import { PulseProviderV2 }  from './Context/PulseProviderV2'
import { AwardsProvider } from './Context/AwardsProvider'
import InviteParticipantsProvider from './Context/InviteParticipantsProvider'
import { TagsProvider } from './Context/TagsProvider'
import { EmployeeProviderV2 } from './Context/EmployeeProviderV2'
import { nodeEnvironment } from './config/Config'
import { initGoogleAnalytics, googlePageView } from './shared/GoogleAnalyticsTracking'
import { Chart, registerables } from 'chart.js'

//  Configures Google Analytics on production server only
if( nodeEnvironment === 'production' ){
  initGoogleAnalytics()
  googlePageView()
}

//  Removes unnecessary react-dom warnings until Facebook updates their package 
if( nodeEnvironment === 'development' ){
  const warn = console.warn;
  const logWarning = (...warnings ) => {
    let showWarning = true
    warnings.forEach(warning => {
      if(warning.includes("componentWillMount"))  showWarning = false;
    });
    if(showWarning) warn(...warnings);
  }
  console.warn  = logWarning
}

Chart.register(...registerables)


const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <UtilityProvider>
    <AuthProvider>
      <SnapshotsProviderV2>
        <SnapshotProvider>
          <CompanyProvider>
            <UserProvider>
              <CoachingSessionsProvider>
                <FeedbackProvider>
                  <FacilitatorProvider>
                    <DigitalFoundationsProvider> 
                      <EmailSubscriptionProvider>
                        <SessionsProvider>
                          <SessionParticipantsProvider>
                            <DFSessionsProvider>
                              <EmployeeProviderV2>
                                <PulseProvider>
                                  <PulseProviderV2>
                                    <AwardsProvider> 
                                      <InviteParticipantsProvider>
                                        <TagsProvider>
                                          <InviteParticipantsProvider>
                                            <Router onUpdate={() => window.scrollTo(0, 0)} history={createBrowserHistory()}>
                                              <App />
                                            </Router>
                                          </InviteParticipantsProvider>
                                        </TagsProvider>
                                      </InviteParticipantsProvider>
                                    </AwardsProvider>
                                  </PulseProviderV2>
                                </PulseProvider>
                              </EmployeeProviderV2>
                            </DFSessionsProvider>
                          </SessionParticipantsProvider>
                        </SessionsProvider>
                      </EmailSubscriptionProvider>
                    </DigitalFoundationsProvider>
                  </FacilitatorProvider>
                </FeedbackProvider>
              </CoachingSessionsProvider>
            </UserProvider>
          </CompanyProvider>
        </SnapshotProvider>
      </SnapshotsProviderV2>
    </AuthProvider>
  </UtilityProvider>
)
