const Loader = ({ community }) => {
	return (
		<div 
			className={`loader ${community}`} 
			data-test="loader-component-body"
		>
		</div>
	)
}

export default Loader
