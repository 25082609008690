// Used to invite participants to a community or session
import React, { Component } from 'react'
import { authAxios } from '../shared/CustomAxios'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'

const baseUrlSessionParticipants = '/v1/api/global/session-participants'
const baseUrlAuth = '/v1/auth'
const InviteParticipantsContext = React.createContext()

const defaultParticipantInviteState = {
    participantFirstName: '',
    participantLastName: '',
    participantEmail: '',
    isParticipantAdmin: false,
    participantCompanyId: null,
    participantInviteList: [],
    sessionId: null,
    product: '',
    participantsUserId: null,
    participantsInviter: {},
    emailInvitationSuccessful: null,
    emailInvitationMessage: '',
}

class InviteParticipantsProvider extends Component {
    constructor(){
        super()
        this.state = defaultParticipantInviteState
    }


    setParticipantData = (product, sessionId, loggedInUser, company, selectedSession) => {
        let sessionsId = sessionId ? sessionId : null

        let companyId = loggedInUser.companyId // default company will be the logged in users company
        if(sessionsId) companyId = selectedSession.id // If in a session, use the sessions company
        if(company.id) companyId = company.id // if superadmin provides a company use that

        this.setState({ sessionId: sessionsId, product: product, participantsInviter: loggedInUser, participantCompanyId: companyId })
    }


    handleParticipantsChange = (e) => {
        const { name, value} = e.target
        if(e.target.type === 'checkbox'){
            this.setState({ [name]: !this.state[name]})
        } else {
            this.setState({ [name]: sanitizeData(value) })
        }
    }


    // Check to see if the user is in the session if being invited to the session or community if being invited to community
    checkIfUserIsInviteable = async (participantEmail) => {
        // Used to query the correct community
        let query;
        let adminCheck;
        switch(this.state.product){
            case 'coaching':
                query = '&hasCoaching=1'
                adminCheck= 'isCoachingClientAdmin'
                break;
            case 'iocdf':
                query = '&hasIocDf=1'
                adminCheck= 'isIocDfAdmin'
                break;
            case 'iocEdu':
                query = '&hasIocEdu=1'
                adminCheck= 'isIocEduAdmin'
                break;
            default:
                break;
        }

        // sets the GET route based on if a sessionId exists
        let url = this.state.sessionId ? 
            `${baseUrlSessionParticipants}?email=${participantEmail}&sessionId=${this.state.sessionId}&product=${this.state.product}`
            :
            `${baseUrlAuth}/users?email=${participantEmail}${query}`

        try {
            let res = await authAxios.get(`${url}`)
            let data = res.data[0]
            let isAdmin = data[adminCheck]
            // If user is not an admin already and is being invited as one, allow the invite.
            return !isAdmin && this.state.isParticipantAdmin
        } catch (err) {
            return true
        }
    }


    // Check to participants avoid duplicate invites
    checkParticipantRecords = async (participantEmail) => {
        const userIsInviteable = await this.checkIfUserIsInviteable(participantEmail)

        // generate array of emails already added
        let emailArray = []
        this.state.participantInviteList.forEach(participant => emailArray.push(participant.email))

        // check to make sure email isnt already in either array
        return userIsInviteable && !emailArray.includes(participantEmail)
    }


    // Sets UserId in participant object if they exist in the database
    getExistingUsersId = async (participantEmail) => {
        try {
            let res = await authAxios.get(`${baseUrlAuth}/users?email=${participantEmail}`)
            this.setState({ participantsUserId: res.data[0].id })
        } catch {
            this.setState({ participantsUserId: null })
        }
    }


    // Add Participant to invite list
    // Run a check to see if you're inviting a user who is already in the Community / session?
    addParticipantInvite = async (e) => {
        e.preventDefault()
        let participantEmail = this.state.participantEmail.toLowerCase()
        const participantIsInviteable = await this.checkParticipantRecords(participantEmail)
        participantIsInviteable && await this.getExistingUsersId(participantEmail)


        // add to participantInviteList only if the email isnt already in it.
        if(participantIsInviteable){
            let participant = {
                firstName: this.state.participantFirstName,
                lastName: this.state.participantLastName,
                email: participantEmail,
                userId: this.state.participantsUserId,
                isAdmin: this.state.isParticipantAdmin,
                companyId: this.state.participantCompanyId,
                sessionId: this.state.sessionId
            }

            this.setState({ 
                participantInviteList: [...this.state.participantInviteList, participant],
                participantFirstName: '',
                participantLastName: '',
                participantEmail: '',
                participantsUserId: null,
                isParticipantAdmin: false
            })
        } else {
            if(this.state.sessionId){
                alert('Participant is already in or invited to session')
                this.setState({ participantFirstName: '', participantLastName: '', participantEmail: '', participantsUserId: null, isParticipantAdmin: false })
            } else {
                alert('Participant is already in or invited to community')
                this.setState({ participantFirstName: '', participantLastName: '', participantEmail: '', participantsUserId: null, isParticipantAdmin: false })
            }
        }
    }


    // Remove User from list
    removeParticipantInvite = (participantEmail) => {
        this.setState({ 
            participantInviteList: this.state.participantInviteList.filter(existingParticipants => existingParticipants.email !== participantEmail)
        })
    }


    sendInvites = async (selectedSession) => {
        
        const submitBody = {
            community: this.state.product,
            sessionAdmin: selectedSession.id ? selectedSession.User.id : null,
            participants: this.state.participantInviteList,
            emailData: {
                invitersName: `${this.state.participantsInviter.firstName} ${this.state.participantsInviter.lastName}`,
                sessionId: selectedSession.id ? selectedSession.id : null,
                companyId: this.state.participantCompanyId,
                invitersUserId: this.state.participantsInviter.id,
            }
        }

        try {
            let res = await authAxios.post('/v1/api/global/invite-participants', submitBody)
            if(res.status === 201){
                this.setState({
                    emailInvitationSuccessful: true,
                    emailInvitationMessage: 'All Participants successfully invited',
                })
            } else if(res.status === 206){
                this.setState({
                    emailInvitationSuccessful: true,
                    emailInvitationMessage: `All Participants successfully invited except: ${res.data.results}`,
                })
            }
        } catch (err) {
            this.setState({
                emailInvitationSuccessful: false,
                emailInvitationMessage: 'Failed to Invite Participants',
            })
        }
    }


    clearParticipantInviteProviderState = () => {
        this.setState(defaultParticipantInviteState)
    }


    render(){
        return (
            <InviteParticipantsContext.Provider 
                value={{
                    ...this.state,
                    setParticipantData: this.setParticipantData,
                    handleParticipantsChange: this.handleParticipantsChange,
                    addParticipantInvite: this.addParticipantInvite,
                    removeParticipantInvite: this.removeParticipantInvite,
                    sendInvites: this.sendInvites,
                    clearParticipantInviteProviderState: this.clearParticipantInviteProviderState
                }}>
                { this.props.children }
            </InviteParticipantsContext.Provider>
        )
    }
}

export default InviteParticipantsProvider

export const withInviteParticipants = C => props => (
    <InviteParticipantsContext.Consumer>
        {value => <C {...props} {...value}/>}
    </InviteParticipantsContext.Consumer>
)
