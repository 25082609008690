
export const highfiveEmail = ({
	emailRecipient,
	iodFrontEndUrl,
	emailSender,
	highfiveMessage,
}) => {
	const employeeLinkBack = emailRecipient.isEmployee
		? `Why not take a moment to visit our website's <a style="color: #005295; font-size: 17px; font-weight: bold;" href="${iodFrontEndUrl}/a2b/awards" target="_blank">Awards</a> page? It's a great chance to see what others have achieved and celebrate their successes alongside your own!`
		: 'Thank you for helping make InsideOut Development great!'

	return `
        <head>
            <style>
                body {
                    margin: 16px; 
                    font-family: Arial, sans-serif;
                }
                
                header {
                    background-color: lightgray;
                    padding: 5px;
                }
                article {
                    padding: 5px
                }
                h2 {
                    color: #005295; 
                    font-size: 22px; 
                    font-weight: bold; 
                    margin: 0px;
                }
                h3 {
                    color: #4D4D4D; 
                    font-size: 18px; 
                    font-weight: bold; 
                    margin: 0px 0px 14px 0px;
                }
                ul {
                    list-style-type: circle;
                }
                li {
                    margin-left: 2px;
                }
                p {
                    font-size: 17px; 
                    font-weight: 300;
                }
                footer {
                    width: 100%;
                    height: 60px;
                    background: #5892C4;
                    position: relative;
                    margin-top: 10px;
                }
            </style>
        </head>
        <body>
            <header>
                <h2> InsideOut Development </h2>
                <h3> You've been given a High Five! </h3>
            </header>
            <article>
                <p style="color: rgb(79, 79, 79);">You received a High Five from ${emailSender}!</p>
                <p style="color: #4D4D4D;">"${highfiveMessage}"</p>
                <p style="color: rgb(79, 79, 79);">${employeeLinkBack}</p>
            </article>
            <footer></footer>
        </body>`
}

export const valueCoinEmail = ({
	iodFrontEndUrl,
	emailSender,
	message,
    valueCoin
}) => {

    const icon = `https://insideoutdev.sfo2.digitaloceanspaces.com/a2b/${valueCoin}.svg`
    let alt = 'Value Coin'
    switch(valueCoin){
        case 'assume':
            alt = 'Dont make assumptions.'
            break;
        case 'best':
            alt = 'Always do our best.'
            break;
        case 'impeccable':
            alt = 'Be Impeccable with our word.'
            break;
        case 'personally':
            alt = 'Dont take anything personally.'
            break;
        default:
            break;
    }

	return `
        <head>
            <style>
                body {
                    margin: 16px; 
                    font-family: Arial, sans-serif;
                }
                
                header {
                    background-color: lightgray;
                    padding: 5px;
                }
                article {
                    padding: 5px
                }
                .title {
                    display: flex;
                    align-items: center;
                    margin: 10px 0;
                }
                img {
                    width: 150px;
                    height: 150px;
                    margin-right: 20px;
                }
                h2 {
                    color: #005295; 
                    font-size: 22px; 
                    font-weight: bold; 
                    margin: 0px;
                }
                h3 {
                    color: #4D4D4D; 
                    font-size: 18px; 
                    font-weight: bold; 
                    margin: 0px 0px 14px 0px;
                }
                ul {
                    list-style-type: circle;
                }
                li {
                    margin-left: 2px;
                }
                p {
                    font-size: 17px; 
                    font-weight: 300;
                }
                footer {
                    width: 100%;
                    height: 60px;
                    background: #5892C4;
                    position: relative;
                    margin-top: 10px;
                }
            </style>
        </head>
        <body>
            <header>
                <h2>InsideOut Development</h2>
                <h3>You've been given a Value Coin!</h3>
            </header>
            <article>
                <div class="title">
                    <img src="${icon}" alt="${alt}" />
                    <p style="color: rgb(79, 79, 79);">Congratulations!<br/>${emailSender} has sent you a Value Coin.</p>
                </div>
                <p style="color: #4D4D4D;">"${message}"</p>
                <p style="color: rgb(79, 79, 79);">Why not take a moment to visit our website's <a style="color: #005295; font-size: 17px; font-weight: bold;" href="${iodFrontEndUrl}/a2b/awards" target="_blank">Awards</a> page? It's a great chance to see what others have achieved and celebrate their successes alongside your own!</p>
            </article>
            <footer></footer>
        </body>`
}

export const bounceHitEmail = ({
	emailRecipient,
	emailSender,
	message,
}) => {
	return `
        <head>
            <style>
                body {
                    margin: 16px; 
                    font-family: Arial, sans-serif;
                }
                
                header {
                    background-color: lightgray;
                    padding: 5px;
                }
                article {
                    padding: 5px
                }
                h2 {
                    color: #005295; 
                    font-size: 22px; 
                    font-weight: bold; 
                    margin: 0px;
                }
                h3 {
                    color: #4D4D4D; 
                    font-size: 18px; 
                    font-weight: bold; 
                    margin: 0px 0px 14px 0px;
                }
                ul {
                    list-style-type: circle;
                }
                li {
                    margin-left: 2px;
                }
                p {
                    font-size: 17px; 
                    font-weight: 300;
                }
                footer {
                    width: 100%;
                    height: 60px;
                    background: #5892C4;
                    position: relative;
                    margin-top: 10px;
                }
            </style>
        </head>
        <body>
            <header>
                <h2>InsideOut Development</h2>
                <h3>Bounce-Hit Nomination</h3>
            </header>
            <article>
                <p style="color: rgb(79, 79, 79);">${emailSender} has nominated ${emailRecipient} for a Bounce-Hit Award.</p>
                <p style="color: #4D4D4D;">"${message}"</p>
            </article>
            <footer></footer>
        </body>`
}

