import { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useOutsideClick } from '../../shared/CustomHooks/useOutsideClick'
import { useCurrentWidth } from '../../shared/CustomHooks/useCurrentWidth'
import { withAuth } from '../../Context/AuthProvider'
import IODLogo from '../../Assets/images/iod-logo-horizantal.svg'
import GlobalNavbar from './GlobalNavbar'
import navData from '../globalNavLinks.json'

const GlobalHeader = ({ community, baseAccessRights, isPrework }) => {
    const [scrolled, setScrolled] = useState(false)
    let currentWindowWidth = useCurrentWidth()
    let location = useLocation()
    const headerMobileDropdownRef = useRef(null)
    const [ expandPanel, collapsePanel, expanded ] = useOutsideClick(headerMobileDropdownRef)
    const displayMobileNav = currentWindowWidth < 769
    const unscrolledDisplay = {backgroundColor: 'transparent'}
    const scrolledDisplay = {backgroundColor: 'rgba(28, 66, 118, .9)', backdropFilter: 'blur(8px)'}
    const toggleNavbar = expanded ? collapsePanel : expandPanel

    const changeHeaderBackground = () => {
        window.scrollY >= 1 ? setScrolled(true) : setScrolled(false)
    }
    
    let links = []

    switch(community){
        case 'insideout': 
            links = navData.iodNavLinks
            break;
        case 'participant-portal':
            links = navData.moduleNavLinks
            break;
        case 'a2b':
            links = navData.a2bNavLinks
            break
        default:
            links = navData.iodNavLinks
            break;
    }

    // Close the dropdown if the route changes on mobile
    useEffect(() => {
        currentWindowWidth <= 900 && collapsePanel()
        window.scrollTo(0, 0)

        window.addEventListener('scroll', changeHeaderBackground)
        return () => removeEventListener('scroll', changeHeaderBackground)
        // eslint-disable-next-line
    }, [location, baseAccessRights])

    return (
        <header className='global-header' style={(!scrolled && !expanded) ? unscrolledDisplay : scrolledDisplay}>
            <section className='global-header-content'>
                <Link to='/' className='global-header-content-logo'>
                    <img src={IODLogo} alt="InsideOut Development"/>
                </Link>

                {/* Hide navbar when user is completing prework */}
                {!isPrework &&
                    <GlobalNavbar 
                        headerMobileDropdownRef={headerMobileDropdownRef}
                        links={links} 
                        displayMobileNav={displayMobileNav} 
                        toggleNavbar={toggleNavbar}
                        expanded={expanded}
                    />
                }
            </section>
        </header>
    )
}

export default withAuth(GlobalHeader)
