import React, { Component } from 'react'
import sanitizeData from '../../shared/HelperFunctions/SanitizeData'
import { authAxios } from '../../shared/CustomAxios'

const baseIocSessionURL = '/v1/api/ioc/sessions'

const defaultIocSessionState = {
    allIocSessions: [],
    allUpcomingIocSessions: [],
    selectedIocSession: {},
    sessionCompany: {}, 
    sessionAdmin: {}, 
    sessionFacilitator: {},
    // Form
    sessionName: '',
    startDate: new Date().toISOString().slice(0,10),
    startTime: '08:30',
    endTime: '17:00',
    timeZone: '',
    companyId: null,
    sessionAdminId: null,
    sessionFacilitatorId: null,
    isSelfPrint: true, 
    isCentralPrint: false,
    snapshotIntervalWeeks: 12,
    hasSnapshotAccess: true,
    bypassTutorial: false,
    sessionNotes: '',
    // Form Submit
    sessionSubmitted: false,
    redirectPath: '',
    submitMessage: ''
}

const CoachingSessionsContext = React.createContext()

class CoachingSessionsProvider extends Component {
    constructor(){
        super()
        this.state = defaultIocSessionState
    }

    handleIocSessionChange = (e) => {
        const { name, value, title } = e.target

        if(title === 'printOptions'){
            if(name === 'isSelfPrint') this.setState({ isCentralPrint: false, isSelfPrint: true })
            if(name === 'isCentralPrint') this.setState({ isCentralPrint: true, isSelfPrint: false })
        } else if(name === 'hasSnapshotAccess' ) {
            value === 'true' ? this.setState({ hasSnapshotAccess : true }) : this.setState({ hasSnapshotAccess : false }) 
        } else if(name === 'bypassTutorial' ) {
            value === 'true' ? this.setState({  bypassTutorial : true }) : this.setState({  bypassTutorial : false }) 
        } else {
            this.setState({ [name]: sanitizeData(value) })
        }
    }

    setCoachingSessionDetails = (session) => {
        console.log(session)
        const facilitator = session.Facilitator
        const admin = session.User
        const company = session.Company
        const { bypassTutorial, endTime, hasSnapshotAccess, isCentralPrint, isSelfPrint, name, snapshotIntervalWeeks, startDate, startTime, timeZone, companyId, facilitatorId, adminId } = session
        const formatStartDate = startDate.slice(0, 10)
        this.setState({
            selectedIocSession: session, 
            sessionName: name, 
            startDate: formatStartDate, 
            startTime: startTime, 
            endTime: endTime, 
            timeZone: timeZone,
            sessionCompany: company, 
            sessionAdmin: admin, 
            sessionFacilitator: facilitator,
            companyId: companyId,
            sessionAdminId: adminId,
            sessionFacilitatorId: facilitatorId,
            isSelfPrint: isSelfPrint, 
            isCentralPrint: isCentralPrint, 
            hasSnapshotAccess: hasSnapshotAccess,
            bypassTutorial: bypassTutorial, 
            snapshotIntervalWeeks: snapshotIntervalWeeks, 
        })
    }

    // user param is placeholder until Admin search works
    createCoachingSession = (e, userId) => {
        e.preventDefault()

        const data = {  
            name: this.state.sessionName,
            companyId: this.state.companyId,   
            sessionAdminId: userId,
            facilitatorId: this.state.sessionFacilitatorId,
            startDate: this.state.startDate,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            timeZone: this.state.timeZone,
            isSelfPrint: this.state.isSelfPrint,
            isCentralPrint: this.state.isCentralPrint,
            bypassTutorial: this.state.bypassTutorial,
            snapshotIntervalWeeks: this.state.snapshotIntervalWeeks,
            hasSnapshotAccess: this.state.hasSnapshotAccess ,
            notes: this.state.sessionNotes,
        }

        authAxios.post(`${baseIocSessionURL}`, data)
            .then(res => {
                this.setState({ sessionSubmitted: true, redirectPath: `/coaching/sessions/${res.data.id}` })
            })
            .catch(() => {
                this.setState({ submitMessage : 'We apologize.  Something broke while creating your session.  Please try again and if the problem persists contact our IT team at development@insideoutdev.com'})
            })
    }

    updateCoachingSession = (e) => {
        e.preventDefault()

        let { id } = this.state.selectedIocSession

        const updateSessionData = {
            name: this.state.sessionName,
            companyId: this.state.companyId,   
            sessionAdminId: this.state.sessionAdminId,
            facilitatorId: this.state.sessionFacilitatorId,
            startDate: this.state.startDate,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            timeZone: this.state.timeZone,
            isSelfPrint: this.state.isSelfPrint,
            isCentralPrint: this.state.isCentralPrint,
            bypassTutorial: this.state.bypassTutorial,
            snapshotIntervalWeeks: this.state.snapshotIntervalWeeks,
            hasSnapshotAccess: this.state.hasSnapshotAccess,
            notes: this.state.sessionNotes,
        }

        authAxios.put(`${baseIocSessionURL}/${id}`, updateSessionData)
            .then(() => {
                this.setState({ sessionSubmitted: true, redirectPath: `/coaching/sessions/${id}`})
            })
            .catch(() => {
                return this.setState({submitMessage : 'We apologize.  Something broke while updating your session.  Please try again and if the problem persists contact our IT team at development@insideoutdev.com'})
            })
    }

    clearIocSession = () => {
        this.setState(defaultIocSessionState)
    }


    render(){
        return (
            <CoachingSessionsContext.Provider 
                value={{
                    ...this.state,
                    handleIocSessionChange: this.handleIocSessionChange,
                    createCoachingSession: this.createCoachingSession,
                    updateCoachingSession: this.updateCoachingSession,
                    clearIocSession: this.clearIocSession,
                    setCoachingSessionDetails: this.setCoachingSessionDetails
                }}>
                { this.props.children }
            </CoachingSessionsContext.Provider>
        )
    }
}

export default CoachingSessionsProvider

export const withCoachingSessions = C => props => (
    <CoachingSessionsContext.Consumer>
        {value => <C {...props} {...value}/>}
    </CoachingSessionsContext.Consumer>
)

