// HOC can wrap any component we want to have LogRocket session recordings on.
    // Wherever the HOC is called is where the recording will begin. 
    // Recording will continue through any component until their session terminates
    // Streamlines Logrocket session usage so we arent capturing if the user never interacts with a component we dont care about

import { nodeEnvironment } from '../config/Config'
import useSetLogrocketIdentifiers from './CustomHooks/LogRocket/useSetLogrocketIdentifiers'
import useLogRocketRecord from './CustomHooks/LogRocket/useLogRocketRecord'

// And any paths here that you want logrocket recording on within localhost or dev.insideoutdev.com
    // These are exact paths. 
    // I.E if /digital-foundations/ is in the list and you go to /digital-foundations, it will not record
// You will also need to add the HOC into the component on that route
// This allows us to use a universal HOC everywhere in the codebase and the HOC will determine if it should record
// Recorded sessions from these enviroments go to the TESTING project in logrocket to not corrupt user data
const desiredTestingPaths = [
    '/digital-foundations/btdf',
]

const LogRocketHoc = Component => {

    const UpdatedComponent = props => {
        useSetLogrocketIdentifiers(props.currentUser)
        if(nodeEnvironment === 'production') useLogRocketRecord()
        if(nodeEnvironment !== 'production' && props.location) {
            if(desiredTestingPaths.includes(props.location.pathname)) useLogRocketRecord()
        }

        return <Component {...props} />
    }
    return UpdatedComponent
}

export default LogRocketHoc