import { useState, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withUsers } from '../../Context/UsersProvider'

const CookieBanner = ({userLocation}) => {
    let token = localStorage.getItem('iodToken')
    let sessid = Cookies.get('SESSID')
    const [ isDisplaying, setIsDisplaying ] = useState(true)

    useEffect(() => {
        if(token || sessid ) setIsDisplaying(false)
    }, [token, sessid ])
    
    const handleCookieAcknowledgement = (value) => {
        axios.post('/v1/cookies/acknowledgement', { didConsent: value, location: userLocation.IPv4 })
        .then(() => {
            value === false && sessionStorage.setItem('declinedCookie', true)
            setIsDisplaying(false)
        })
        .catch(err => err)
    }

    return (
        <>
            {isDisplaying &&
                <article className="cookie-banner redesign">
                    <div className="cookie-banner-content">
                        <FontAwesomeIcon icon='info-circle' />
                        <section>
                            <p className='body-2'><strong>Website Cookies</strong></p>
                            <p className='body-2'>
                                We use cookies to ensure you have the best experience. By using our website, you agree to the terms of our
                                <Link to='/privacy-policy' target="_blank">Privacy Policy.</Link>
                            </p>
                        </section>
                        <span className="cookie-banner-content-buttons">
                            <button 
                                onClick={() => handleCookieAcknowledgement(true)}
                                className='btn-contained-white button-type'
                            >
                                Accept
                            </button>
                            <button 
                                onClick={() => handleCookieAcknowledgement(false)}
                                className='btn-outlined-dark button-type'
                            >
                                Decline
                            </button>
                        </span>
                    </div>
                </article>
            }
        </>
    )
}

export default withUsers(CookieBanner);
