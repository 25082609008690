import axios from 'axios'

let authInstance = axios.create()

authInstance.interceptors.request.use(config => {
    const token = localStorage.getItem("iodToken")
    config.headers.Authorization = `Bearer ${token}`
    return config;
})

export const authAxios = authInstance