import { useEffect, useState } from "react"

const DropdownAnimate = ({ show, children }) => {
    const [render, setRender] = useState(show)

    useEffect(() => {
        if (show) setRender(true)
    }, [show])

    const onAnimationEnd = () => {
        if (!show) setRender(false)
    }

    return (
        <div
            style={{
                visibility: render ? 'visible' : 'hidden',
                animation: `${show ? "growDown " : "growUp"} 300ms ease-in-out forwards`,
                transformOrigin: 'top center'
            }}
            onAnimationEnd={onAnimationEnd}
        >
            {children}
        </div>
    )
}

export default DropdownAnimate
