import { lazy, Suspense } from 'react'
import Cookies from 'js-cookie'
import { Route, Switch } from 'react-router-dom'
import { PublicRoute }  from './shared/Routes/PublicRoute'
import { withAuth } from './Context/AuthProvider'
import { withUsers } from './Context/UsersProvider'
import { ExternalRedirect } from './GlobalComponents/ExternalRedirects/ExternalRedirect'
import { rootDomain } from './config/Config'
//Routes
import ProtectedRoute from './shared/Routes/ProtectedRoute'
import ProtectedRouteV2 from './shared/Routes/ProtectedRouteV2'
import LmsSnapshotQuiz from './GlobalComponents/Snapshots/LmsSnapshot/LmsSnapshotQuiz'
import LmsSnapshotFeedbackRequest from './GlobalComponents/Snapshots/LmsSnapshot/LmsSnapshotFeedbackRequest'
import CookieBanner from './GlobalComponents/Banners/CookieBanner'
import IEShame from './GlobalComponents/Banners/IEShame'
import './Assets/FaIcons'
const Portal = lazy(() => import('./ProtectedCommunities/Portal/Portal'))
const PortalRegister = lazy(() => import('./ProtectedCommunities/Portal/Register'))
const ForgotPassword = lazy(() => import('./ProtectedCommunities/Portal/ForgotPassword'))
const ResetPassword = lazy(() => import('./GlobalComponents/ResetPassword/ResetPassword'))
const PrivacyPolicy = lazy(() => import('./GlobalComponents/PrivacyPolicy/PrivacyPolicy'))
const IOCSnapshotSurvey = lazy(() => import('./GlobalComponents/Snapshots/CoachingSnapshots/IOCSurveyStyle'))
// const VirtualEventDetails = lazy(() => import('./GlobalComponents/VirtualShipping/VirtualShipping'))
const UnsubscribeFromEmail = lazy(() => import('./GlobalComponents/UnsubscribeFromEmail/UnsubscribeFromEmail'))
const NotFound404 = lazy(() => import('./GlobalComponents/NotFound404/NotFound404'))
// Protected Routes 
const DashboardHome = lazy(() => import('./ProtectedCommunities/Dashboard/DashboardLanding'))
const A2bHome = lazy(() => import('./ProtectedCommunities/a2b/A2bIndex'))
const IOCDFIndex = lazy(() => import('./ProtectedCommunities/DigitalFoundations/IOCDFIndex'))
const ParticipantPortalIndex = lazy(() => import('./ProtectedCommunities/ParticipantPortal/ParticipantPortalIndex'))
const Preferences = lazy(() => import('./ProtectedCommunities/Global/Preferences/PreferencesLanding'))

let isShowingCookieBanner = true;
let token = localStorage.getItem('iodToken')
let declinedCookie = sessionStorage.getItem('declinedCookie')
let acceptedCookie = Cookies.get('SESSID')

if(token || declinedCookie || acceptedCookie) isShowingCookieBanner = false
const isIE = false || !!document.documentMode;

const App = () => {
  const renderLoader = () => <p>Loading...</p>

  return (
    <div className='App'>
      {isShowingCookieBanner && <CookieBanner/> }
      <Suspense fallback={renderLoader()}>
        <Switch>
          <ProtectedRouteV2 exact path='/' component={DashboardHome} site={'Dashboard'} />
          <PublicRoute path='/contact' component={() => ExternalRedirect(`${rootDomain}/contact`)} />
          <PublicRoute path='/portal/login' component={Portal} />
          <PublicRoute path='/portal/register/' component={PortalRegister} />
          <PublicRoute path='/portal/register/:id' component={PortalRegister} />
          <PublicRoute path='/forgot-password' component={ForgotPassword} />
          <PublicRoute path='/password-reset/:id' component={ResetPassword} />
          <PublicRoute path='/privacy-policy' component={PrivacyPolicy} />
          <PublicRoute path='/unsubscribe/:id' component={UnsubscribeFromEmail}/>
          <Route path='/snapshots/coaching-snapshot/v1/' component={isIE ? IEShame : IOCSnapshotSurvey} />
          <Route path='/snapshots/coaching-snapshot/v2/feedback/' component={isIE ? IEShame : LmsSnapshotFeedbackRequest} />
          <Route path='/snapshots/coaching-snapshot/v2/' component={isIE ? IEShame : LmsSnapshotQuiz} />
          <ProtectedRouteV2 path='/preferences' exact component={Preferences} site={'Participant Portal'} />
          <ProtectedRouteV2 path='/dashboard' component={DashboardHome} site={'Dashboard'} />
          <ProtectedRouteV2 path='/a2b' component={A2bHome} redirectTo={"/dashboard"} site={'a2b'} community='a2b' />
          <ProtectedRoute path='/digital-foundations/iocdf' component={IOCDFIndex} site={'IOCDF'} />
          <ProtectedRouteV2 path='/participant-portal' component={ParticipantPortalIndex} site={'Participant Portal'} />
          {/* <OldReactCommunityRoutes /> */}
          <PublicRoute path='*' component={NotFound404} />
        </Switch>
      </Suspense>
    </div>
  )
}

export default withAuth(withUsers(App))
