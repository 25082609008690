import React, { Component } from 'react'
import { authAxios } from '../../shared/CustomAxios'
import moment from 'moment'
import sanitizeData from '../../shared/HelperFunctions/SanitizeData'

const DfSessionsBaseUrl = '/v1/api/digital-foundations/sessions'
const burstURL = '/v1/api/digital-foundations/user-bursts'

const DfSessionsContext = React.createContext()

const defaultDfSessionState = {
    selectedDfSession: {},
    selectedDfStartDate: '',
    selectedDfEndDate: '',
    selectedDfCompany: {},
    participantsBursts: [],
    participantsBurstsAvailable: 0,
    sessionName: '',
    sessionStart: new Date().toISOString().slice(0,10),
    burstsPerWeek: 0,
    unlockDayOne: '',
    unlockDayTwo: '',
    isSessionGated: false,
    emailSending: true,
    submitMessage: '',
    editingSession: false,
    sessionError: false,
    sessionSubmitted: false,
    redirectPath: ''
}

class DFSessionsProvider extends Component {
    constructor(){
        super()
        this.state = defaultDfSessionState
    }

    dfSessionHandleChange = (e) => {
        if(e.target.type === 'checkbox'){
            const { name, checked } = e.target
            this.setState({ [name]: checked })
        } else if(e.target.name === 'burstsPerWeek'){
            const { name, value } = e.target
            const setAsInteger = parseInt(value, 10);
            this.setState({ [name]: setAsInteger })
        } else if(e.target){
            const { name, value } = e.target
            this.setState({ [name]: sanitizeData(value) })
        }
    }

    setDfSessionDetails = (selectedCompanySession) => {
        const session = selectedCompanySession
        const company = session.Company
        const startDate = session.startDate.slice(0, 10)
        const endDate = session.endDate !== null && session.endDate.slice(0, 10)
        const participants = session.SessionParticipants.filter(participant => participant.userId !== null)
        const invitedParticipants = session.SessionParticipants.filter(participant => participant.userId === null)
        this.setState({ selectedDfSession: session, selectedDfStartDate: startDate, selectedDfEndDate: endDate, selectedDfCompany: company, sessionParticipants: participants, invitedParticipants: invitedParticipants}, () => this.setDfEditingState(session))
    }

    validateDays = () => {
        if(this.state.burstsPerWeek > 1 &&  this.state.unlockDayOne !== '' ){
            this.state.unlockDayOne === this.state.unlockDayTwo ?
            this.setState({ submitMessage: 'Please select two different days', sessionError: true })
            :
            this.setState({ submitMessage: '', sessionError: false })
        }
    }

    getSessionsBursts = (participantId, sessionId) => {
        authAxios
        .get(`${burstURL}?userId=${participantId}&sessionId=${sessionId}`)
        .then(res => {
            const usersBursts = res.data[0]
            const available = usersBursts.User.isIocDfAdmin || !usersBursts.isGated ? 5 : usersBursts.availableSteps 
            this.setState({ participantsBursts: usersBursts, participantsBurstsAvailable: available })
        })
        .catch( err => {  
            throw err
        });
    }

    calculateEndDate = () => {
        const addFiveWeeks = moment(`${this.state.sessionStart}`, "YYYY-MM-DD").add(5, 'w').format();
        const addThreeWeeks = moment(`${this.state.sessionStart}`, "YYYY-MM-DD").add(3, 'w').format();

        const endDate = this.state.burstsPerWeek === 1 ? addFiveWeeks: this.state.burstsPerWeek === 2 ? addThreeWeeks : null

        return endDate
    }
    
    createDfSession = (e, community, user, companyId) => {
        e.preventDefault()
        const calculatedEndDate = this.calculateEndDate()

        let emailDay1;
        let emailDay2;
        switch(this.state.burstsPerWeek){
            case 0:
                emailDay1 = null
                emailDay2 = null
                break
            case 1:
                emailDay1 = this.state.unlockDayOne
                emailDay2 = null
                break
            case 2:
                emailDay1 = this.state.unlockDayOne
                emailDay2 = this.state.unlockDayTwo
                break
            default:
                break
        }

        const data = {  
            adminId: user.id,
            companyId: companyId.length > 0 ? companyId: user.companyId,
            name: this.state.sessionName,
            isSendingEmails: this.state.emailSending,
            isScheduled: this.state.isSessionGated,
            startDate: this.state.sessionStart,
            endDate: this.state.isSessionGated ? calculatedEndDate : null,
            weeklyEmailQuantity: this.state.burstsPerWeek,
            emailDay1: emailDay1,
            emailDay2: emailDay2,
            product: community
        }

        authAxios.post(`${DfSessionsBaseUrl}`, data)
            .then(res => {
                this.setState({ sessionSubmitted: true, redirectPath: `/digital-foundations/${community}/admin/sessions/${res.data.id}` })
            })
            .catch( () => {
                this.setState({ submitMessage : 'We apologize.  Something broke while creating your session.  Please try again and if the problem persists contact our IT team at development@insideoutdev.com'})
            })
    }

    setDfEditingState = (session) => {
        const { name, isScheduled, weeklyEmailQuantity, emailDay1, emailDay2, isSendingEmails } = session
        let emailDayOne = emailDay1
        let emailDayTwo = emailDay2
        if(emailDay1 === null) emailDayOne = ''
        if(emailDay2 === null) emailDayTwo = ''

        this.setState({ sessionName: name, isSessionGated: isScheduled, sessionStart: this.state.selectedDfStartDate, burstsPerWeek: weeklyEmailQuantity, unlockDayOne: emailDayOne, unlockDayTwo: emailDayTwo, emailSending: isSendingEmails  })
    }

    updateDfSession = (e, community) => {
        e.preventDefault()
        const calculatedEndDate = this.calculateEndDate()

        let { id } = this.state.selectedDfSession
        let endDate = this.state.isSessionGated ? calculatedEndDate : null
        let weeklyEmailQuantity = this.state.burstsPerWeek
        let emailDay1 = this.state.unlockDayOne
        let emailDay2 = this.state.unlockDayTwo

        if(this.state.isSessionGated === false){
            weeklyEmailQuantity = null
            emailDay1 = null
            emailDay2 = null
        }

        if(weeklyEmailQuantity === 1){
            emailDay2 = null
        }

        const updateSessionData = {
            name: this.state.sessionName,
            isSendingEmails: this.state.emailSending,
            isScheduled: this.state.isSessionGated,
            startDate: this.state.sessionStart,
            endDate: endDate,
            weeklyEmailQuantity: weeklyEmailQuantity,
            emailDay1: emailDay1,
            emailDay2: emailDay2,
        }

        authAxios.put(`${DfSessionsBaseUrl}/${id}`, updateSessionData)
            .then(() => {
                this.setState({ sessionSubmitted: true, redirectPath: `/digital-foundations/${community}/admin/sessions/${id}`})
            })
            .catch(() => {
                return this.setState({submitMessage : 'We apologize.  Something broke while updating your session.  Please try again and if the problem persists contact our IT team at development@insideoutdev.com'})
            })
        
    }

    clearDfState = () => {
        this.setState(defaultDfSessionState)
    }

    render(){
        return (
            <DfSessionsContext.Provider 
                value={{
                    ...this.state,
                    dfSessionHandleChange: this.dfSessionHandleChange,
                    setDfSessionDetails: this.setDfSessionDetails,
                    validateDays: this.validateDays,
                    getSessionsBursts: this.getSessionsBursts,
                    setDfEditingState: this.setDfEditingState,
                    createDfSession: this.createDfSession,
                    updateDfSession: this.updateDfSession,
                    clearDfState: this.clearDfState
                }}>
                { this.props.children }
            </DfSessionsContext.Provider>
        )
    }
}

export default DFSessionsProvider

export const withDfSessions = C => props => (
    <DfSessionsContext.Consumer>
        {value => <C {...props} {...value}/>}
    </DfSessionsContext.Consumer>
)
