import { useState, useEffect } from 'react'
import { withSnapshots } from '../../../Context/SnapshotProvider'
import { withAuth } from '../../../Context/AuthProvider'
import { useHistory, Link } from 'react-router-dom'
import userQuestionsData from './LmsUserQuestionsData.json'
import guestQuestionsData from './LmsGuestQuestionsData.json'
import LmsSnapshotQuestion from './LmsSnapshotQuizQuestions'
import LogRocketHoc from '../../../shared/LogRocketHoc'

// window.location !== window.parent.location

const LmsSnapshotQuiz = ({ 
    getUserFromToken,
    user,
    setSnapshotUser,
    checkIfRequired, 
    snapshotSurvey, 
    isSelfAssessment, 
    isQuestionRequired, 
    snapshotRecipient, 
    getSnapshotLocationUrl,
    submitSnapshotV2, 
    snapshotMessage
}) => {
    let [currentIndex, setCurrentIndex] = useState(0)
    const history = useHistory()
    const search = history.location.search
    const params = new URLSearchParams(search)
    const userParam = params.get('user')
    const containerParam = params.get('container')

    useEffect(() => {
        checkIfRequired(currentIndex + 1)
    }, [ checkIfRequired, currentIndex, snapshotSurvey ])

    useEffect(() => {
        getUserFromToken() 
    }, [])

    useEffect(() => {
        getSnapshotLocationUrl(userParam, containerParam)
    }, [ userParam ])

    useEffect(() => {
        setSnapshotUser(user)
    }, [ user ])

    const nextQuestion = () => {
        setCurrentIndex(currentIndex + 1)
        checkIfRequired()
    }

    const previousQuestion = () => {
        if(currentIndex === 0)
            return;
        setCurrentIndex(currentIndex - 1)
    }

    const submitSurvey = (e) => {
        let type = window.location === window.parent.location ? 'grow' : 'lms'
        submitSnapshotV2(e, type)
        nextQuestion()
    }

    const mappedGuestQuestions  = guestQuestionsData.coachingVersion2GuestQuestions.map( (question, i) => {
        return (
            <div className={i === currentIndex ? 'snapshot-quiz-content-card-survey' : 'snapshot-quiz-content-card-invisible'} key={i}>
                <LmsSnapshotQuestion 
                    snapshotRecipientName={snapshotRecipient.firstName} 
                    question={question.questionText} 
                    type={question.questionType} 
                    questionNumber={question.questionNumber}/>
            </div>
        )
    })

    const mappedUserQuestions  = userQuestionsData.coachingVersion2UserQuestions.map( (question, i) => {
        return (
            <div className={i === currentIndex ? 'snapshot-quiz-content-card-survey' : 'snapshot-quiz-content-card-invisible'} key={i}>
                <LmsSnapshotQuestion 
                    question={question.questionText} 
                    type={question.questionType} 
                    questionNumber={question.questionNumber} 
                />
            </div>
        )
    })

    const quizQuestionsArray = !isSelfAssessment ? mappedGuestQuestions : mappedUserQuestions
    const percentComplete = Math.round(((currentIndex) / (quizQuestionsArray.length)) * 100)
    const percentBar = 100 + (Math.round(-((currentIndex) / (quizQuestionsArray.length)) * 100))

    return (
        <div className='snapshot-quiz-wrap'>
            <header className='snapshot-quiz-content-header'>
                <h3>GROW COACHING SNAPSHOT</h3>
                <p>{isSelfAssessment ? 'Self assessment' : 'Feedback'} for {snapshotRecipient.firstName} {snapshotRecipient.lastName}</p>
            </header>
            <h2>Think about the conversations you have had in the past 90 days…</h2>

            <div className='snapshot-quiz-progress-wrap'>
                <p className='snapshot-quiz-progress-count'>{percentComplete}%</p>
                <aside className='snapshot-quiz-progress'>
                    <div className='snapshot-quiz-progress-inner' style={{width: `${percentBar}%`}}>
                        <p></p>
                    </div>
                </aside>
            </div>

            <main className='snapshot-quiz'>
                <section className='lms-snapshot-quiz-content'>
                    {currentIndex <= (quizQuestionsArray.length -1) ?
                        <article>
                            {quizQuestionsArray}
                        </article>
                        :
                        <article>
                            { snapshotMessage && 
                                (
                                    snapshotMessage.includes('error') ?
                                    <>
                                        <h3> {snapshotMessage} </h3> 
                                        <button className='snapshot-quiz-arrow-back' 
                                            onClick={previousQuestion}>
                                            BACK
                                        </button>
                                    </>
                                    :
                                    <>
                                        <h3> {snapshotMessage} </h3> 
                                        {isSelfAssessment ?
                                            <Link className='button-blue'  to='/snapshots/coaching-snapshot/v2/feedback/'> Click here to request feedback from colleagues </Link>
                                            :
                                            <p>Thank you for taking the time to give {snapshotRecipient.firstName} {snapshotRecipient.lastName} feedback for their coaching journey. You can now close this window.</p>
                                        }
                                        {/* Below displays only for GROW not on the LMS */}
                                        {window.location === window.parent.location && 
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '25px'}}>
                                            {(isSelfAssessment && user.hasGrowCoaching) &&
                                                <>
                                                {user.isNewGrowUser ?
                                                    <Link to='/participant-portal/grow-coaching/prework'> Return to Prework </Link>
                                                    :
                                                    <Link to='/participant-portal/snapshots/grow-coaching'> Return to Participant Portal </Link>
                                                }
                                                </>
                                            }
                                            </div>
                                        }
                                    </>
                                )   
                            }
                        </article>
                    }
                </section>

                <nav className='snapshot-quiz-arrow'>
                    {((currentIndex > 0) && (currentIndex < quizQuestionsArray.length)) ?
                        <button className='snapshot-quiz-arrow-back' 
                            onClick={previousQuestion}>
                            BACK
                        </button>
                        :
                        <div></div>
                    }

                    {currentIndex < (quizQuestionsArray.length -1) ?
                        <button className='snapshot-quiz-arrow-next' 
                            disabled={isQuestionRequired}
                            onClick={nextQuestion}>
                            NEXT
                        </button>
                        :
                        <button className={currentIndex < quizQuestionsArray.length ? 'snapshot-quiz-arrow-submit' : 'snapshot-quiz-content-card-invisible' }
                            onClick={submitSurvey} 
                            disabled={isQuestionRequired}
                        > 
                            SUBMIT  
                        </button>
                    }
                </nav>
            </main> 
        </div>
    )
}

export default LogRocketHoc(withSnapshots(withAuth(LmsSnapshotQuiz)))