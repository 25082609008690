import React, { Component } from 'react'
import axios from 'axios'
import { authAxios } from '../../shared/CustomAxios'
import sanitizeData from '../../shared/HelperFunctions/SanitizeData'

const baseUrlSessionParticipants = '/v1/api/global/session-participants'
const baseUrlAuth = '/v1/auth'
const SessionParticipantsContext = React.createContext()

class SessionParticipantsProvider extends Component {
    constructor(){
        super()
        this.state = {
            sessionId: '',
            companyId: '',
            adminId: '',
            adminName: '',
            product: '',
            userSearch: '',
            ignoreAccessRightsSearch: false,
            // Unique to Digital Foundations
            isSessionGated: true,
            emailInvitationSuccessful: null,
            failedUserInvites: [],
            sessionInvitationLoading: false,
            allSesionParticipants: [],
            existingUsersToAdd: [],  
            usersToInvite: [], 
            allUsers: [],
            participantsInSession: [],
            userToInvite: { // This will handle the current selected user before adding them to the array/s above
                userId: null, 
                name: null, 
                email: null, 
                sessionId: null, 
                adminId: null, 
                companyId: null, 
                product: null  
            },
        }
    }


    handleSessionParticipantsChange = (e) => {
        const { name, value} = e.target
        this.setState({ [name]: sanitizeData(value) })
    }

    handleSearchAllUsers = (e) => {
        const { name, checked } = e.target
        this.setState({ [name]: sanitizeData(checked) })
    }

    // Function will be called when 'Next' is clicked on step 1 after selecting all users to add to the session
        // usersArray will be all the users who are checked from the list component and passed into the callback
    handleSetExistingUsers = (usersArray) => {
        this.setState({ existingUsersToAdd: usersArray })
    }

    //Gets list of existing users to invite
    getUsersToInvite = async (user) => {
        // Gets a full list of users when trying to invite users to sessions, no pagination
        let url = `${baseUrlAuth}/users`
        let superAdminsQuery = this.state.ignoreAccessRightsSearch ? '' : '?hasIocDf=1'
        let queryString = user.isIodSuperAdmin ? superAdminsQuery : `?hasIocDf=1&companyId=${user.companyId}`

        if(queryString){ url = url+queryString }

        const userEmailArray = this.state.participantsInSession.map(participant => participant.email)

        await authAxios.get(`${url}`)
        .then( res => {
            let users = res.data
            let uniqueUserArray = []
            users.forEach(user => {
                let checkArray = userEmailArray.indexOf(user.email)  
                checkArray < 0 && uniqueUserArray.push(user)
            })

            if(this.state.userSearch.length > 0) {
                let filteredResults = uniqueUserArray.filter(user =>
                    user.firstName.indexOf(this.state.userSearch) >  -1 ||
                    user.lastName.indexOf(this.state.userSearch) >  -1 ||
                    user.email.indexOf(this.state.userSearch) >  -1
                )
                uniqueUserArray = filteredResults
            }

            this.setState({ existingUsersToAdd : uniqueUserArray, usersCount: uniqueUserArray.length, userSearch: '' })
        })
        .catch( err => {
            throw err
        })
    }

    handleSelectUser = (i, isSelected) => {
        let updatedUserArray = this.state.existingUsersToAdd
        let updatedUserObject = this.state.existingUsersToAdd[i]
        if (!isSelected) {
            updatedUserObject.isSelected = true
        } else {
            updatedUserObject.isSelected = false
        }
        updatedUserArray[i] = updatedUserObject
        this.setState({ existingUsersToAdd: updatedUserArray })
    }

    clearInvitationState = () => {
        this.setState({ emailInvitationSuccessful: null, failedUserInvites: [], sessionInvitationLoading: false, existingUsersToAdd: [], })
    }

    handleRemoveNewUser = (i) => {
        let newUsersToInviteArray = this.state.usersToInvite
        if(window.confirm(`Are you sure you would like to remove ${newUsersToInviteArray[i].firstName}?`)) {
            newUsersToInviteArray.splice(i, 1)
            this.setState({ usersToInvite: newUsersToInviteArray })
        }
    }

    handleRemoveExistingUser = (i) => {
        let existingUsersToInviteArray = this.state.existingUsersToAdd
        let existingUsersToInviteObject = this.state.existingUsersToAdd[i]
        if(window.confirm(`Are you sure you would like to remove ${existingUsersToInviteObject.firstName}?`)) {
            existingUsersToInviteObject.isSelected = false
            existingUsersToInviteArray[i] = existingUsersToInviteObject
            this.setState({ existingUsersToAdd: existingUsersToInviteArray })
        }
    }

    handleSetSessionData = (id) => {
        authAxios
        .get(`/v1/api/digital-foundations/sessions/${id}`)
        .then(res => {
            const session = res.data[0]
            const { id, companyId, adminId, product, isScheduled } = session;
            let lowerCaseProduct = product.toLowerCase()
            const sessionParticipants = session.SessionParticipants
            this.setState({ sessionId: id, companyId: companyId, adminId: adminId, product: lowerCaseProduct, isSessionGated: isScheduled, participantsInSession: sessionParticipants })
            axios
                .get(`/v1/auth/user/${adminId}`)
                .then(res => {
                    this.setState({ adminName: `${res.data.firstName} ${res.data.lastName}` })
                })
                .catch( err => err)
        })
        .catch( err => {  
            throw err
        });
    }

    handleSetCoachingSessionData = (id) => {
        authAxios
        .get(`/v1/api/ioc/sessions/${id}`)
        .then(res => {
            const session = res.data[0]
            const { id, companyId, adminId, User } = session;
            const sessionParticipants = session.SessionParticipants
            const sessionAdmin = User ? `${User.firstName} ${User.lastName}` : ''
            this.setState({ sessionId: id, companyId: companyId, adminId: adminId, participantsInSession: sessionParticipants, adminName: sessionAdmin, product: 'coaching' })
        })
        .catch( err => {  
            throw err
        });
    }

    //  Function will be used on the review page to cut a member out of the state array of existing users if 'delete' is clicked
    removeOneFromExistingUsersToAdd = (index) => {
        let existingUsersArray = this.state.existingUsersToAdd
        existingUsersArray.splice(index, 1)
        this.setState({ existingUsersToAdd: existingUsersArray })
    }


    //  Function will be called when 'Add Participant' is clicked on step 2 after they add the users name and email
    handleSetInvitationUser = () => {
        this.setState({ usersToInvite: [...this.state.usersToInvite, this.state.userToInvite] })
    }


    //  Function will be used on the review page to cut a member out of the state array of invitation users if 'delete' is clicked
    removeOneFromUsersToInvite = (index) => {
        let newUsersArray = this.state.usersToInvite
        newUsersArray.splice(index, 1)
        this.setState({ usersToInvite: newUsersArray })
    }


    //  Function for initilizing all next steps to add the 2 users arrays in state to the sessionParticipants database
    handleAddParticipantsToSession = () => {
        this.setState({ sessionInvitationLoading: true })
        let existingUsers = this.state.existingUsersToAdd.filter(user => user.isSelected)
        let allUsers = [ ...existingUsers, ...this.state.usersToInvite ]
        this.updateOrInviteAllUsers(allUsers)
    }


    // Takes users and sends them to the back end to either authorize account, send intro email, or send invitation email
    updateOrInviteAllUsers = (allUsers) => {
        let community = this.state.product
        let adminId = this.state.adminId
        let sessionId = this.state.sessionId
        let adminName = this.state.adminName
        let companyId = this.state.companyId
        let isSessionGated = this.state.isSessionGated
        let failedUserInvites = []

        //  3rd - For each user, hit the correct route to update their access rights and send them an email
        async function sendInviteOrModifyAccess(user){
            let isNewUser = null
            user.id ? isNewUser = false : isNewUser = true

            user.participantName = `${user.firstName} ${user.lastName}`
            user.participantEmail = user.email
            user.invitersName = adminName
            user.invitersEmail = ''
            user.invitersUserId = adminId
            user.sessionId = sessionId
            user.isNewUser = isNewUser
            user.companyId = companyId
            user.isSessionGated = isSessionGated
            user.community = community

            const updatedResponse = await axios.post(`${baseUrlAuth}/users/df/invite-participant`, user)
            .then(res => res.data)
            .catch( err => {
                if(err.response.status === 422){
                    //  User already in session, do nothing.  Leaving this here in case we need to scale.
                }else if(err.response.status === 400){
                    // Failed to send invite
                    failedUserInvites.unshift(user.participantName)
                }
            })
            return updatedResponse
        }

        const createNewSesionParticipant = async (user) => {
            let newSessionParticipant = {
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                sessionId: this.state.sessionId,
                adminId: this.state.adminId,
                companyId: this.state.companyId,
                product: this.state.product
            }

            let newSessionParticipantRecord = await authAxios.post(`${baseUrlSessionParticipants}/`, newSessionParticipant)
            .then( res => res.data )
            .catch( err => err)
    
            return newSessionParticipantRecord
        }

        const finalizeEmailInvitationStatus = (status, noneWorked) => {
            if(noneWorked) {
                this.setState({ emailInvitationSuccessful: status, failedUserInvites: failedUserInvites, sessionInvitationLoading: false })
            } else {
                this.setState({ emailInvitationSuccessful: status, failedUserInvites: failedUserInvites, sessionInvitationLoading: false, existingUsersToAdd: [], usersToInvite: [] })
            }
        }

        //  2nd - Loop through users array here
        async function processAllParticipants(allUsers) {
            for (let i = 0; i < allUsers.length; i++) {
                const user = allUsers[i]
                let userId = null
                let doesUserExist = await authAxios.get(`${baseUrlAuth}/users?email=${user.email}`)
                    .then( res => res.data[0] )
                    .catch( () => null)
                if(doesUserExist) userId = doesUserExist.id
                user.id = userId
                await sendInviteOrModifyAccess(user)
                await createNewSesionParticipant(user)
            }
            if(failedUserInvites.length === allUsers.length) {
                finalizeEmailInvitationStatus(false, true)
            } else if(failedUserInvites.length) {
                finalizeEmailInvitationStatus(false)
            } else {
                finalizeEmailInvitationStatus(true)
            }            
        }

        // 1st - Initialize the loop
        processAllParticipants(allUsers)
    }


    deleteSessionParticipant = (id) => {
        authAxios.delete(`${baseUrlSessionParticipants}/${id}`)
        .then( window.alert(`Participant has been removed.`) )
        .then(window.location.reload())
        .catch( err => err )
    }


    editSesionParticipant = (id) => {
        let updates = {}
        authAxios.put(`${baseUrlSessionParticipants}/${id}`, updates)
        .then( res => res.data )
        .catch( err => err )
    }


    render(){
        return (
            <SessionParticipantsContext.Provider 
                value={{
                    ...this.state,
                    getUsersToInvite: this.getUsersToInvite,
                    clearInvitationState: this.clearInvitationState,
                    handleSelectUser: this.handleSelectUser,
                    handleRemoveNewUser: this.handleRemoveNewUser,
                    handleRemoveExistingUser: this.handleRemoveExistingUser,
                    getSelectedSessionParticipant: this.getSelectedSessionParticipant,
                    handleSetSessionData: this.handleSetSessionData,
                    getAllSessionParticipants : this.getAllSessionParticipants, 
                    createNewSesionParticipant : this.createNewSesionParticipant,
                    handleSessionParticipantsChange: this.handleSessionParticipantsChange,
                    deleteSessionParticipant : this.deleteSessionParticipant,
                    editSesionParticipant : this.editSesionParticipant,
                    handleAddParticipantsToSession: this.handleAddParticipantsToSession,
                    handleSetCoachingSessionData: this.handleSetCoachingSessionData,
                    handleSearchAllUsers: this.handleSearchAllUsers
                }}>
                { this.props.children }
            </SessionParticipantsContext.Provider>
        )
    }
}

export default SessionParticipantsProvider

export const withSessionParticipants = C => props => (
    <SessionParticipantsContext.Consumer>
        {value => <C {...props} {...value}/>}
    </SessionParticipantsContext.Consumer>
)
