import { useEffect } from 'react'
import { withSnapshots } from '../../../Context/SnapshotProvider'
import { withAuth } from '../../../Context/AuthProvider'
import SnapshotEmailModal from '../../../ProtectedCommunities/Global/SnapshotEmailModal'

const LmsSnapshotFeedbackRequest = ({ user, getUserFromToken, setSnapshotUser, toggleSnapshotEmailTemplate }) => {

    useEffect(() => {
        async function setUserData(){
            await getUserFromToken() 
        }
        setUserData()
    }, [getUserFromToken])

    useEffect(() => {
        setSnapshotUser(user)
    }, [ user ])


    return (
        <div>
            <h1> Snapshot Feedback Request </h1>
            <h3> You can now request feedback from your colleagues anonymously. Once you have at least three responses, you can view your report.  </h3>
            <button 
                onClick={toggleSnapshotEmailTemplate} 
                className='rounded-btn btn-contained-default'
            > 
                Send Emails for Feedback 
            </button>
            <SnapshotEmailModal snapshotVersion={2}/>
        </div>
    )

}

export default withAuth(withSnapshots(LmsSnapshotFeedbackRequest))