import React, { Component } from 'react'
import axios from 'axios'
import { authAxios } from '../shared/CustomAxios'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'
import { nodeEnvironment } from '../config/Config'
// import sendNodeMailer from '../shared/HelperFunctions/sendNodeMailer'
const baseURL = '/v1/auth'
const baseURL2 = '/v2/authentication'
const baseURL3 = '/v1/api/global/users'
const authorizatonBaseURL = '/v1/authorizations'
const emailURL = '/v1/api/manual-email-send'
const employeeURL = '/v1/api/a2b/employees'
const UsersContext = React.createContext()

class UsersProvider extends Component {
    constructor(){
        super()
        this.state = {
            userSearch: '',
            usersNotification: '',
            addParticipantName: '',
            addParticipantEmail: '',
            addParticipantToCompany: '',
            addClientAdminName: '',
            addClientAdminEmail: '',
            addClientAdminToCompany: '',
            addUserNotification: '',
            isSendingInvitationEmail: false,
            isSearchingUser: false,
            userSearchInput: '',
            selectedUser: {},
            userLocation: {IPv4: null},
            // used in new
            users : [],
            clientAdmins: [],
            viewingPendingAccounts: null,
            usersCount: 0,
            pendingUsersCount: 0,
            userSort: ['email', 'ASC'],
            currentPageQuery: 0,
            pageSize: 25,
            selectedUsers: [], // used for displaying who is selected
            selectedUsersIds: [], // used to batch update
            filterQueryString: '',
            filterObject: {
                admin: {},
                status: {},
                product: {}
            },
            searchObject: {},
            searchValue: '',
            selectedTag: '',
            tagFilter: '',
            // editing
            accessRightsUpdated: null,
            statusUpdated: null,
            participantDeleted: false,
            participantEditFirstName: '',
            participantEditLastName: '',
            participantEditEmail: '',
        }
    }

    getAllUsers = (queryString) => {
        //  Gets a paginated list of 10 users and appends dynamic search queries if they exist
        const offset = +this.state.currentPageQuery * +this.state.pageSize;
        const limit = +this.state.pageSize;
        let url = `${baseURL}/users`
        if(queryString){ url = url+queryString }
        let body = {offset, limit}
        authAxios.post(`${url}`, body)
            .then( res => {
                this.setState({ users : res.data.rows, usersCount: res.data.count })
            })
            .catch( err => {
                throw err
            })
    }

    getIodUsers = () => {
        // Get all users with insideoutdev.com email domain
        const url = `${baseURL3}/iod`
        authAxios.get(`${url}`)
            .then( res => {
                this.setState({ users : res.data, usersCount: res.data.length })
            })
            .catch( err => {
                throw err
            })
    }
    
    getOneUser = (userId) => {
        authAxios.get(`/v1/auth/user/${userId}`)
        .then( res => {
            this.setState({ selectedUser: res.data })
        })
        .catch( err => {
            throw err
        })
    }

    updateUser = (userId) => {
        const url = `${baseURL}/user/details/${userId}`
        authAxios.put(url, this.state.selectedUser)
            .then( res => {
                this.setState({ selectedUser: res.data })
            })
            .catch( err => {
                throw err
            })
    }

    handleUserChange = (e) => {
        const { name, value, type, checked } = e.target
        let finalValue = type === 'checkbox' ? checked : sanitizeData(value)
        this.setState({
            selectedUser: {
                ...this.state.selectedUser,
                [name]: finalValue
            },
            [name]: finalValue,
        })
    }

    changePageNumber = (requestedPage, queryString) => {
        this.setState({ currentPageQuery: this.state.currentPageQuery + requestedPage }, () => this.getAllUsers(queryString))
    }

    clearPageState = () => {
        this.setState({ currentPageQuery: 0 })
    }

    searchCoachingParticipants = (companyId, userSearch) => {
        //  Gets all coaching users and appends dynamic search queries if they exist
        let url = `${baseURL}/users/coaching?hasCoaching=1&`
        if(companyId && userSearch){ 
            url = `${url}companyId=${companyId}&search=${userSearch}` 
        }else if(companyId && !userSearch){ 
            url = `${url}&companyId=${companyId}` 
        }else if(userSearch && !companyId){ 
            url = `${url}&search=${userSearch}` 
        }

        authAxios.get(`${url}`)
        .then( res => {
            this.setState({ users : res.data, usersCount : res.data.length, currentPageQuery: 0 })
        })
        .catch( err => {
            this.setState({ users : [], usersNotification: 'There are no users who match your search criteria' })
            throw err
        })
    }

    searchCoachingClientAdmins = (companyId, userSearch) => {
        if(userSearch.isSearching) this.setState({isSearchingUser : true})
        if(userSearch.isAddingUser) this.setState({isSearchingUser : false})
            
        //  Gets all coaching client admins and appends dynamic search queries if they exist
        let url = `${baseURL}/users/coaching/client-admin?isCoachingClientAdmin=1&`
        // if(this.state.userSearchInput){ url = `${url}companyId=${this.state.userSearchInput}` }
        if(this.state.userSearchInput){ url = `${url}&search=${this.state.userSearchInput}` }

        authAxios.get(`${url}`)
        .then( res => {
            if(res.data.length > 1){
                this.setState({ users : res.data, selectedUser: {}, usersCount : res.data.length, currentPageQuery: 0 })
            } else {
                this.setState({ users : res.data, selectedUser: res.data[0], usersCount : res.data.length })
            }

        })
        .catch( err => {
            this.setState({ users : [], usersNotification: 'There are no users who match your search criteria', selectedUser : {} })
            throw err
        })
    }

    searchDigitalFoundationsParticipants = (queryString) => {
        //  Gets all digital foundations users and appends dynamic search queries if they exist
        let url = `${baseURL}/users`
        // let url = `${baseURL}/users?hasIocDf=1&`
        // if(companyId && userSearch){ 
        //     url = `${url}companyId=${companyId}&search=${userSearch}` 
        // }else if(companyId && !userSearch){ 
        //     url = `${url}&companyId=${companyId}` 
        // }else if(userSearch && !companyId){ 
        //     url = `${url}&search=${userSearch}` 
        // }

        authAxios.get(`${url}${queryString}`)
        .then( res => {
            this.setState({ users : res.data, usersCount : res.data.length, currentPageQuery: 0 })
        })
        .catch( err => {
            this.setState({ users : [], usersNotification: 'There are no users who match your search criteria' })
            throw err
        })
    }

    searchUsers = (inputType) => {
        let userSearch;
        if(inputType.isSearching) {
            this.setState({isSearchingCompany : true, isAddingUserToCompany: false})
            userSearch = this.state.userSearchInput
        }
        if(inputType.isAddingUser){
            this.setState({isSearchingCompany : false, isAddingUserToCompany: true})
            userSearch = this.state.companyAddUserInput
        }
            
        authAxios.get(`${baseURL}?companyName=${userSearch}`)
        .then( res => {
            if(res.data.length > 1){
                this.setState({ companies : res.data, selectedCompany: {} })
            } else {
                this.setState({ companies : res.data, selectedCompany: res.data[0] })
            }
        })
        .catch( err => {
            this.setState({ companies : [], selectedCompany: {} })
            throw err
        })
    }
    
    isNewParticipantRegisteredInPortal = (submittingUser, newUsersCompany) => {
        this.setState({isSendingInvitationEmail: true})
        authAxios.get(`${baseURL}/users?email=${this.state.addParticipantEmail}`)
        .then(res => {
            //  User exists.  Add access to coaching community.
            this.addExistingUserToCoachingCommunity(res.data[0].id)
        })
        .catch(err => {
            //  User does not exist.  Need to send them an invite email.  Clear notification from state if they have previously added someone.
            this.setState({ addUserNotification: ``})
            this.sendNewUserInviteToCoachingCommunity(submittingUser, newUsersCompany)
            throw err
        })
    }

    addExistingUserToCoachingCommunity = (userId) => {
        const newAccess = { hasCoaching : 1 }
        axios.put(`${baseURL}/user/${userId}`, newAccess)
        .then(() => {
            this.setState({ addUserNotification: `${this.state.addParticipantEmail} has been added and has access to the Coaching Community. `, isSendingInvitationEmail: false })
        })
        .catch(err => {
            throw err
        })
    }

    sendNewUserInviteToCoachingCommunity = async (submittingUser, newUsersCompany) => {
        //  Data needed to generate the email invitation
        const body = {  
            participantName: this.state.addParticipantName, 
            participantEmail: this.state.addParticipantEmail , 
            invitersEmail: submittingUser.email 
        }
        
        if(submittingUser.isCoachingClientAdmin && !submittingUser.isIodSuperAdmin){
            //  Person ading new user is a client admin.  Generate company specific registration link
            body['companyId'] = submittingUser.companyId 
            body['invitersName'] = `${submittingUser.firstName} ${submittingUser.lastName}`
        }else if(submittingUser.isIodSuperAdmin){
            //  Person ading new user is an iod super admin.  Generate a generaic registration link without company attached, unless one was selected
            body['invitersName'] = `${submittingUser.firstName} ${submittingUser.lastName}`
            if(newUsersCompany) body['companyId'] = newUsersCompany
        }

        // Add Authorization Record Here
        await this.createAuthorizations(this.state.addParticipantEmail , body.companyId, { grantIoc: true })

        authAxios.post(`${baseURL}/users/coaching/invite-participant`, body)
        .then(() => {
            this.setState({ addUserNotification: `${this.state.addParticipantEmail} has been sent an email invitation to set up an account.  They will have access to the Coaching Community when they finish the registration process. You have been CC'd on the invitation email.`, isSendingInvitationEmail: false })
        })
        .catch(() => {
            this.setState({ addUserNotification: `We appologize, something broke while inviting the user.  Please try again and contact InsideOut technical support if the problem persists. `, isSendingInvitationEmail: false})
        })
    }


    createAuthorizations = async (email, company, access) => {
        let body = {
            email: email,
            company: company,
            access: access
        }

        try {
            let res = await axios.post(authorizatonBaseURL, body)
            return res
        } catch (err) {
            return err
        }
    }


    // These are used to set the data locally in this file from the global invite participants form.
    // Keeps the iodf and iodedu invitations working withut breaking the existing code.
    setNewParticipantEmail = email => this.setState({addParticipantEmail: email})
    setNewParticipantName = name => this.setState({addParticipantName: name})



    isNewDfParticipantRegisteredInPortal = (submittingUser, newUsersCompany, community) => {
        this.setState({isSendingInvitationEmail: true})
        authAxios.get(`${baseURL}/users?email=${this.state.addParticipantEmail}`)
        .then(res => {
            //  User exists.  Add access to coaching community.
            this.addExistingUserToDfCommunity(res.data[0].id, res.data[0].companyId, submittingUser, community)
        })
        .catch(err => {
            //  User does not exist.  Need to send them an invite email.  Clear notification from state if they have previously added someone.
            this.setState({ addUserNotification: ``})
            let access = ''
            if(community === 'iocdf') access = { grantIocdf: true }
            this.createAuthorizations(this.state.addParticipantEmail, newUsersCompany, access)

            this.sendNewUserInviteToDfCommunity(submittingUser, newUsersCompany, community)
            throw err
        })
    }

    addExistingUserToDfCommunity = (userId, newUsersCompany, submittingUser, community) => {
        let access;
        if(community === 'iocdf') access = 'hasIocDf'
        if(community === 'iocedu') access = 'hasIocEdu'
        let newAccess = { [access] : 1 }

        axios.put(`${baseURL}/user/${userId}`, newAccess)
        .then(() => {
            this.setState({ addUserNotification: `${this.state.addParticipantEmail} has been added and has access to the Digital Foundations Community. `, isSendingInvitationEmail: false })
        })
        .catch(err => {
            throw err
        })
        
        //  Adds new user burst record so participant can access
        const newUserBurst = {
            userId: userId,
            adminId: submittingUser.id,
            companyId: newUsersCompany,
            product: community,
            availableSteps: 0,
            availableStepUpdatedOn: new Date()
        }

        authAxios.post(`/v1/api/digital-foundations/user-bursts`, newUserBurst)
        .then( () => {
            // Users account has been activated. This next post is to send them a notification email.
            const body = {  
                participantName: this.state.addParticipantName, 
                participantEmail: this.state.addParticipantEmail , 
                invitersEmail: submittingUser.email,
                invitersUserId: submittingUser.id,
                invitersName: `${submittingUser.firstName} ${submittingUser.lastName}`,
                isNewUser: false,
                community: community
            }
            authAxios.post(`${baseURL}/users/df/invite-participant`, body)
            .then(() => {
                this.setState({ addUserNotification: `${this.state.addParticipantEmail} has been sent an email invitation with information to access this product. They will have access to the Digital Foundations Community immediately. You have been CC'd on the invitation email.`, isSendingInvitationEmail: false, addParticipantName: '', addParticipantEmail: '', addParticipantToCompany: '' })
            })
            .catch(() => {
                this.setState({ addUserNotification: `We appologize, something broke while sending the invitation email, but their account has been granted access.`, isSendingInvitationEmail: false})
            })
        })
        .catch( err => {
            throw err
        })
    }

    sendNewUserInviteToDfCommunity = (submittingUser, newUsersCompany, community) => {
        //  Data needed to generate the email invitation
        const body = {  
            participantName: this.state.addParticipantName, 
            participantEmail: this.state.addParticipantEmail , 
            invitersEmail: submittingUser.email,
            invitersUserId: submittingUser.id,
            isNewUser: true,
            community: community
        }
        
        if(submittingUser.isIocDfAdmin && !submittingUser.isIodSuperAdmin){
            //  Person ading new user is a client admin.  Generate company specific registration link
            body['companyId'] = submittingUser.companyId 
            body['invitersName'] = `${submittingUser.firstName} ${submittingUser.lastName}`
        }else if(submittingUser.isIodSuperAdmin){
            //  Person ading new user is an iod super admin.  Generate a generaic registration link without company attached, unless one was selected
            body['invitersName'] = `${submittingUser.firstName} ${submittingUser.lastName}`
            if(newUsersCompany) body['companyId'] = newUsersCompany
        }

        authAxios.post(`${baseURL}/users/df/invite-participant`, body)
        .then(() => {
            this.setState({ addUserNotification: `${this.state.addParticipantEmail} has been sent an email invitation to set up an account.  They will have access to the Digital Foundations Community when they finish the registration process. You have been CC'd on the invitation email.`, isSendingInvitationEmail: false, addParticipantName: '', addParticipantEmail: '', addParticipantToCompany: '' })
        })
        .catch(() => {
            this.setState({ addUserNotification: `We appologize, something broke while inviting the user.  Please try again and contact InsideOut technical support if the problem persists. `, isSendingInvitationEmail: false})
        })
    }

    isNewClientAdminRegisteredInPortal = (submittingUser, newUsersCompany) => {
        authAxios.get(`${baseURL}/users?email=${this.state.addClientAdminEmail}`)
        .then(res => {
            //  User exists.  Add access to be coaching client admin.
            this.addExistingUserAsClientAdmin(res.data[0].id)
        })
        .catch(err => {
            //  User does not exist.  Need to send them an invite email.  Clear notification from state if they have previously added someone.
            this.setState({ addUserNotification: ``})
            this.sendNewUserInviteToBeCoachingClientAdmin(submittingUser, newUsersCompany)
            throw err
        })
    }


    addExistingUserAsClientAdmin = (userId) => {
        const newAccess = { isCoachingClientAdmin : 1 }
        axios.put(`${baseURL}/user/${userId}`, newAccess)
        .then(() => {
            this.setState({ addUserNotification: `${this.state.addClientAdminEmail} has been added as a Coaching Client Admin.` })
        })
        .catch(err => {
            throw err
        })
    }

    sendNewUserInviteToBeCoachingClientAdmin = (submittingUser, newUsersCompany) => {
        //  Data needed to generate the email invitation
        const body = {  clientAdminName: this.state.addClientAdminName, 
            clientAdminEmail: this.state.addClientAdminEmail, 
            invitersEmail: submittingUser.email,
            invitersName: `${submittingUser.firstName} ${submittingUser.lastName}`,
            companyId: newUsersCompany
        }

        authAxios.post(`${baseURL}/users/coaching/invite-client-admin`, body)
        .then(() => {
            this.setState({ addUserNotification: `${this.state.addClientAdminEmail} has been sent an email invitaiton to set up an account.  They will have access to the Coaching Community and be added as a Client Admin when they finish the registration process. You have been CC'd on the invitation email.`})
        })
        .catch(() => {
            this.setState({ addUserNotification: `We appologize, something broke while inviting the user.  Please try again and contact InsideOut technical support if the problem persists. `})
        })
    }

    setSelectedUser = (selectedUser) => {
        if(this.state.userSearchInput.length > 0){
            this.setState({ selectedUser: selectedUser, 
                            userSearchInput: `${selectedUser.firstName} ${selectedUser.lastName}`})
        }        
    }

    toggleUserSearching = () => {
        this.setState({
            isSearchingUser: false
        })
    }

    clearUserProviderStateOnUnmount = () => {
        this.setState({ 
            users : [],
            userSearch: '',
            usersNotification: '',
            addParticipantName: '',
            addParticipantEmail: '',
            addParticipantToCompany: '',
            addUserNotification: ''
        })
    }

    dynamicGet = () => {
        let url = `/user`
        if(this.state.search.length){ 
            url = `${url}?search=${this.state.search}` 
        }

        axios.get(`${url}`)
    }

    // NEW
    getAllFilteredUsers = (user) => {
        let url = `${baseURL2}/filtered-users`
        //  Gets a paginated list of 10 users and appends dynamic search queries if they exist
        const offset = +this.state.currentPageQuery * +this.state.pageSize
        const limit = +this.state.pageSize
        const sort = this.state.userSort
        // append company search query if not super admin
        if(!user.isIodSuperAdmin){ url = `${url}?companyId=${user.companyId}`}
        // add optional filters and searches to body for sequelize formatting 
        const filters = this.state.filterObject
        const search = this.state.searchObject
        const tags = this.state.tagFilter
        let body = {offset, limit, sort, filters, search, tags}
        authAxios.post(`${url}`, body)
            .then(res => {
                this.setState({ users : res.data.rows, usersCount: res.data.count })
            })
            .catch(err => {
                throw err
            })
    }

    getAllFilteredPendingAccounts = (user) => {
        let url = `${baseURL2}/filtered-pending-users`
        //  Gets a paginated list of 10 users and appends dynamic search queries if they exist
        const offset = +this.state.currentPageQuery * +this.state.pageSize
        const limit = +this.state.pageSize
        const sort = this.state.userSort
        // append company search query if not super admin
        if(!user.isIodSuperAdmin){ url = `${url}?companyId=${user.companyId}`}
        // add optional filters and searches to body for sequelize formatting 
        const filters = this.state.filterObject
        const search = this.state.searchObject
        const tags = this.state.tagFilter
        let body = {offset, limit, sort, filters, search, tags}
        authAxios.post(`${url}`, body)
            .then(res => {
                this.setState({ users : res.data.rows, usersCount: res.data.count })
            })
            .catch(err => {
                throw err
            })
    }

    getClientAdmins = async (companyId) => {
        try {
            let res = await authAxios.get(`${baseURL2}/client-admins?companyId=${companyId}`)
            this.setState({clientAdmins: res.data})
        } catch (error) {
            console.log(error)
        }
    }

    changePageNumberV2 = (requestedPage) => {
        this.setState({ currentPageQuery: requestedPage })
    }

    applyUserSort = (sort) => {
        // sort will always be an array
        // the first item is what you're sorting, second it the direction
        this.setState({ userSort: sort })
    }

    // checking the select box on a participant card
    checkUser = (e, user) => {
        const { checked } = e.target
        let allSelectedUsers = this.state.selectedUsers
        let selectedUsersIds = this.state.selectedUsersIds

        // if the checkbox is checked
        if(checked) {
            // see if the user is already in the array
            if(selectedUsersIds.includes(user.id)) {
                // dont do anything if they are
                console.log('Already in array')
            } else {
                // add them if they are not
                allSelectedUsers.push(user)
                selectedUsersIds.push(user.id)
            }
        } else {
            // remove them if unchecked
            allSelectedUsers = allSelectedUsers.filter(x => x.id != user.id)
            selectedUsersIds = selectedUsersIds.filter(id => id != user.id)
        }

        this.setState({ selectedUsers: allSelectedUsers, selectedUsersIds: selectedUsersIds })
    }

    clearSelectedUser = (id) => {
        let filteredUsers = this.state.selectedUsers.filter(x => x.id != id)
        let filteredIds = this.state.selectedUsersIds.filter(x => x != id)

        this.setState({ selectedUsers: filteredUsers, selectedUsersIds: filteredIds })
    }

    clearAllSelectedUsers = () => {
        this.setState({ selectedUsers: [], selectedUsersIds: [] })
    }

    applyParticipantFilters = (adminfilters, statusfilters, productfilters) => {
        const filters = {
            admin: adminfilters,
            status: statusfilters,
            product: productfilters
        }

        this.setState({ currentPageQuery: 0, filterObject: filters })
    }

    searchParticipants = (user, searchValue) => {
        let searchArray = searchValue.split(',')
        let search = {
            firstName: searchArray,
            lastName: searchArray,
            email: searchArray,
        }
        // if(user.isIodSuperAdmin) search['company'] = searchValue

        // remove first and last name if searching pending accounts
        if(this.state.viewingPendingAccounts){
            delete search['firstName']
            delete search['lastName']
        }

        if(searchValue === '') search = {}
        this.setState({ currentPageQuery: 0, searchObject: search })
    }

    updateUserListsState = (id, updateBody) => {
        // update the users lists state with product changes
        let usersList = this.state.users
        let foundIndex = usersList.findIndex(user => user.id === id);
        let foundUser = usersList[foundIndex]
        let updatedUser = {...foundUser, ...updateBody}
        usersList[foundIndex] = updatedUser

        return usersList
    }

    // compareAccessRights = (participant, accessRights) => {
    //     // Create list off access rights being added
    //     let updatedProducts = []
    //     Object.keys(accessRights).forEach(key => {
    //         // if the product is being added and it wasnt currently active for the participant
    //         if(accessRights[key] == true && (participant[key] !== accessRights[key])){
    //             updatedProducts.push(accessRights[key])
    //         }
    //     });

    //     return updatedProducts
    // }

    modifyParticipantAccessRights = (participant, accessRights) => {
        // let updatedProducts = this.compareAccessRights(participant, accessRights)
        const usersList = this.updateUserListsState(participant.id, accessRights)

        let url = this.state.viewingPendingAccounts ? `${authorizatonBaseURL}` : `${baseURL2}/user-update`
        authAxios.put(`${url}/${participant.id}`, accessRights)
            .then(() => {
                this.setState({ accessRightsUpdated: true, users: usersList })
                // this.sendProductInvites(user, participant, updatedProducts)
            })
            .catch(() => this.setState({ accessRightsUpdated: false }))
    }
    
    setPendingViewFromUrl = (param) => {
        let showing = param === 'pending' ? true : false
        this.setState({ viewingPendingAccounts: showing })
    }

    clearParticipantsView = () => {
        this.setState({ tagFilter: '', users : [], usersCount: 0, userSort: ['email', 'ASC'], currentPageQuery: 0, selectedUsers: [], selectedUsersIds: [], filterQueryString: '', searchValue: '', searchObject: {}, selectedTag: '', filterObject: { admin: {}, status: {}, product: {} }})
    }

    setSelectedUserStatus = (e, userId, name, status) => {
        // alert confirmation before deactivate
        // status true = active, false = deactivate
        let body = {
            isActive: status, // account stats
            deactivatedByAdmin: !status // remove if activating, add if deactivating
        }

        // updating users after change
        const usersList = this.updateUserListsState(userId, body)

        if(!status){
            if(window.confirm(`Are you sure you want to deactivate ${name}s account?`)) {
                e.preventDefault();
                axios.put(`${baseURL}/user/${userId}`, body)
                    .then(() => {
                        this.setState({ statusUpdated: true, users: usersList })
                        window.alert(`${name}s account has been deactivated.`)
                    })
                    .catch(() => {
                        this.setState({ statusUpdated: false })
                        window.alert(`Unable to deavtivate ${name}s account. Please contact info@insideoutdev.com.`)
                    })
            }
        } else {
            axios.put(`${baseURL}/user/${userId}`, body)
                .then(() => this.setState({ statusUpdated: true, users: usersList }))
                .catch(() => this.setState({ statusUpdated: false }))
        }
    }

    // deleteSelectedUser = (e, userId, email) => {
    //     if(this.state.viewingPendingAccounts){
    //         // alert confirmation before deleting Authorization record
    //         if(window.confirm(`WARNING: There is no way to undo this action. Are you sure you want to delete the pending account for ${email}? `)) {
    //             e.preventDefault();
    //             axios.delete(`${authorizatonBaseURL}/${userId}`)
    //                 .then(() => {
    //                     this.setState({ participantDeleted: true })
    //                     window.alert(`Pending account for ${email} has been deleted.`)
    //                 })
    //                 .catch(() => {
    //                     this.setState({ participantDeleted: false })
    //                     window.alert(`Unable to delete account registered with ${email}. Please contact info@insideoutdev.com.`)
    //                 })
    //         }
    //     } else {
    //         // alert confirmation before deleting User record
    //         if(window.confirm(`WARNING: This will delete any data associated with ${email}, Are you sure you want to delete their account? `)) {
    //             e.preventDefault();
    //             axios.delete(`${baseURL}/user/${userId}`)
    //                 .then(() => {
    //                     this.setState({ participantDeleted: true })
    //                     window.alert(`${name}s account has been deleted.`)
    //                 })
    //                 .catch(() => {
    //                     this.setState({ participantDeleted: false })
    //                     window.alert(`Unable to delete account registered with ${email}. Please contact info@insideoutdev.com.`)
    //                 })
    //         }
    //     }
    // }

    updateSelectedUserDetails = (participant, selectedCompany) => {
        let userBody = {
            firstName: this.state.participantEditFirstName.length > 0 ? 
                this.state.participantEditFirstName : participant.firstName,
            lastName: this.state.participantEditLastName.length > 0 ? 
                this.state.participantEditLastName : participant.lastName,
            email: this.state.participantEditEmail.length > 0 ? 
                this.state.participantEditEmail : participant.email
        }

        // if a new company is added, set id for post body and Company to update existing user list
        if(selectedCompany.id) userBody['companyId'] = selectedCompany.id
        if(selectedCompany.id) userBody['Company'] = selectedCompany
        const usersList = this.updateUserListsState(participant.id, userBody)
        // remove Company from post body before submitting
        delete userBody.Company

        let url = this.state.viewingPendingAccounts ? `${authorizatonBaseURL}/${participant.id}` : `${baseURL}/user/${participant.id}`
        axios.put(url, userBody)
            .then(() => {
                this.setState({ users: usersList })
            })
            .catch(err => {
                throw err
            })
    }

    sendParticipantEmail = (e, user, participant, template) => {
        e.preventSefault()

        let body = {
            template: template, 
            emailAddress: participant.email, 
            firstName: participant.firstName, 
            cc: user.email, 
            serverEnvironment: nodeEnvironment,
        }

        // Missing:
            // workshopEventDate, 
            // eventDate, 
            // startTime, 
            // endTime, 
            // timeZone, 
            // certificationEventEndDate, 
            // certificationEventStartDate

        axios.post(emailURL, body)
            .then(res => console.log(res.data))
            .catch(err => console.log(err))
    }

    // Will only select the users showing on the page
    selectAllPartiticpants = () => {
        let allSelectedUsers = this.state.selectedUsers
        let selectedUsersIds = this.state.selectedUsersIds

        this.state.users.forEach(user => {
            // see if the user is already in the array
            if(selectedUsersIds.includes(user.id)) {
                // dont do anything if they are
                console.log('Already in array')
            } else {
                // add them if they are not
                allSelectedUsers.push(user)
                selectedUsersIds.push(user.id)
            }
        })

        this.setState({ selectedUsers: allSelectedUsers, selectedUsersIds: selectedUsersIds })
    }

    clearTagFilter = () => {
        this.setState({ tagFilter: '' })
    }

    // ----- Email Invites for product access ----- //
    // productEmailGeneration = (updatedProducts) => {
    //     let displayName = ''
    //     let emailSubject = ''
    //     let emailSubTemplate = ''

    //     switch(updatedProducts){
    //         case 'grantA2b' || 'hasA2b':
    //             displayName = 'A2B'
    //             break;
    //         case 'grantA2bAdmin' || 'isA2bAdmin':
    //             displayName = 'A2B Admin'
    //             break;
    //         case 'grantBt' || 'hasBreakthroughs':
    //             displayName = 'Breakthroughs'
    //             break;
    //         case 'grantBtAdmin' || 'isBreakthroughsClientAdmin':
    //             displayName = 'Breakthroughs Admin'
    //             break;
    //         case 'grantGrowCoaching' || 'hasGrowCoaching':
    //             displayName = 'Grow Coaching'
    //             break;
    //         case 'grantGrowCoachingAdmin' || 'isGrowClientAdmin':
    //             displayName = 'Grow Coaching Admin'
    //             break;
    //         case 'grantIoc' || 'hasCoaching':
    //             displayName = 'InsideOut Coaching'
    //             break;
    //         case 'grantIocAdmin' || 'isCoachingClientAdmin':
    //             displayName = 'InsideOut Coaching Admin'
    //             break;
    //         case 'grantIocdf' || 'hasIocDf':
    //             displayName = 'InsideOut Digital Foundations'
    //             break;
    //         case 'grantIocdfAdmin' || 'isIocDfAdmin':
    //             displayName = 'InsideOut Digital Foundations Admin'
    //             break;
    //         case 'grantIof' || 'hasFacilitator':
    //             displayName = 'InsideOut Facilitator'
    //             break;
    //         case 'grantIofAdmin' || 'isFacilitatorClientAdmin':
    //             displayName = 'InsideOut Facilitator Admin'
    //             break;
    //         default:
    //             break;
    //     }
    
    // }

    // IOC, GROW, BT, IOF, IOCDF, A2B
    // sendProductInvites = (user, participant, updatedProducts) => {
    //     let senderBody = { 
    //         recipient: participant.email, 
    //         subject: '', 
    //         emailTemplate: '', 
    //         emailLogId: '',
    //         carbonCopy: ''
    //     }
    //     let logBody = { 
    //         senderEmail: user.email, 
    //         receiverEmail: participant.email, 
    //         purpose: '', 
    //         userId: null 
    //     }

        // if only added to one, send specific email
        // if(updatedProducts.length > 1){
            // specify if they are an admin
        //     let product = updatedProducts[0]
        //     let isAdmin = product.includes('Admin')

        //     senderBody['subject'] = ''
        //     senderBody['subject'] = ''
        //     logBody['purpose'] = ''

        // } else {
            // if added to multiple, send email listing the products they have access to

        // }
        // sendNodeMailer(senderBody, logBody)
    // }


    render(){
        return (
            <UsersContext.Provider 
                value={{
                    ...this.state,
                    getOneUser: this.getOneUser,
                    changePageNumber: this.changePageNumber,
                    clearPageState: this.clearPageState,
                    getAllUsers: this.getAllUsers,
                    searchCoachingParticipants : this.searchCoachingParticipants,
                    searchDigitalFoundationsParticipants: this.searchDigitalFoundationsParticipants,
                    handleUserChange: this.handleUserChange,
                    searchCoachingClientAdmins: this.searchCoachingClientAdmins,
                    isNewParticipantRegisteredInPortal: this.isNewParticipantRegisteredInPortal,
                    isNewClientAdminRegisteredInPortal: this.isNewClientAdminRegisteredInPortal,
                    isNewDfParticipantRegisteredInPortal: this.isNewDfParticipantRegisteredInPortal,
                    clearUserProviderStateOnUnmount: this.clearUserProviderStateOnUnmount, 
                    setSelectedUser: this.setSelectedUser, 
                    toggleUserSearching: this.toggleUserSearching,
                    setNewParticipantEmail: this.setNewParticipantEmail,
                    setNewParticipantName: this.setNewParticipantName,
                    // ----- NEW PARTICIPANTS PAGES ----- //
                    getAllFilteredUsers: this.getAllFilteredUsers,
                    getAllFilteredPendingAccounts: this.getAllFilteredPendingAccounts,
                    getClientAdmins: this.getClientAdmins,
                    changePageNumberV2: this.changePageNumberV2,
                    applyUserSort: this.applyUserSort,
                    checkUser: this.checkUser,
                    clearSelectedUser: this.clearSelectedUser,
                    clearAllSelectedUsers: this.clearAllSelectedUsers,
                    applyParticipantFilters: this.applyParticipantFilters,
                    searchParticipants: this.searchParticipants,
                    modifyParticipantAccessRights: this.modifyParticipantAccessRights,
                    setSelectedUserStatus: this.setSelectedUserStatus,
                    updateSelectedUserDetails: this.updateSelectedUserDetails,
                    sendParticipantEmail: this.sendParticipantEmail,
                    selectAllPartiticpants: this.selectAllPartiticpants,
                    clearTagFilter: this.clearTagFilter,
                    toggleParticipantView: this.toggleParticipantView,
                    updateUser: this.updateUser,
                    getIodUsers: this.getIodUsers,
                    createAuthorizations: this.createAuthorizations,
                    setPendingViewFromUrl: this.setPendingViewFromUrl,
                    clearParticipantsView: this.clearParticipantsView,
                }}>
                { this.props.children }
            </UsersContext.Provider>
        )
    }
}

export default UsersProvider

export const withUsers = C => props => (
    <UsersContext.Consumer>
        {value => <C {...props} {...value}/>}
    </UsersContext.Consumer>
)

