import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faArrowCircleLeft, 
    faArrowCircleRight, 
    faArrowLeft, 
    faArrowRight,
    faBars, 
    faCalendarAlt,
    faCaretDown, 
    faCaretUp, 
    faCaretLeft, 
    faCaretRight, 
    faChartLine, 
    faCheck, 
    faCheckSquare, 
    faChevronDown, 
    faChevronLeft,
    faChevronRight,
    faChevronUp, 
    faEdit, 
    faEnvelope, 
    faEye,
    faFastBackward,
    faFastForward, 
    faFrown, 
    faHandPaper, 
    faHeartbeat,
    faHome, 
    faInfoCircle,
    faMeh, 
    faMinus, 
    faMouse, 
    faNewspaper, 
    faPlus,
    faQuestionCircle, 
    faReplyAll, 
    faSearch, 
    faSmile, 
    faStopwatch, 
    faTasks, 
    faTimesCircle, 
    faTimes, 
    faTrash, 
    faTrashAlt,
    faUserMinus, 
    faUserShield, 
    faUsers,
    faUser,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faPeopleGroup,
    faAddressCard,
    faUserCheck,
    faCamera,
    faGear,
    faTags,
    faComments,
    faCircleMinus,
    faCommentMedical,
    faQuoteLeft,
    faQuoteRight,
    faFileArrowDown
} from "@fortawesome/free-solid-svg-icons"

import {
    faFacebookF, 
    faTwitter, 
    faLinkedinIn,
    faInstagram,
    faYoutube
} from '@fortawesome/free-brands-svg-icons'

import {
    faPlayCircle,
    faPauseCircle,
    faThumbsUp,
} from "@fortawesome/free-regular-svg-icons"


library.add(faNewspaper, faEye, faStopwatch, faReplyAll, faMouse, faFrown, faMeh, faSmile, faTasks, faArrowCircleLeft, faArrowCircleRight, faBars, faQuestionCircle, faEnvelope, faUserMinus, faUserShield, faTrash, faUsers, faCheckSquare, faEdit, faTrashAlt, faPlus, faMinus, faCheck, faChevronRight, faChevronUp, faChevronDown, faArrowLeft, faArrowRight, faCaretDown, faCaretUp, faSearch, faHome, faTimes, faTimesCircle, faFacebookF, faTwitter, faLinkedinIn, faHandPaper, faChartLine, faHeartbeat, faCalendarAlt, faPlayCircle, faChevronLeft, faInfoCircle, faPauseCircle, faFastBackward, faFastForward, faCaretLeft, faCaretRight, faInstagram, faYoutube, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faPeopleGroup, faAddressCard, faUserCheck, faUser, faCamera, faThumbsUp, faGear, faComments, faCircleMinus, faCommentMedical, faTags, faQuoteLeft, faQuoteRight, faFileArrowDown )
