import React, { Component } from 'react'
import { authAxios } from '../shared/CustomAxios'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'

const baseURL = '/v1/api/facilitators'
const FacilitatorContext = React.createContext()

class FacilitatorProvider extends Component {
    constructor(){
        super()
        this.state = {
            facilitators : [],
            facilitatorSearchInput: '',
            facilitatorAddUserInput: '',
            selectedFacilitator : {},
            isSearchingFacilitator : false, 
            isAddingUserToFacilitator: false,
            facilitatorsModalIsOpen: false
        }
    }

    handleFacilitatorChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: sanitizeData(value) })
    }

    setModalIsOpen = () => {
        this.setState({
            modalIsOpen: !this.state.modalIsOpen
        })
    }

    getAllFacilitators = () => {
        authAxios.get(`${baseURL}`)
        .then( res => {
            this.setState({ facilitators : res.data })
        })
        .catch( err => {
            throw err
        })
    }

    searchFacilitators = (inputType) => {
        let facilitatorSearch;
        if(inputType.isSearching) {
            this.setState({isSearchingFacilitator : true, isAddingUserToFacilitator: false})
            facilitatorSearch = this.state.facilitatorSearchInput
        }
        if(inputType.isAddingUser){
            this.setState({isSearchingFacilitator : false, isAddingUserToFacilitator: true})
            facilitatorSearch = this.state.facilitatorAddUserInput
        }
        if(this.state.facilitatorSearchInput.length){

        
            authAxios.get(`${baseURL}?search=${facilitatorSearch}`)
            .then( res => {
                if(res.data.length > 1){
                    this.setState({ facilitators : res.data, selectedFacilitator: {} })
                } else {
                    this.setState({ facilitators : res.data, selectedFacilitator: res.data[0] })
                }
            })
            .catch( err => {
                this.setState({ facilitators : [], selectedFacilitators: {} })
                throw err
            })
        }
    }

    setSelectedFacilitator = (selectedFacilitator) => {
        if(this.state.facilitatorSearchInput.length > 0 && this.state.facilitatorAddUserInput.length === 0){
            this.setState({ selectedFacilitator: selectedFacilitator, 
                            facilitatorSearchInput: `${selectedFacilitator.FirstName} ${selectedFacilitator.LastName}`, 
                            facilitatorAddUserInput: '' })
        }
        if(this.state.facilitatorSearchInput.length === 0 && this.state.facilitatorAddUserInput.length > 0 ){
            this.setState({ selectedFacilitator: selectedFacilitator, 
                            facilitatorAddUserInput: `${selectedFacilitator.FirstName} ${selectedFacilitator.LastName}`, 
                            facilitatorSearchInput: '' })
        }
        if(this.state.facilitatorSearchInput.length > 0 && this.state.facilitatorAddUserInput.length > 0 ){
            this.setState({ selectedFacilitator: selectedFacilitator, 
                            facilitatorAddUserInput: `${selectedFacilitator.FirstName} ${selectedFacilitator.LastName}`, 
                            facilitatorSearchInput: `${selectedFacilitator.FirstName} ${selectedFacilitator.LastName}` })
        }
    }

    clearFacilitatorSearch = () => {
        this.setState({
            facilitatorSearchInput: '',
            facilitatorAddUserInput: '',
            selectedFacilitator : {}
        })
    }

    toggleSearchingFacilitators = () => {
        this.setState({
            isSearchingFacilitator: false,
            isAddingUserToFacilitator: false
        })
    }


    render(){
        return (
            <FacilitatorContext.Provider 
                value={{
                    ...this.state,
                    handleFacilitatorChange: this.handleFacilitatorChange,
                    getAllFacilitators: this.getAllFacilitators,
                    searchFacilitators: this.searchFacilitators,
                    setSelectedFacilitator: this.setSelectedFacilitator,
                    clearCompanySearch: this.clearCompanySearch,
                    toggleSearchingFacilitators: this.toggleSearchingFacilitators,
                    setModalIsOpen: this.setModalIsOpen
                }}>
                { this.props.children }
            </FacilitatorContext.Provider>
        )
    }
}

export default FacilitatorProvider

export const withFacilitators = C => props => (
    <FacilitatorContext.Consumer>
        {value => <C {...props} {...value}/>}
    </FacilitatorContext.Consumer>
)

