// Used to display the Header & Nav
// import axios from 'axios'
// import moment from 'moment'
import { Route } from 'react-router-dom'
import GlobalHeader from '../../GlobalComponents/MainHeader/GlobalHeader'
import Footer from '../../GlobalComponents/Footer/Footer'
// import { NotificationBanner } from '../../GlobalComponents/Banners/NotificationBanner'

export const PublicRoute = ({ component: Component , ...rest}) => {
    // const [notificationOpen, setNotificationOpen] = useState(false)
    // const [notification, setNotification] = useState('')

    // useEffect(() => {
    //     axios.get('/v1/notification-bar')
    //     .then(res => {
    //         let today = moment().format()
    //         let data = res.data
    //         setNotification(data)
    //         let startDateCheck = data.startDate <= today || data.startDate === null
    //         let endDateCheck = data.endDate >= today || data.endDate === null
    //         if( startDateCheck && endDateCheck){
    //             setNotificationOpen(data.isActive)
    //         }

    //     })
    //     .catch( err => {throw err} )
    // }, [])

    return (
        <Route {...rest}  render={(props) => (
            <>
                {/* {notificationOpen &&
                    <NotificationBanner setNotificationOpen={setNotificationOpen} notification={notification} />
                } */}
                {/* <GlobalHeader notificationOpen={notificationOpen}  /> */}
                <GlobalHeader community='insideout' />
                {/* <div className={notificationOpen ? 'component-container-banner-open' : 'component-container-banner-closed'}> */}
                <Component {...props} />
                <Footer />
            </>
        )}
        />
    )
}
