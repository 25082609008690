import moment from 'moment'

const pulseReminderEmail = ( pulseReminderData ) => {
    const reminderDate = moment(pulseReminderData.emailRecipient.weekOf).format("MMMM Do, YYYY")
    return (
        `<body style="margin: 16px; font-family: Arial, Helvetica, sans-serif;">
            <article>
                <div style="width: 90%; max-width: 800px;">
                    <section style="max-width: 800px; padding: 20px;">
                        <h2 style="color: #005295; font-size: 22px; font-weight: bold; margin: 0px;"> InsideOut Development </h2>
                        <h3 style="color: #4D4D4D; font-size: 18px; font-weight: bold; margin: 0px 0px 14px 0px;"> Pulse Reminder </h3>
                        <p style="color: rgb(79, 79, 79); font-size: 17px; font-weight: 300;">Hey ${pulseReminderData.emailRecipient.user.firstName}, </p>
                        <p style="color: rgb(79, 79, 79); font-size: 17px; font-weight: 300;">It looks like you havent completed your Pulse for ${reminderDate}.</p>
                        <p style="color: rgb(79, 79, 79); font-size: 17px; font-weight: 300;">Pulses are due every Friday.</p>
                        <p style="color: rgb(79, 79, 79); font-size: 17px; font-weight: 300;">Click this link to be directed to your <a style="color: #005295; font-size: 17px; font-weight: bold;" href="${pulseReminderData.iodFrontEndUrl}/a2b/pulse" target="_blank">Pulse</a></p>
                        <p style="color: rgb(79, 79, 79); font-size: 17px; font-weight: 300;">Thank you.</p>
                    </section>
                </div>
            </article>
        </body>`
    )
}

export default pulseReminderEmail
