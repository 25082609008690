import React, { Component } from 'react'
import axios from 'axios'
import { authAxios } from '../shared/CustomAxios'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'
import moment from 'moment'
import { googleEventTracker } from '../shared/GoogleAnalyticsTracking'
import snapshotReportInfo from '../GlobalComponents/Snapshots/CoachingSnapshots/snapshotReportInfo.json'

const snapshotBaseURL = '/v1/ioc/snapshots'
const snapshotBaseURLv2 = '/v2/ioc/snapshots'
const authBaseURL = '/v1/auth'
// const campaignMemberBaseURL = '/v1/ioc/campaign-members'
const SnapshotContext = React.createContext()

const defaultState = {
    snapshotUser: {},
    snapshotRecipient: {},
    snapshotUrlUserId: '',
    isSelfAssessment: false,
    snapshotMessage: null,
    groupedUserSnapshotReports: [],
    mostRecentSnapshotReport: [],
    completedSnapshots: [],
    // salesforceCampaignMemberId: '',
    // sqlCampaignMemberId: '',
    // salesforceCampaignId: '', 
    snapshotSurvey:  {
        question1: '',
        question2: '',
        question3: '',
        question4: '',
        question5: '',
        question6: '',
        question7: '',
        question8: '',
        question9: '',
        question10: '',
        question11: '',
        question12: '',
        question13: '',
        question14: '',
        question15: '',
        question16: '',
        question17: '',
        question18: '',
        question19: '',
        question20: '',
        question21: '',
        question22: '',
        question23: '',
        question24: '',
        question25: '',
        question26: '',
        question27: '',
        question28: '',
        question29: '',
        question30: '',
        question31: null,
        question32: '',
        question33: '',
    },
    // for quiz
    isQuestionRequired: true,
    hasValue: 0,
    isSurvey: true,
    //
    nextSelfAssessmentDate: '',
    canTakeSelfAssessment: true,
    guestSnapshotIntro : true,
    snapshotCompleted : false,
    emailBody: '',
    emailSubject: '',
    emailRecipient: [],
    emailStatus: '',
    isShowingSnapshotEmailModal: false,
    isReminderEmail: false,
    isFeedbackEmail: false,
    emailSent: false,
    containerId: '',
    snapshotUrlContainerId: '',
    allUsersSnapshots: [],
    groupedContainers: [],
    mostRecentContainer: [],
    availableReports: [],
    lastSelfAssessmentDate: '',
    snapshotContainer: [],
    currentSelfAssessment: {},
    currentGuestAssessments: [],
    previousSnapshot: [],
    selfAssessmentScores: [],
    previousSelfAssessmentScores: [],
    guestAssessmentScores: [],
    guestAssessmentsAverages: [],
    previousGuestAverages: [],
    goalAverageArray: [],
    goalPreviousAverageArray: [],
    recentSelfAssessments: [],
    loadingReport: true,
    canViewSnapshot: false,
    selfAssessmentMap: [],

    // LMS Snapshot Report
    lmsSnapshotsList: [],
    lmsPdfData: [], 
    lmsPdfTextResponses: [],
    lmsReportLoading: true,
    lmsReportError: false,
    lmsReportErrorMessage: '',
    lmsReportDate: '',
    lmsFooterInfo: '',
    snapshotsRespondents: 0,

    // Module Rework
    groupedSnapshots: [],
    recentSnapshots: [],
    snapshotMapData: [],
    dateOfLastSnapshot: '',
    canTakeSnapshot: false,

    // Cohort/Group Snapshots
    availableSnapshotCohortList: [],
    isGroupSnapshot: false,

    // IOC Snapshot Report
    // snapshotList: [],
    snapshotReportData: [],
    overallReportScores: [],
    snapshotReportCritiqueComments: [],
    snapshotReportComplimentComments: [],
    snapshotReportLoading: true,
    snapshotReportError: false,
    snapshotReportErrorMessage: '',
    snapshotReportInfo: {} // name, date, responses
}

class SnapshotProvider extends Component {
    constructor(){
        super()
        this.state = defaultState
    }

    handleToggleSurveyStyle = () => {
        this.setState({ isSurvey: !this.state.isSurvey })
    }

    handleSnapshotChange = (e) => {
        const { name, value} = e.target
        this.setState({ [name]: sanitizeData(value) })
    }

    checkIfRequired = (questionName) => {
        let questionValue = this.state.snapshotSurvey[`question${questionName}`]
        if (questionValue !== '') {
            this.setState({
                isQuestionRequired: false,
                hasValue: questionValue
            
            })
        } else {
            this.setState({isQuestionRequired: true})
        }
    }

    handleQuestionClick = (number, value) => {
        let score;
        value === 0 ? score = 0 : score = sanitizeData(value)
        this.setState({
            snapshotSurvey: {
                ...this.state.snapshotSurvey,
                [number] : score
            },
            snapshotMessage : null,
        })
    }

    handleSnapshotQuestionsChange = (e) => {
        const { name, value} = e.target
        this.setState({
            snapshotSurvey: {
                ...this.state.snapshotSurvey,
                [name] : sanitizeData(value)
            },
            snapshotMessage : null,
        })
    }
    
    setSnapshotUser = (user) => {
        this.setState({ snapshotUser : user })
    }

    getSnapshotLocationUrl = (usersId, containerId) => {
        this.setState({ 
            snapshotUrlUserId: usersId, 
            snapshotUrlContainerId: containerId
        }, () => this.getSnapshotRecipient() )
    }

    getSnapshotRecipient = () => {
        axios.get(`${authBaseURL}/user/${this.state.snapshotUrlUserId}`)
        .then( res => {
            this.setState({snapshotRecipient: res.data})
        })
        .then(() => {
            this.isSnapshotSelfAssessmentOrGuestAssessment()
        })
        .catch( err => {
            throw err
        })
    }

    isSnapshotSelfAssessmentOrGuestAssessment = () => {
        if(this.state.snapshotUser.id){
            if(this.state.snapshotUser.id === this.state.snapshotUrlUserId){
                // User is logged in and is taking self assessment Snapshot 
                this.setState({ isSelfAssessment: true, guestSnapshotIntro: false })
                
            } else {
                // User is logged in and is taking guest assessment Snapshot 
                this.setState({ isSelfAssessment: false, guestSnapshotIntro: true })
            }
        } 
        // else {
        //     //  User is not logged in and is taking guest assessment Snapshot 
        //     this.setState({ isSelfAssessment: false }, () => this.getSnapshotRecipient ())
        // }
    }


    submitSnapshot = (e) => {
        //  Creates a new coaching Snapshot version 1.0 object and posts results to the DB
        // Quiz needs prevent default to function properly, survey does not.
        if(e !== undefined)e.preventDefault()

        let newSnapshot = this.state.snapshotSurvey
        newSnapshot['userId'] = this.state.snapshotUrlUserId
        newSnapshot['isSelfEvaluation'] = this.state.isSelfAssessment
        newSnapshot['surveyVersion'] = 1
        newSnapshot['course'] = 'coaching'
        newSnapshot['submitterEmail'] = this.state.snapshotUser.email ? this.state.snapshotUser.email : 'none'
        newSnapshot['containerId'] = this.state.snapshotUrlContainerId
        // newSnapshot['eventId'] = this.state.salesforceCampaignId
        console.log(this.state.snapshotSurvey)

        axios.post(`${snapshotBaseURL}`, newSnapshot)
        .then(() => {
            this.setState({snapshotSurvey: {}, snapshotMessage : 'Success', snapshotCompleted: true})
        })
        // .then( this.getSubmittersCampaignMemberId() )
        .catch(err => {
            this.setState({snapshotMessage : 'There was an error submitting the snapshot. Please check your responses and try again. If the problem persists contact InsideOut Development. ', snapshotCompleted: false})
            throw err
        })
    }

    // getEventId = (email) => {
    //     const userIdQuery = `?campaignMemberEmail=${email}`
    //     authAxios.get(`${campaignMemberBaseURL}${userIdQuery}`)
    //     .then(res => {
    //         let mostRecentDate = new Date(Math.max.apply(null, res.data.map( campaign => {
    //             return new Date(campaign.createdAt);
    //         })));
    //         let mostRecentObject = res.data.filter( campaign => { 
    //             let date = new Date( campaign.createdAt); 
    //             return date.getTime() === mostRecentDate.getTime();
    //         })[0];

    //         this.setState({salesforceCampaignId: mostRecentObject.campaignId })
    //     })
    // }

    //  Obtains the Users salesforce campaign member id so updates can be sent to salesforce record
    // getSubmittersCampaignMemberId = () => {
    //     const userIdQuery = `?userId=${this.state.snapshotUrlUserId}`
    //     authAxios.get(`${campaignMemberBaseURL}${userIdQuery}`)
    //     .then(res => {
    //         this.setState({ salesforceCampaignMemberId: res.data[0].campaignMemberId, sqlCampaignMemberId: res.data[0].id })
    //         this.markCampaignMemberPreworkComplete()
    //     })
    //     .catch( err => err )
    // }

    // Saves prework is complete to campaign member record in SQL
    // markCampaignMemberPreworkComplete = () => {
    //     const sqlCampaignMemberId = this.state.sqlCampaignMemberId
    //     const updatedMember = { IocPreworkComplete: true }
    //     authAxios.put(`${campaignMemberBaseURL}/${sqlCampaignMemberId}`, updatedMember )
    //         .then(res => {
    //             this.generateSalesforceToken()
    //         })
    //         .catch( err => err )
    // }

    //  Obtains salesforce auth token from the back end for posting data in next step
    // generateSalesforceToken = () => {
    //     authAxios.get(`/v1/api/global/salesforce/get-token` )
    //         .then(res => {
    //             this.updateCampaignMemberPreworkInSalesforce(res.data)
    //         })
    //         .catch( err => err )
    // }

    //  Send prework is complete to Salesforce campaign member
    // updateCampaignMemberPreworkInSalesforce = (salesforceToken) => {
    //     let updatedSalesforceMember = {
    //         salesforceToken: salesforceToken,
    //         salesforceCampaignMemberId: this.state.salesforceCampaignMemberId ,
    //         updates: {"IOC_Prework_Complete__c": true }
    //     }

    //     authAxios.post(`/v1/api/global/salesforce/update-campaign-member`, updatedSalesforceMember )
    //         .then( res => res )
    //         .catch( err => err )
    // }

    clearSnapshotReportArrays = () => {
        this.setState({ groupedUserSnapshotReports: [], mostRecentSnapshotReport: [], previousSnapshotReport: [] })
    }

    // disable intro snapshot survey page if they click begin or are taking a self assessment
    guestIntroFalse = () => {
        googleEventTracker('Snapshot', 'User clicked begin guest snapshot assessment', 'Guest Snapshot Page')
        this.setState({ guestSnapshotIntro: false })
    }

    toggleSnapshotEmailTemplate = () => {
        googleEventTracker('Snapshot', 'User opened ask for feedback email modal', 'Ask for Feedback')
        this.setState({ isFeedbackEmail: true, isShowingSnapshotEmailModal: true })
    }

    toggleSnapshotEmailReminderTemplate = (e) => {
        e.preventDefault()
        googleEventTracker('Snapshot', 'User opened send feedback reminder email modal', 'Feedback Reminder')
        this.setState({ isReminderEmail: true, isShowingSnapshotEmailModal: true })
    }

    closeSnapshotEmailModal = () => {
        this.setState({ isFeedbackEmail: false, isReminderEmail: false, isShowingSnapshotEmailModal: false, emailStatus: '', emailSent: false })
    }

    sendSnapshotEmail = (snapshotVersion) => {
        let emailPurpose = ''
        if(this.state.isReminderEmail) emailPurpose = 'snapshot reminder email'
        if(this.state.isFeedbackEmail) emailPurpose = 'snapshot feedback email'

        const body = { 
            snapshotVersion: snapshotVersion,
            containerId: this.state.containerId,
            // eventId: this.state.salesforceCampaignId, 
            emailRecipient: this.state.emailRecipient, 
            emailSender: this.state.snapshotUser.email, 
            emailBody: this.state.emailBody, 
            emailSubject: this.state.emailSubject, 
            emailPurpose 
        }

        if(this.state.snapshotUser.id && this.state.emailRecipient){
            authAxios.post(`${snapshotBaseURL}/request-feedback/`, body)
            .then( res => {
                this.setState({emailStatus : res.data.msg, emailSent : true })
            })
            .catch( err => {
                this.setState({emailStatus : err.response.data.msg})
                throw err
            })
        } else{
            this.setState({emailStatus : 'Unable to send email.  Please try again and contact InsideOut Technical support if this problem persists.'})
        }
    }

    setDefaultEmailMessages = (subject, body) => {
        this.setState({
            emailSubject: subject,
            emailBody : body,
        })
    }

    handleMultipleEmailChange = (items) => {
        this.setState({ emailRecipient: items })
    }

    // Snapshot Report
    getUsersSnapshots = async (userId) => {
        await authAxios.get(`${snapshotBaseURL}/survey-questions?userId=${userId}&surveyVersion=1`)
        .then(res => {
            this.setState({ allUsersSnapshots: res.data },
                () => this.groupContainers(res.data)
            )
        })
    }

    getSnapshotContainer = async (containerId) => {
        await authAxios.get(`${snapshotBaseURL}/survey-questions?containerId=${containerId}`)
        .then(res => {
            this.setState({ snapshotContainer: res.data })
        })
    }

    getUserFromSnapshotContainer = async (containerId) => {
        let userId = await authAxios.get(`${snapshotBaseURL}/survey-questions?containerId=${containerId}`)
        .then(res => res.data[0].userId)
        .catch(err => err)
        return userId
    }

    currentReportData = () => {
        const container = this.state.snapshotContainer
        let guests = container.filter(snapshot => snapshot.isSelfEvaluation === false)
        let selfEval = container.filter(snapshot => snapshot.isSelfEvaluation)[0]

        this.setState({ currentGuestAssessments: guests, currentSelfAssessment: selfEval })
        this.setCurrentReport(selfEval, guests)
    }

    findPreviousSnapshot = () => {
        const container = this.state.snapshotContainer
        const currentDate = container.filter(snapshot => snapshot.isSelfEvaluation).map(selfAssessment => selfAssessment.createdAt)

        let lastSnapshot = []
        this.state.availableReports.forEach((snapshot, i) => {
            if (snapshot[i].createdAt < currentDate){
                lastSnapshot.push(snapshot)
                return
            }
        })

        if(lastSnapshot.length === 0){
            this.setState({ loadingReport: false })
        } else {
            this.setState({ previousSnapshot: lastSnapshot }, () => this.previousReportsData())
        }
    }

    previousReportsData = async () => {
        const lastSnapshot = this.state.previousSnapshot[0]
        const selfAssessment = lastSnapshot.filter(snapshot => snapshot.isSelfEvaluation)[0]
        const guestAssessments = lastSnapshot.filter(snapshot => snapshot.isSelfEvaluation === false)

        this.setPreviousReport(selfAssessment, guestAssessments)
    }

    reportScoreCalculations = (self, guests) => {
        const selfAssessmentQuestions = Object.values(self.questions)

        let guestAssessmentsCombined = []
        guests.forEach(respondent => {
            let singleQuestion = Object.values(respondent.questions)
            guestAssessmentsCombined.push(singleQuestion)
        })

        let guestQuestion = []
        const getGuestQuestion = (index) => {
            guestAssessmentsCombined.forEach(array => {
                guestQuestion.push(array[index])
            })
        } 
    
        let guestQuestionsArray = []
        for (let i = 0; i <= 30; i++){
            getGuestQuestion(i)
            guestQuestionsArray.push(guestQuestion)
            guestQuestion = []
        }

        let guestQuestionAverages = []
        guestQuestionsArray.forEach(question => {
            let average = question.reduce((a, b) => a + b, 0)/question.length
            let roundedAverage = Math.round(average * 10) / 10
            guestQuestionAverages.push(roundedAverage) 
        })

        return [selfAssessmentQuestions, guestQuestionAverages, guestQuestionsArray]
    }

    setCurrentReport = (self, guests) => {
        const scores = this.reportScoreCalculations(self, guests)
        this.setState({
            selfAssessmentScores: scores[0],
            guestAssessmentsAverages: scores[1],
            guestAssessmentScores: scores[2]
        })
    }

    setPreviousReport = (self , guests) => {
        const scores = this.reportScoreCalculations(self, guests)
        this.setState({
            previousSelfAssessmentScores: scores[0],
            previousGuestAverages: scores[1],
            loadingReport: false
        })
    }

    comparisonAverages = () => {
        const goalArray = snapshotReportInfo.coachingVersion1Report
        const newArray = [...this.state.guestAssessmentsAverages]

        // finds the average of averages per goal for both current and past snapshots
        let goalAverageArray = []
        let goalPreviousAverageArray = []

        goalArray.forEach(goal => {
            goal.goals.forEach(question => {
                let size = question.goalQuestions.length
                let average = Math.round((newArray.splice(0, size).reduce((a, b) => a + b, 0)/size) * 10) / 10
                goalAverageArray.push(average)

                if(this.state.previousGuestAverages.length > 0){
                    let previousAverage = Math.round((this.state.previousGuestAverages.splice(0, size).reduce((a, b) => a + b, 0)/size) * 10) / 10
                    goalPreviousAverageArray.push(previousAverage)
                }
            })
        })

        this.setState({ goalAverageArray: goalAverageArray, goalPreviousAverageArray: goalPreviousAverageArray})
    }

    // runs when users snapshots are fetched
    groupContainers = (snapshots) => {
        let snapshotsGrouped = []
        for(let i=0;i<snapshots.length;++i) {
            let singleSnapshot = [];
            singleSnapshot.push(snapshots[i]);

            while(i < snapshots.length-1 && snapshots[i].containerId === snapshots[i+1].containerId) {
                ++i
                singleSnapshot.push(snapshots[i]);
            }

            snapshotsGrouped.push(singleSnapshot);
        }

        this.setState({ groupedContainers: snapshotsGrouped, mostRecentContainer: snapshotsGrouped.length ? snapshotsGrouped[0] : []})
    }

    setSelfAssessmentsMap = async () => {
        let filtered = []
        this.state.groupedContainers.map(allSnapshots => {
            let responses = allSnapshots.length - 1
            allSnapshots.forEach(function (snapshot){ 
                snapshot.respondents = responses;
            })
            let filter = allSnapshots.filter(snapshot => snapshot.isSelfEvaluation)[0]
            return filtered.push(filter)
        })

        
        this.setState({selfAssessmentMap: filtered})
    }

    snapshotsWithReports = () => {
        let completedSnapshots = []
        this.state.groupedContainers.map(container => (
            container.length > 3 && completedSnapshots.push(container)
        ))
        
        this.setState({ availableReports: completedSnapshots })
    }

    getContainerId = async (userId) => {
        await authAxios.get(`${snapshotBaseURL}?userId=${userId}&isSelfEvaluation=1`)
        .then(res => 
            this.setState({containerId: res.data[0].containerId})
        )
    }

    // Get date of last snapshot taken then automatically runs getNextSelfAssessmentDate
    lastSelfAssessmentTaken = () => {
        const selfAssessment = this.state.allUsersSnapshots.filter(snapshot => snapshot.isSelfEvaluation)[0]
        const mostRecentDate = selfAssessment.createdAt
        const lastContainer = selfAssessment.containerId
        this.setState({ lastSelfAssessmentDate: mostRecentDate, containerId: lastContainer },
            this.getNextSelfAssessmentDate(mostRecentDate)
        )
    }

    // Reliant on lastSelfAssessmentTaken
    getNextSelfAssessmentDate = (lastSelfEvaluationDate) => {
        if(lastSelfEvaluationDate.length === 0){
            this.setState({ canTakeSelfAssessment: true })
        } else {
            let nextAvailable = moment(lastSelfEvaluationDate).add(6, 'months')
            let difference = moment().diff(nextAvailable)
            let canTakeSelfAssessment;
                difference >= 0 ? canTakeSelfAssessment = true : canTakeSelfAssessment = false
                this.setState({ nextSelfAssessmentDate: nextAvailable, canTakeSelfAssessment })
        }
    } 

    getSnapshotReportRecipient = (id) => {
        axios.get(`${authBaseURL}/user/${id}`)
        .then( res => {
            this.setState({snapshotRecipient: res.data})
        })
        .catch( err => {
            throw err
        })
    }

    renderSnapshotReport = async (containerId) => {
        await this.getSnapshotContainer(containerId)
        await this.currentReportData()
        await this.getUsersSnapshots(this.state.currentSelfAssessment.userId)
        await this.getSnapshotReportRecipient(this.state.currentSelfAssessment.userId)
        await this.snapshotsWithReports()
        await this.findPreviousSnapshot()
        await this.comparisonAverages()
    }

    // ALL LMS SNAPSHOT FUNCTIONS BELOW
    submitSnapshotV2 = (e, type) => {
        //  Creates a new coaching Snapshot version 2.0 object and posts results to the DB
        //  Quiz needs prevent default to function properly, survey does not.
        if(e !== undefined) e.preventDefault()
        let newSnapshot = this.state.snapshotSurvey
        newSnapshot['surveyVersion'] = 2
        newSnapshot['course'] = `${type}-coaching`
        newSnapshot['eventId'] = type
        newSnapshot['userId'] = this.state.snapshotUrlUserId
        newSnapshot['isSelfEvaluation'] = this.state.isSelfAssessment
        newSnapshot['containerId'] = this.state.snapshotUrlContainerId
        newSnapshot['submitterEmail'] = this.state.snapshotUser.email ? this.state.snapshotUser.email : 'none'
        
        console.log(newSnapshot)

        axios.post(`${snapshotBaseURLv2}`, newSnapshot)
        .then(() => {
            this.setState({
                snapshotSurvey: {}, 
                snapshotMessage : 'Thank you for submitting the snapshot.', 
                snapshotCompleted: true
            })
        })
        .catch(err => {
            this.setState({
                snapshotMessage : 'There was an error submitting the snapshot. Please check your responses and try again. If the problem persists contact InsideOut Development.', 
                snapshotCompleted: false
            })
            throw err
        })
    }

    getLmsSnapshotReports = async (userId) => {
        try {
            let res = await authAxios.get(`${snapshotBaseURLv2}/report-list?userId=${userId}&surveyVersion=2`)
            this.setState({ lmsSnapshotsList: res.data })
        } catch(err){
            console.log(err)
        }
    }

    generateLmsSnapshotReport = async (userId, containerId) => {
        try {
            let res = await authAxios.get(`${snapshotBaseURLv2}/report?userId=${userId}&containerId=${containerId}`)
            this.setState({ lmsPdfData: res.data[0], lmsPdfTextResponses: res.data[1], lmsReportDate: res.data[2], lmsReportLoading: false, lmsReportError: false })
        } catch(err) {
            this.setState({ lmsReportLoading: true, lmsReportError: true, lmsReportErrorMessage: 'Error' })
        }
    }

    generateGrowSnapshotReport = async (userId, containerId) => {
        let isGroupSnapshot = userId === null
        let url;
        if(isGroupSnapshot){
            url = `${snapshotBaseURLv2}/grow-group-report/${containerId}` // containerId is tagId
        } else {
            url = `${snapshotBaseURLv2}/grow-report?userId=${userId}&containerId=${containerId}`
        }

        try {
            let res = await authAxios.get(url)
            this.setState({ lmsPdfData: res.data.reportData, lmsPdfTextResponses: res.data.latestComments, lmsFooterInfo: res.data.metaInfo, snapshotsRespondents: res.data.respones, lmsReportLoading: false, lmsReportError: false, isGroupSnapshot: isGroupSnapshot })
        } catch(err) {
            this.setState({ lmsReportLoading: true, lmsReportError: true, lmsReportErrorMessage: 'Error' })
        }
    }
    
    // Remove and use clearSnapshotState
    clearState = () => {
        this.setState(defaultState)
    }
    
    clearSnapshotState = () => {
        this.setState(defaultState)
    }
    
    // MODULAR REWORK BELOW
    getUsersSnapshotsV2 = async (userId, snapshotVersion) => {
        try {
            let res = await authAxios.get(`${snapshotBaseURL}/survey-questions?userId=${userId}&surveyVersion=${snapshotVersion}`)
            this.setState({ allUsersSnapshots: res.data })
            if(res.data.length > 0){
                this.groupUsersSnapshotsV2(res.data)
            } else {
                // if there are no snapshots they can take it.
                this.setState({ canTakeSnapshot: true })
            }
        } catch (err) {
            console.log(err)
        }
    }

    groupUsersSnapshotsV2 = (snapshots) => {
        let groupedSnapshots = Object.values(
            snapshots.reduce((r, o) => {
                (r[o.containerId] = r[o.containerId] || []).push(o);
                return r;
            }, {})
        );
        let recentSnapshots = groupedSnapshots.slice(0, 3)
        this.setState({ groupedSnapshots: groupedSnapshots, recentSnapshots: recentSnapshots })
    }

    setSnapshotMapData = (snapshotGroup) => {
        // Used on recent map or full history map
        const mapData = snapshotGroup.map(group => {
            // find the amount of responses before filtering
            let responses = group.length - 1
            // filter out to only have the self assessments - there will always be 1
            let filteredSnapshots = group.filter(snapshot => snapshot.isSelfEvaluation)[0]
            // add responses key back in
            filteredSnapshots.responses = responses
            return filteredSnapshots
        })
        let dateOfLastSnapshot = mapData[0].createdAt
        this.setState({ snapshotMapData: mapData, dateOfLastSnapshot: dateOfLastSnapshot})
        // use the last date taken to find out when the next snapshot will be available
        this.setNextSnapshotAvailable(dateOfLastSnapshot)
    }

    setNextSnapshotAvailable = (dateOfLastSnapshot) => {
        let today = new Date()
        let dateToCheckAgainst = new Date(dateOfLastSnapshot)
        let nextAvailable = new Date(dateToCheckAgainst.setMonth(dateToCheckAgainst.getMonth()+6))
        let canTakeSelfAssessment = +nextAvailable <= +today

        this.setState({ nextSelfAssessmentDate: nextAvailable, canTakeSnapshot: canTakeSelfAssessment })
    }

    getCohortsSnapshots = async (user, snapshotVersion) => {
        let product = snapshotVersion === 1 ? 'hasCoaching' : 'hasGrowCoaching'
        let url = `${snapshotBaseURLv2}/group-report-list`
        if(!user.isIodSuperAdmin){
            url = `${url}?adminId=${user.id}&product=${product}`
        } else {
            url = `${url}?product=${product}`
        } 
        // use the userIds from tagged participants to find snapshots taken since the tag was created and sort by date. Grabbing only since tag was created will filter out older snapshots the user may have taken.
        try {
            let res = await authAxios.get(url)
            let tagsWithParticipants = res.data.filter(tag => tag.TaggedParticipants.length > 0)
            this.setState({ availableSnapshotCohortList: tagsWithParticipants })
        } catch (err) {
            console.log(err)
        }
    }

    generateIocSnapshotReport = async (userId, containerId) => {
        let isGroupSnapshot = userId === null
        let url;
        if(isGroupSnapshot){
            url = `${snapshotBaseURLv2}/ioc-group-report/${containerId}` // containerId is tagId
        } else {
            url = `${snapshotBaseURLv2}/ioc-report?userId=${userId}&containerId=${containerId}`
        }

        try {
            let res = await authAxios.get(url)
            this.setState({ snapshotReportData: res.data.reportData, snapshotReportCritiqueComments: res.data.critiqueComments, snapshotReportComplimentComments: res.data.complimentComments,  snapshotReportInfo: res.data.metaInfo, snapshotReportLoading: false, snapshotReportError: false, snapshotReportErrorMessage: '', isGroupSnapshot: isGroupSnapshot, overallReportScores: res.data.overallScore })
        } catch(err) {
            console.log(err)
            this.setState({ snapshotReportLoading: true, snapshotReportError: true, snapshotReportErrorMessage: 'Error' })
        }
    }

    render(){ 
        return (
            <SnapshotContext.Provider 
                value={{
                    ...this.state,
                    handleSnapshotChange : this.handleSnapshotChange,
                    handleSnapshotQuestionsChange : this.handleSnapshotQuestionsChange,
                    setSnapshotUser: this.setSnapshotUser,
                    getSnapshotLocationUrl: this.getSnapshotLocationUrl,
                    submitSnapshot: this.submitSnapshot,
                    submitSnapshotV2: this.submitSnapshotV2,
                    clearSnapshotReportArrays: this.clearSnapshotReportArrays,
                    checkIfRequired: this.checkIfRequired,
                    handleQuestionClick: this.handleQuestionClick, 
                    toggleSnapshotEmailTemplate: this.toggleSnapshotEmailTemplate,
                    toggleSnapshotEmailReminderTemplate: this.toggleSnapshotEmailReminderTemplate,
                    handleToggleSurveyStyle: this.handleToggleSurveyStyle,
                    getNextSelfAssessmentDate: this.getNextSelfAssessmentDate,
                    guestIntroFalse: this.guestIntroFalse,
                    sendSnapshotEmail: this.sendSnapshotEmail,
                    setDefaultEmailMessages: this.setDefaultEmailMessages,
                    closeSnapshotEmailModal: this.closeSnapshotEmailModal,
                    handleMultipleEmailChange: this.handleMultipleEmailChange,
                    getUsersSnapshots: this.getUsersSnapshots,
                    getSnapshotContainer: this.getSnapshotContainer,
                    getUserFromSnapshotContainer: this.getUserFromSnapshotContainer,
                    snapshotsWithReports: this.snapshotsWithReports,
                    lastSelfAssessmentTaken: this.lastSelfAssessmentTaken,
                    renderSnapshotReport: this.renderSnapshotReport,
                    getContainerId: this.getContainerId,
                    // getEventId: this.getEventId,
                    getLmsSnapshotReports: this.getLmsSnapshotReports,
                    generateLmsSnapshotReport: this.generateLmsSnapshotReport,
                    generateGrowSnapshotReport: this.generateGrowSnapshotReport,
                    clearState: this.clearState,
                    setSelfAssessmentsMap: this.setSelfAssessmentsMap,
                    loadingTimeout: this.loadingTimeout,
                    getUsersSnapshotsV2: this.getUsersSnapshotsV2,
                    setSnapshotMapData: this.setSnapshotMapData,
                    clearSnapshotState: this.clearSnapshotState,
                    getCohortsSnapshots: this.getCohortsSnapshots,

                    // remove after ioc report refactor
                    generateIocSnapshotReport: this.generateIocSnapshotReport
                }}>
                { this.props.children }
            </SnapshotContext.Provider>
        )
    }
}

export default SnapshotProvider

export const withSnapshots = C => props => (
    <SnapshotContext.Consumer>
        {value => <C {...props} {...value}/>}
    </SnapshotContext.Consumer>
)
