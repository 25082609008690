import React, { Component } from 'react'
import { authAxios } from '../../shared/CustomAxios'

const DfSessionsBaseUrl = '/v1/api/digital-foundations/sessions'
const IocSessionsBaseUrl = '/v1/api/ioc/sessions'

const SessionsContext = React.createContext()

const defaultSessionState = {
    sessionCommunity: '',
    companiesSessions: [],
    selectedSession: {},
    sessionName: '',
    sessionUser: {},
    sessionError: false,
    sessionDeleted: false,
    sessionsRedirectPath: ''
}

class SessionsProvider extends Component {
    constructor(){
        super()
        this.state = defaultSessionState
    }

    setSessionCredentials = (user, community) => {
        this.setState({sessionUser: user, sessionCommunity: community})
    }

    setSessionUser = (user) => {
        this.setState({sessionUser: user})
    }

    setSessionCommunity = (community) => {
        this.setState({sessionCommunity: community})
    }

    // Check companies existing names to validate against duplicates (not case sensitive)
    validateName = () => {
        let sessionNames = this.state.companiesSessions.map(session => session.name.toUpperCase())
        let sessionCheck = sessionNames.indexOf(this.state.sessionName.toUpperCase())
        sessionCheck >= 0 ? 
            this.setState({ submitMessage: 'Session Name already in use', sessionError: true })
            :
            this.setState({ submitMessage: '', sessionError: false })
    }

    generateSessionUrls = () => {
        let query;
        let baseUrl;
        switch(this.state.sessionCommunity) {
        case 'iocdf':
            baseUrl = `${DfSessionsBaseUrl}?product=iocdf`
            query = this.state.sessionUser.isIodSuperAdmin ? '' : `&companyId=${this.state.sessionUser.companyId}`
            break;
        case 'iocedu':
            baseUrl = `${DfSessionsBaseUrl}?product=iocedu`
            query = this.state.sessionUser.isIodSuperAdmin ? '' : `&companyId=${this.state.sessionUser.companyId}`
            break;
        case 'coaching':
            baseUrl = IocSessionsBaseUrl
            query = this.state.sessionUser.isIodSuperAdmin ? '' : `&companyId=${this.state.sessionUser.companyId}`
            break;
        default:
            break;  
        }
        return baseUrl + query
    }

    getCompaniesSessions = async () => {
        let finalUrlEndpoint = this.generateSessionUrls()

        await authAxios.get(finalUrlEndpoint)
        .then(res => {
            this.setState({ companiesSessions: res.data })
        })
        .catch((err) => {
            throw err;
        })
    }

    getOneSession = (id, community) => {
        let baseUrl = community === 'coaching' ? IocSessionsBaseUrl : DfSessionsBaseUrl
        authAxios
        .get(`${baseUrl}/${id}`)
        .then(res => {
            const session = res.data[0]
            this.setState({ selectedSession: session })
        })
        .catch( err => {  
            throw err
        });
    }

    deleteSession = () => {
        let { id } = this.state.selectedSession

        // Specifies where to send the user after the session is deleted
        let baseRoute;
        let redirectPath;
        switch(this.state.sessionCommunity) {
            case 'iocdf':
                baseRoute = DfSessionsBaseUrl
                redirectPath = '/digital-foundations/iocdf/admin/sessions'
                break;
            case 'iocedu':
                baseRoute = DfSessionsBaseUrl
                redirectPath = '/digital-foundations/iocedu/admin/sessions'
                break;
            case 'coaching':
                baseRoute = IocSessionsBaseUrl
                redirectPath = '/coaching/sessions'
                break;
            default:
                break;  
        }

        authAxios
            .delete(`${baseRoute}/${id}`)
            .then(() => {
                this.setState({ sessionDeleted: true, sessionsRedirectPath: redirectPath });
                setInterval( this.setState({ sessionDeleted: false }), 1000 );
            })
            .catch(err => {throw err} );
    }

    confirmSessionDelete = (e) => {
        if(window.confirm(`Are you sure you want to delete ${this.state.selectedSession.name}? `)) {
            e.preventDefault();
            this.deleteSession()
            window.alert(`${this.state.selectedSession.name} has been deleted.`)
        } else { 
            e.preventDefault() 
        }
    }

    clearSessions = () => {
        this.setState({
            selectedSession: {},
            sessionName: ''
        })
    }


    render(){
        return (
            <SessionsContext.Provider 
                value={{
                    ...this.state,
                    setSessionCommunity: this.setSessionCommunity,
                    confirmSessionDelete: this.confirmSessionDelete,
                    validateName: this.validateName,
                    getCompaniesSessions: this.getCompaniesSessions,
                    getOneSession: this.getOneSession,
                    setSessionUser: this.setSessionUser,
                    clearSessions: this.clearSessions,
                    setSessionCredentials: this.setSessionCredentials,
                }}>
                { this.props.children }
            </SessionsContext.Provider>
        )
    }
}

export default SessionsProvider

export const withSessions = C => props => (
    <SessionsContext.Consumer>
        {value => <C {...props} {...value}/>}
    </SessionsContext.Consumer>
)
