const IEShame = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className='under-construction-ie'>
                <p>We are currently unable to display this page on Internet Explorer. According to the Microsoft website, "Since 1/12/16, Microsoft no longer provides security updates or technical support for old versions of Internet Explorer." We recommend that you try another browser for a better and more secure experience.</p>
                {/* Required Attribution to use image */}
                <a href='https://www.freepik.com/vectors/design'>Design vector created by freepik - www.freepik.com</a>
            </div>
        </div>
    )
}

export default IEShame