import React, { Component } from 'react'
import axios from 'axios'
import validator from 'validator'
import decode from 'jwt-decode'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'
import getUrlParams from '../shared/HelperFunctions/getUrlParams'
import { authAxios } from '../shared/CustomAxios'

const baseURL = '/v1/auth'
const authorizatonBaseURL = '/v1/authorizations'

const AuthContext = React.createContext()

class AuthProvider extends Component {
    constructor(){
        super()
        this.state = {
            loginEmailInput: '',
            loginPasswordInput: '',
            registerEmailInput : '',
            registerDepartmentInput: '',
            registerPasswordInput: '',
            registerPasswordConfirmInput: '',
            registerFirstNameInput: '',
            registerLastNameInput: '',
            registerCompanyNameInput: '',
            editFirstName: '',
            editLastName: '',
            editEmail: '',
            editCompany: '',
            forgotPasswordInput: '',
            token: localStorage.getItem("iodToken") || '',
            user: {},
            menuIsOpen: false,
            loginAttemptFailed: false,
            loginRegisterErrorMessage : '',
            accountHasNotBeenActivated: false,
            isRegistered: false,
            newPasswordInput: '',
            confirmPasswordInput: '',
            isReset: false,
            redirect: false,
            displayLoadingIcon: false,
            showForgotPassConfirm: false,
            showForgotPassword: false,
            // Modules
            displayAdminModules: false,
            baseAccessRights: [],
            adminAccessRights: [],
            currentPasswordCheck: '',
            updateErrorMessage: '',
            updateSuccessful: null,
            snapshotsEnabled: true,
        }
    }

    //  State data handler for form input
    authHandleChange = (e) => {
        if(e.target){
            const { name, value } = e.target
            this.setState({ [name]: sanitizeData(value) })
        } else {
            const {name, value} = e
            this.setState({ [name]: sanitizeData(value) })
        }
    }


    getUserFromToken = () => {
        let token = localStorage.getItem("iodToken")
        if(token) {
            let decodedJwt = decode(token)
            this.setState({ user: decodedJwt.user })
            return decodedJwt;
        }
    }


    updateUser = (userId) => {
        let firstName;
        let lastName;
        let email;
        let companyId;
        let id;

        userId ? id = userId : id = this.state.user.id
        this.state.editFirstName ? firstName = this.state.editFirstName : firstName = undefined
        this.state.editLastName ? lastName = this.state.editLastName : lastName = undefined
        this.state.editEmail ? email = this.state.editEmail : email = undefined
        this.state.editCompany ? companyId = this.state.editCompany : companyId = undefined

        let body = {
            firstName: firstName, 
            lastName: lastName, 
            email: email,
            companyId: companyId
        }

        axios
            .put(`/v1/auth/user/${id}`, body)
            .catch((err) => {
                throw err;
        });
    }

    // Admin function to modify a users access rights and remove them from a community
    restrictUsersAccess = ( userId, community ) => {
        let access;
        switch(community){
            case 'iocdf': access = 'hasIocDf'
                break;
            case 'iocedu': access = 'hasIocEdu'
                break;
            case 'coaching': access = 'hasCoaching'
                break;
            default:
                break;
        }

        let body = { [access]: false }
        axios
            .put(`/v1/auth/user/${userId}`, body)
            .catch((err) => {
                throw err
        })
    }

    // IOD Super Admin function to modify a admin's access rights and remove them from being a communities admin
    restrictAdminAccess = ( userId, community ) => {
        let access;
        switch(community){
            case 'iocdf': access = 'isIocDfAdmin'
                break;
            case 'iocedu': access = 'isIocEduAdmin'
                break;
            case 'coaching': access = 'isCoachingClientAdmin'
                break;
            default:
                break;
        }

        let body = { [access]: false }
        axios
            .put(`/v1/auth/user/${userId}`, body)
            .catch((err) => {
                throw err
        })
    }


    // Authenticates and logs in a user
    authHandleLoginSubmit = (history, from) => {
        let userInfo = {
            email: this.state.loginEmailInput,
            password: this.state.loginPasswordInput
        }

        // if onsite update users access rights
        let locationCheck = history.location
        if(locationCheck.pathname.includes('onsite')) {
            let searchParameters = new URLSearchParams(locationCheck.search)
            userInfo['company'] = searchParameters.get('companyId')
            if(locationCheck.search.includes('hasGrowCoaching')) userInfo['access'] = 'hasGrowCoaching'
            if(locationCheck.search.includes('hasCoaching')) userInfo['access'] = 'hasCoaching'
        }

        if(from === '/portal/register/lms') userInfo['community'] = 'lms'

        axios.post('/v1/auth/login', userInfo)
        .then(res => {
            if(res.status === 201){
                //  Successful login
                const { user, iodToken } = res.data
                localStorage.setItem("iodToken", iodToken)

                let destination;
                if(from === '/portal/register/lms'){ // Redirect to snapshots v2 if they are coming from the LMS
                    destination = `/snapshots/coaching-snapshot/v2/?user=${user.id}`
                } else  {// Redirect to their requested route
                    destination = from
                }

                // Temp fix until token creation is updated for user update on login
                const updateTokenAndRedirect = async (userId) => {
                    await this.refreshToken(userId)
                    history.push(destination)
                }

                this.setState({
                    loginAttemptFailed: false, 
                    token: iodToken,
                    user: user,
                    loginPasswordInput: '',
                    accountHasNotBeenActivated: false,
                }, () => updateTokenAndRedirect(user.id))
            } else if(res.status === 202) {
                // Account has not been activated yet
                return this.setState({loginAttemptFailed: true, accountHasNotBeenActivated: true, loginRegisterErrorMessage: res.data.msg})
            } else {
                // Bad email/password combination
                return this.setState({loginAttemptFailed: true, accountHasNotBeenActivated: false, loginRegisterErrorMessage: 'Email or password are incorrect'})
            }
        })
        .catch (err => {
            // Server error, or their account has been suspended
            return this.setState({loginAttemptFailed: true, accountHasNotBeenActivated: false, loginRegisterErrorMessage: err.response.data.msg})
        })
    }


    initializeCoachingUserObject = (locationObject) => {
        const paramsObject = getUrlParams(locationObject.search)
        let coachingData = {
            usersCommunity: 'coaching',
            companyId: paramsObject.company,
            usersAdmin: paramsObject.admin,
            isCoachingClientAdmin: paramsObject.coachingClientAdmin
        }
        return coachingData
    }

    initializeBreakthroughsUserObject = (locationObject) => {
        const paramsObject = getUrlParams(locationObject.search)
        let breakthroughsData = {
            usersCommunity: 'breakthroughs',
            companyId: paramsObject.company,
            usersAdmin: paramsObject.admin
        }
        return breakthroughsData
    }

    initializeDfUserObject = (locationObject, community) => {
        const paramsObject = getUrlParams(locationObject.search)
        let digitalFoundationData = {
            usersCommunity: community,
            companyId: paramsObject.company,
            usersAdmin: paramsObject.admin,
            sessionId: paramsObject.session
        }
        return digitalFoundationData
    }

    passwordCheck = () => {
        let password = this.state.registerPasswordInput
        let passwordConfirm = this.state.registerPasswordConfirmInput
        //  Confirm password is long enough
        let isPasswordLength = validator.isLength(password, { min: 6, max: 30 })
        if(!isPasswordLength){
            this.setState({loginRegisterErrorMessage: 'Password must be 6 - 30 characters', displayLoadingIcon: false })
        }

        //  Confirm both password fields match
        let doPasswordsMatch = validator.equals(password, passwordConfirm)
        if(!doPasswordsMatch){
            this.setState({loginRegisterErrorMessage: 'Passwords do not match', displayLoadingIcon: false })
        } 

        return doPasswordsMatch && isPasswordLength
    }

    // Checks to see if authorization record for users email exists
    checkAuthorizationRecords = async () => {
        try {
            let res = await axios.get(`${authorizatonBaseURL}?email=${this.state.registerEmailInput}`)
            return res.data[0]
        } catch (err) {
            return false
        }
    }

    // Build the newUser object based on authorization record if found
    // Otherwise builds newUser with no access rights
    buildNewUserRecord = (authorizationRecord) => {
        if(authorizationRecord){
            // if the auth record has GROW Coaching, add a year to the current date and set as expiration date
            let expirationDate = new Date()
            // the date to check is the current date - 1 year
            expirationDate.setFullYear( expirationDate.getFullYear() + 1 )

            return {
                email: this.state.registerEmailInput,
                password: this.state.registerPasswordInput,
                firstName: this.state.registerFirstNameInput,
                lastName: this.state.registerLastNameInput,
                companyId: authorizationRecord.companyId, 
                hasCoaching: authorizationRecord.grantIoc,
                isCoachingClientAdmin: authorizationRecord.grantIocAdmin,
                hasBreakthroughs: authorizationRecord.grantBt,
                isBreakthroughsClientAdmin: authorizationRecord.grantBtAdmin,
                hasFacilitator: authorizationRecord.grantIof,
                isFacilitatorClientAdmin: authorizationRecord.grantIofAdmin,
                hasIocDf: authorizationRecord.grantIocdf,
                isIocDfAdmin: authorizationRecord.grantIocdfAdmin,
                // hasIocEdu: authorizationRecord,
                // isIocEduAdmin: authorizationRecord,
                hasA2b: authorizationRecord.grantA2b,
                isA2bAdmin: authorizationRecord.grantA2bAdmin,
                hasGrowCoaching: authorizationRecord.grantGrowCoaching,
                isGrowClientAdmin: authorizationRecord.grantGroCoachingAdmin,
                // growExpirationDate: authorizationRecord.grantGrowCoaching ? expirationDate : null,
                // Use to update session participant record
                sessionId: authorizationRecord.sessionId
            }
        } else {
            return {
                email: this.state.registerEmailInput,
                password: this.state.registerPasswordInput,
                firstName: this.state.registerFirstNameInput,
                lastName: this.state.registerLastNameInput
            }
        }
    }

    // Removes the authorization record used to create the user registration object after successful registration.
    removeAuthorizationRecord = async (authId) => {
        let res = await axios.delete(`${authorizatonBaseURL}/${authId}`)
        .then(() => res)
        .catch(err => {
            console.log(err)
        })
    }

    handleUserRegistration = async (locationObject, history) => {
        this.setState({ displayLoadingIcon: true })

        let referringUrlBasePath = locationObject.pathname
        let registrationParams;
        let onSiteRegistrationParams;

        if(referringUrlBasePath.includes('iocdf')){
            registrationParams = this.initializeDfUserObject(locationObject, 'iocdf')
        }

        if(referringUrlBasePath.includes('iocedu')){
            registrationParams = this.initializeDfUserObject(locationObject, 'iocedu')
        }

        // default add an onsite clients company and what products they are supposed to have access to
        if(referringUrlBasePath.includes('onsite')){
            onSiteRegistrationParams = getUrlParams(locationObject.search)
        }

        let validPassword = this.passwordCheck()
        //  If all data is good, create the new user object in the DB
        if(validPassword){
            const authorizationRecord = await this.checkAuthorizationRecords()
            const newUser = this.buildNewUserRecord(authorizationRecord)
            if(referringUrlBasePath.includes('lms')) {
                newUser.isActive = true // Autoactivate LMS accounts. 
                newUser.hasLmsSnapshots = true // They get access to lms snapshot reports
            }
            
            if(registrationParams){
                if(registrationParams.usersCommunity) newUser.community = registrationParams.usersCommunity
                if(registrationParams.companyId) newUser.companyId = registrationParams.companyId
                if(registrationParams.isCoachingClientAdmin) newUser.isCoachingClientAdmin = registrationParams.isCoachingClientAdmin
                if(registrationParams.usersAdmin) newUser.usersAdmin = registrationParams.usersAdmin
                if(registrationParams.sessionId) newUser.sessionId = registrationParams.sessionId
            }

            // currently built to handle IOC and GROW
            if(onSiteRegistrationParams){
                // only append if there is a companyId associated so we can track
                if(onSiteRegistrationParams.companyId){
                    newUser.companyId = onSiteRegistrationParams.companyId
                    newUser.community = 'onsite'
                    if(onSiteRegistrationParams.hasGrowCoaching) newUser.hasGrowCoaching = +onSiteRegistrationParams.hasGrowCoaching
                    if(onSiteRegistrationParams.hasCoaching) newUser.hasCoaching = +onSiteRegistrationParams.hasCoaching
                }
            }

            // If the user had an authorization record use new registration route.
            let postRoute =  authorizationRecord ? '/v2/authentication' : '/v1/auth'

            axios.post(`${postRoute}/register`, newUser)
            .then(res => {
                if(res.status === 201){
                    const { user } = res.data

                    // Stage user Credentials for lms autologin
                    const email = this.state.registerEmailInput
                    const password = this.state.registerPasswordInput

                    // Clear State
                    this.setState({
                        loginAttemptFailed: false, 
                        isRegistered: true,
                        registerPasswordInput: '',
                        registerPasswordConfirmInput: '',
                        displayLoadingIcon: false,
                    })
                    // Sets credentials for autologin in state if comming from lms
                    if(referringUrlBasePath.includes('lms')){
                        this.setState({
                            loginEmailInput: email,
                            loginPasswordInput: password
                        }, () => this.lmsAccountLogin(user.id, history))
                    } 

                } else {
                    return this.setState({loginAttemptFailed: true, loginRegisterErrorMessage: 'This email address is already registered. Click the link below to login.', displayLoadingIcon: false})
                }
            })
            .then(() => {
                // Remove authorization record after user is created
                authorizationRecord && this.removeAuthorizationRecord(authorizationRecord.id)
            })
            .catch (() => {
                return this.setState({loginAttemptFailed: true, loginRegisterErrorMessage: 'Something broke. Please refresh the page and try again.', displayLoadingIcon: false})
            })
        }
    }


    // If user created an account via the LMS set them to redirect to Snapshots, then proceed to login function
    lmsAccountLogin = (userId, history) => {
        console.log(userId)
        let destination = { 
            pathname: `/snapshots/coaching-snapshot/v2/`,
            search: `?user=${userId}`
        }
        this.authHandleLoginSubmit(history, destination)
    }


    //  Send an email to a user in order to reset their password
    handlePasswordResetRequest = (email) => {
        let userEmail;
        email ? userEmail = {email : email} : userEmail= {email: this.state.forgotPasswordInput}

        axios.post('/v1/auth/user/password-reset-email', userEmail).then(res => {
            if(res.status === 201){
                this.setState({showForgotPassConfirm: true, showForgotPassword: false})
            } else {
                return this.setState({loginAttemptFailed: true, loginRegisterErrorMessage: 'Email is not recognized'})
            }
        })
        .catch (err => {
            return this.setState({loginAttemptFailed: true, loginRegisterErrorMessage: err.response.data.msg})
        })
    }

    //  Resets a users password in the database
    handlePasswordReset = (userId) => {
        let password = this.state.newPasswordInput
        let passwordConfirm = this.state.confirmPasswordInput

        // Ensure both passwords match
        let doPasswordsMatch = validator.equals(password, passwordConfirm)
        if(!doPasswordsMatch){
            return this.setState({loginRegisterErrorMessage: 'Passwords do not match'})
        }

        //  Ensure the password is long enough
        let isPasswordLength = validator.isLength(password, { min: 6, max: 30 })
        if(!isPasswordLength){
            return this.setState({loginRegisterErrorMessage: 'Password must be 6 - 30 characters'})
        }

        //  Send the new password to the database to be updated
        let passwordReset = { password: this.state.newPasswordInput}
        axios.put(`${baseURL}/user/${userId}`, passwordReset).then(res => {
            if(res.status === 201){
                this.setState({isReset: true, loginRegisterErrorMessage: ''})
                this.timer = setInterval( this.handleRedirect, 2000 );
            } else {
                return this.setState({loginRegisterErrorMessage: 'Something didnt work. Please try again'})
            }
        })
        .catch(() => {
            return this.setState({loginAttemptFailed: true, loginRegisterErrorMessage: 'Something didnt work. Please try again'})
        })
    }


    handleRedirect = () => {
        this.setState({ 
            redirect: true
        });
        clearInterval(this.timer);
    }

    clearErrorMessages = () => {
        this.setState({ loginRegisterErrorMessage: '', loginAttemptFailed: false, accountHasNotBeenActivated: false })
    }

    logOut = () => {
        localStorage.removeItem("iodToken");
        this.setState({
            user: {},
            token: "",
            menuIsOpen: !this.state.menuIsOpen
        });
    }

    sendNewCompanyIdToAuthProvider = (selecetedCompanyId) => {
        if(selecetedCompanyId) this.setState({editCompany: selecetedCompanyId})
    }

    activateUserAccount = (userId) => {
        let body = { isActive: true, deactivatedByAdmin: false }
        axios
            .put(`/v1/auth/user/${userId}`, body)
            .catch((err) => {
                throw err
        })
    }

    // removes access to all communities
    deactivateUserAccount = (user) => {
        const userId = user.id;
        for(let key in user) {
            if((key.startsWith('is', 0)) || (key.startsWith('has', 0))) {
                user[key] = false;
            } else if(key === 'deactivatedByAdmin') {
                user[key] = true;
            } else {
                delete user[key]
            }
        }
        axios
            .put(`/v1/auth/user/${userId}`, user)
            .catch((err) => {
                throw err
        })
    }

    // keeps community access but sets them inactive
    setUserInactive = (userId) => {
        let body ={
            isActive: false
        }
        axios.put(`/v1/auth/user/${userId}`, body)
        .then(res => console.log(res.data))
        .catch((err) => {
            console.log(err)
        })
    }

    setIocUserCompleteTutorial = () => {
        let user = this.state.user
        user.isNewIOCUser = false
        this.setState({ user  })
    }

    checkIfUsersCompanyAllowsSnapshots = async (companyId) => {
        try {
            let res = await authAxios.get(`/v1/api/global/companies/takes-snapshot/${companyId}`)
            this.setState({snapshotsEnabled: res.data })
            return res.data
        } catch (err) {
            console.log(err)
            this.setState({snapshotsEnabled: false })
        }
    }

    // Modules
    setCommunityAccess = async (user) => {
        let hasAccessTo = []
        let isAdminOf = []
        let hasAdminAccess = false
        // let takesSnapshot = await this.checkIfUsersCompanyAllowsSnapshots(user.companyId)

        // generate array of communities the user can see
        if(user.hasCoaching || user.isIodSuperAdmin) hasAccessTo.push('Coaching')
        if(user.hasGrowCoaching || user.isIodSuperAdmin) hasAccessTo.push('GROW Coaching')
        if(user.hasBreakthroughs || user.isIodSuperAdmin) hasAccessTo.push('Breakthroughs')
        if(user.hasFacilitator || user.isIodSuperAdmin) hasAccessTo.push('Facilitator')
        if(user.hasIocDf || user.isIodSuperAdmin) hasAccessTo.push('Digital Foundations')
        if(user.hasLmsSnapshots || user.isIodSuperAdmin) {
            hasAccessTo.push('LMS Snapshots')
            // since there is no admin, use just the user check until one is built
            // isAdminOf.push('LMS Snapshots')
        }
        if(user.hasA2b || user.isIodSuperAdmin) hasAccessTo.push('A2B')
        // generate an array of communities the user is admin of
        if(user.isIodSuperAdmin) isAdminOf = ['Coaching', 'GROW Coaching', 'Facilitator', 'Digital Foundations', 'A2B', 'Breakthroughs']

        // comment back in when Participants is finished
        // if(user.isCoachingClientAdmin || user.isIodSuperAdmin) isAdminOf.push('Coaching')
        // if(user.isGrowClientAdmin || user.isIodSuperAdmin) isAdminOf.push('GROW Coaching')
        // if(user.isBreakthroughsClientAdmin || user.isIodSuperAdmin) isAdminOf.push('Breakthroughs')
        // if(user.isFacilitatorClientAdmin || user.isIodSuperAdmin) isAdminOf.push('Facilitator')
        // if(user.isIocDfAdmin || user.isIodSuperAdmin) isAdminOf.push('Digital Foundations')
        // if(user.isA2bAdmin || user.isIodSuperAdmin) isAdminOf.push('A2B')

        // check the admin array to see if the user has access to any admin components
        if(isAdminOf.length > 0) hasAdminAccess = true
        this.setState({ 
            baseAccessRights: hasAccessTo, 
            adminAccessRights: isAdminOf,
            displayAdminModules: hasAdminAccess, 
            user: user
        })
    }

    // used in resources preferences component
    updateUserInfo = (userId) => {
        let updatedUser = this.state.user

        if(this.state.editFirstName) {
            updatedUser.firstName = this.state.editFirstName
        }
        if(this.state.editLastName) {
            updatedUser.lastName = this.state.editLastName
        }
        if(this.state.editEmail) {
            updatedUser.email = this.state.editEmail
        }

        axios.put(`/v1/auth/user/${userId}`, updatedUser)
        .then(() => {
            this.refreshToken(userId)
        })
        .catch(() => {
            this.setState({ updateErrorMessage: 'Something went wrong with the update. Please try again' })
        });
    }

    // refresh the users token and reload the page to show updates
    refreshToken = async (userId) => {
        localStorage.removeItem("iodToken")
        await axios.get(`/v1/auth/generate-new-token/${userId}`)
        .then(res => {
            const { iodToken } = res.data
            localStorage.setItem("iodToken", iodToken)
            this.setState({ updateSuccessful: true,  updateErrorMessage: '' })
            return
        })
        .catch (() => {
            this.setState({ updateErrorMessage: 'Could not automatically update your record. Please logout and back in to see the changes' })
            return
        })
    }

    verifyPassword = async (id) => {
        let body = { enteredPass: this.state.currentPasswordCheck }
        try {
            const res = await axios.post(`/v1/auth/verify-password/${id}`, body)
            // if the current password dosent match, set the err and err message
            if(!res.data.success) {
                this.setState({ updateErrorMessage: res.data.msg })
            } else {
                this.setState({ updateErrorMessage: '' })
            }
            // return whether or not the request worked
            return res.data.success
        } catch (err) {
            // this only runs if something on the route breaks, not if the passwords dont match
            this.setState({ updateErrorMessage: 'There was a problem authenticating your current password' })
            return false
        }
    }

    //  Resets a users password in the database
    handlePasswordResetV2 = async (userId) => {
        let password = this.state.newPasswordInput
        let passwordConfirm = this.state.confirmPasswordInput
        // Ensure both passwords match
        let doPasswordsMatch = validator.equals(password, passwordConfirm)
        if(!doPasswordsMatch){
            return this.setState({updateErrorMessage: 'Passwords do not match'})
        }
        //  Ensure the password is long enough
        let isPasswordLength = validator.isLength(password, { min: 6, max: 30 })
        if(!isPasswordLength){
            return this.setState({updateErrorMessage: 'Password must be 6 - 30 characters'})
        }
        //  Send the new password to the database to be updated
        let passwordReset = {password: this.state.newPasswordInput}
        try {
            let res = await axios.put(`${baseURL}/user/${userId}`, passwordReset)
            if(res.status === 201){
                this.setState({updateSuccessful: true, updateErrorMessage: ''})
            } else {
                return this.setState({updateErrorMessage: 'There was a problem authenticating your new password. Please try again'})
            }
        } catch (error) {
            return this.setState({updateErrorMessage: 'There was a problem authenticating your new password. Please try again'})
        }
    }

    resetErrors = () => {
        this.setState({
            updateSuccessful: null,
            updateErrorMessage: ''
        })
    }

    // Used for the mark complete button at the bottom of Snapshots prework. 
    // Redirects to the snapshots page on completion (could send to home?)
    completePrework = (product) => {
        let user = this.state.user

        if(product === 'GROW') user.isNewGrowUser = false
        if(product === 'IOC') user.isNewIOCUser = false
        
        try {
            axios.put(`/v1/auth/user/${user.id}`, user)
            this.setState({ user: user, editSuccessful: true })
            this.refreshToken(user.id)
            this.timer = setInterval( this.handleRedirect, 2000 );
        } catch (err) {
            this.setState({ editSuccessful: false })
        }
    }


    render(){
        // console.log(this.state.token)
        return (
            <AuthContext.Provider 
                value={{
                    ...this.state,
                    authHandleChange: this.authHandleChange,
                    authHandleLoginSubmit: this.authHandleLoginSubmit,
                    handleUserRegistration: this.handleUserRegistration,
                    logOut: this.logOut,
                    clearErrorMessages: this.clearErrorMessages,
                    handlePasswordResetRequest: this.handlePasswordResetRequest,
                    handlePasswordReset: this.handlePasswordReset,
                    getUserFromToken: this.getUserFromToken,
                    updateUser: this.updateUser,
                    restrictUsersAccess: this.restrictUsersAccess,
                    restrictAdminAccess: this.restrictAdminAccess,
                    sendNewCompanyIdToAuthProvider: this.sendNewCompanyIdToAuthProvider,
                    activateUserAccount: this.activateUserAccount,
                    deactivateUserAccount: this.deactivateUserAccount,
                    setIocUserCompleteTutorial: this.setIocUserCompleteTutorial,
                    // Modules
                    setCommunityAccess: this.setCommunityAccess,
                    updateUserInfo: this.updateUserInfo,
                    verifyPassword: this.verifyPassword,
                    handlePasswordResetV2: this.handlePasswordResetV2,
                    resetErrors: this.resetErrors,
                    setUserInactive: this.setUserInactive,
                    completePrework: this.completePrework,
                    checkIfUsersCompanyAllowsSnapshots: this.checkIfUsersCompanyAllowsSnapshots
                }}>
                { this.props.children }
            </AuthContext.Provider>
        )
    }
}

export default AuthProvider

export const withAuth = C => props => (
    <AuthContext.Consumer>
        {value => <C {...props} {...value}/>}
    </AuthContext.Consumer>
)
