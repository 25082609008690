import React, { Component } from 'react'
import { authAxios } from '../shared/CustomAxios'

const baseURL = '/v1/email-subscriptions'
const EmailSubscriptionContext = React.createContext()

class EmailSubscriptionProvider extends Component {
    constructor(){
        super()
        this.state = {
            usersEmailSubs: [],
            unsubscribeFromAll: false,
            dfUpdates: true,
            dfReminders: true,
            snapshotUpdates: true,
            snapshotReminders: true,
            submissionMessage: '',
            submissionError: null
        }
    }

    emailSubscriptionHandleChange = (e) => {
        if(e.target){
            const { name, checked } = e.target
            this.setState({ [name]: checked })
        } else {
            const {name, checked} = e
            this.setState({ [name]: checked })
        }
    }

    checkUnsubscribeAll = () => {
        if(this.state.unsubscribeFromAll === true){
            this.setState({ 
                dfUpdates: false,
                dfReminders: false,
                snapshotUpdates: false,
                snapshotReminders: false
            })
        } else {
            this.setState({ 
                unsubscribeFromAll: false,
                dfUpdates: this.state.usersEmailSubs.hasDfUpdates,
                dfReminders: this.state.usersEmailSubs.hasDfReminders,
                snapshotUpdates: this.state.usersEmailSubs.hasIocSnapshotReminders,
                snapshotReminders: this.state.usersEmailSubs.hasIocSnapshotUpdates
            })
        }
    }

    getAllUsersEmailSubscriptions = (userId) => {
        authAxios.get(`${baseURL}?userId=${userId}`)
        .then(res => {
            let subData = res.data[0]
            this.setState({ 
                usersEmailSubs: subData,
                unsubscribeFromAll: subData.unsubscribedFromAll,
                dfUpdates: subData.hasDfUpdates,
                dfReminders: subData.hasDfReminders,
                snapshotUpdates: subData.hasIocSnapshotReminders,
                snapshotReminders: subData.hasIocSnapshotUpdates
            })
        })
        .catch((err) => {
            throw err;
        })
    }

    getUsersEmailSubscriptionsFromURL = (id) => {
        authAxios.get(`${baseURL}?id=${id}`)
        .then(res => {
            let subData = res.data[0]
            this.setState({ 
                usersEmailSubs: subData,
                unsubscribeFromAll: subData.unsubscribedFromAll,
                dfUpdates: subData.hasDfUpdates,
                dfReminders: subData.hasDfReminders,
                snapshotUpdates: subData.hasIocSnapshotReminders,
                snapshotReminders: subData.hasIocSnapshotUpdates
            })
        })
        .catch((err) => {
            throw err;
        })
    }

    updateSubscriptions = () => {
        let body = {
            unsubscribedFromAll: this.state.unsubscribeFromAll,
            hasDfUpdates: this.state.dfUpdates,
            hasDfReminders: this.state.dfReminders,
            hasIocSnapshotReminders: this.state.snapshotReminders,
            hasIocSnapshotUpdates: this.state.snapshotUpdates
        } 
        authAxios.put(`${baseURL}/${this.state.usersEmailSubs.id}`, body)
        .then(
            this.setState({submissionError: false, submissionMessage: 'Your email preferences have been successfully updated!' })
        )
        .catch((err) => {
            this.setState({submissionError: true, submissionMessage: 'There was an error updating your email subscription preferences.' })
            throw err;
        });
    }

    render(){
        return (
            <EmailSubscriptionContext.Provider 
                value={{
                    ...this.state,
                    getAllUsersEmailSubscriptions: this.getAllUsersEmailSubscriptions,
                    emailSubscriptionHandleChange: this.emailSubscriptionHandleChange,
                    checkUnsubscribeAll: this.checkUnsubscribeAll,
                    updateSubscriptions: this.updateSubscriptions,
                    getUsersEmailSubscriptionsFromURL: this.getUsersEmailSubscriptionsFromURL
                }}>
                { this.props.children }
            </EmailSubscriptionContext.Provider>
        )
    }
}

export default EmailSubscriptionProvider

export const withEmailSubscription = C => props => (
    <EmailSubscriptionContext.Consumer>
        {value => <C {...props} {...value}/>}
    </EmailSubscriptionContext.Consumer>
)

