/**
 *  ProtectedRoute.js is a HOC that works site wide.  When the user wants to access a component, it checks if they are authorized.  If so, it returns the      requested component with all props, otherwise it redirects them back to the home component. 
 *  @props - Coming in from AuthProvider.js via context - Nothing is prop drilled to it.
 *  THIS COMPONEST LIVES IN: App.js
 *  THIS COMPONENT IS DEPENDENT ON:  Recieving user and token props from AuthProvider.js via context.  
 *  OTHER COMPONENTS RELIANT ON THIS COMPONENT: All components via the Route in App.js
 */

import { Component } from 'react'
import { withAuth } from '../../Context/AuthProvider'
import { Route, Redirect } from 'react-router-dom'
import ErrorBoundary from '../ErrorBoundary'
import decode from 'jwt-decode';
import Footer from '../../GlobalComponents/Footer/Footer'


class ProtectedRoute extends Component {
    render() {
        const { token, path, otherProps, location } = this.props
        const Component = this.props.component
        let isTokenExpired = false;
        let decodedJwt = null;
        let canAccessComponent = false

        const checkAccessRights = (user) => {
            if(user.isIodSuperAdmin){
                canAccessComponent = true
            } else {
                switch(path){
                    case '/dashboard':
                        canAccessComponent = true
                        break;
                    case '/account-settings':
                        canAccessComponent = true
                        break;
                    case '/features':
                        canAccessComponent = true
                        break;
                    case '/coaching':
                        user.hasCoaching ? canAccessComponent = true : canAccessComponent = false
                        break;
                    case '/breakthroughs':
                        user.hasBreakthroughs ? canAccessComponent = true : canAccessComponent = false
                        break;
                    case '/a2b':
                        user.hasA2b ? canAccessComponent = true : canAccessComponent = false
                        break;
                    case '/facilitator':
                        user.hasFacilitator ? canAccessComponent = true : canAccessComponent = false
                        break;
                    case '/digital-foundations/iocdf':  
                        user.hasIocDf ? canAccessComponent = true : canAccessComponent = false
                        break;  
                    case '/digital-foundations/btdf':  
                        user.hasBtDf ? canAccessComponent = true : canAccessComponent = false
                        break;  
                    case '/digital-foundations/iocedu':  
                        user.hasIocEdu ? canAccessComponent = true : canAccessComponent = false
                        break;  
                    case '/lms/snapshots':  
                        user.hasLmsSnapshots ? canAccessComponent = true : canAccessComponent = false
                        break;  
                    case '/grow-coaching':  
                        (user.hasGrowCoaching || user.hasLmsSnapshots) ? canAccessComponent = true : canAccessComponent = false
                        break; 
                    default:
                        canAccessComponent = false
                }
            }
        }

        if(this.props.token) {
            decodedJwt = decode(this.props.token)
            if(decodedJwt.exp < Date.now() / 1000) {
                this.props.logOut();
                isTokenExpired = true;
            } else {
                checkAccessRights(decodedJwt.user)
            }
        }
        else {
            decodedJwt = null;
        }

        return (
            token && !isTokenExpired && canAccessComponent ?
            (
                <Route
                    path={path}
                    render={props => (
                        <ErrorBoundary >
                            <Component {...props} {...otherProps} currentUser={decodedJwt.user} />
                            <Footer />
                        </ErrorBoundary>
                    )}
                />
            ) : token && !isTokenExpired && !canAccessComponent ? (
                <Redirect to={{
                    pathname: '/dashboard',
                    state: { from: location }
                }} />
            ) : (
                <Redirect to={{
                    pathname: '/portal/login',
                    state: { from: location }
                }} />
            )
        )
    }
}

export default withAuth(ProtectedRoute)