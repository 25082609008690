import React, { Component } from 'react'
import { authAxios } from '../shared/CustomAxios'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'
import moment from 'moment'
import pulseReminderEmail from '../shared/EmailTemplates/pulseReminderEmail'
import sendNodeMailer from '../shared/HelperFunctions/sendNodeMailer'
import { iodFrontEndUrl } from '../config/Config'

const pulseBaseURL = '/v1/api/a2b/pulse'
const PulseContext = React.createContext()

const defaultPulseState = {
    pulseHistory: [],
    historyDates: [],
    historyPulse: [],
    currentPage: 0,
    recordsPerPage: 10,
    totalPages: 0,

    initialPulse: {},
    urlPulse: {},
    pulseScore: 0,
    pulseQuestion: '',
    pulseMessage: '',
    pulseError: null,

    departmentPulses: [],
    viewingAnalytics: false,
    emailError: false,
    loggedInUsersPulseInfo: {
        id: '',
        reviewedEmployees: false
    },
    // Analytics
    allFilteredPulses: [],
    completionCounts: {},
    averageDates: [],
    averagePulses: [],
    totalAverage: 0,
    
    displayPulses: [],
    availableWeeks: [],
    displayWeekOf: '',
    availableDepartments: [],
    initialDepartments: [],
    displayDepartment: ''
}

class PulseProvider extends Component {
    constructor(){
        super()
        this.state = defaultPulseState
    }

    getUsersPulseHistory = (userId) => {
        authAxios.get(`${pulseBaseURL}/history?userId=${userId}&page=${this.state.currentPage}&pageSize=${this.state.recordsPerPage}`)
        .then(res => {
            let weeks = []
            let pulse = []
            res.data.forEach(week => {
                if(week.pulseScore !== null){
                    weeks.push(moment(week.weekOf).format("MMMM Do"))
                    pulse.push(week.pulseScore)
                }
            })
            this.setState({ pulseHistory: res.data, historyDates: weeks, historyPulse: pulse }, () => this.getUsersHistoryTotal(userId))
        })
        .catch((err) => {
            throw err;
        })
    }

    getUsersHistoryTotal = (userId) => {
        authAxios.get(`${pulseBaseURL}/?userId=${userId}`)
        .then(res => {
            const totalRecords = res.data.length
            const totalPages = Math.ceil(totalRecords / this.state.recordsPerPage)
            this.setState({ totalPages: totalPages - 1 })
        })
        .catch((err) => {
            throw err;
        })
    }

    getUrlPulse = (id) => {
        authAxios.get(`${pulseBaseURL}/${id}`)
        .then(res => {
            this.setState({ urlPulse: res.data[0] })
        })
        .catch((err) => {
            throw err;
        })
    }
    
    getSundayOfCurrentWeek = () => {
        const today = moment()
        const nextWeek = today.add(1, 'w');
        const upcomingSunday = nextWeek.startOf('week')
        return upcomingSunday
    }

    getFridayOfCurrentWeek = () => {
        const today = moment()
        const friday = today.day(5)
        return friday
    }

    createInitialPulse = (userId) => {
        const newPulse = {
            userId: userId,
            weekOf: this.getSundayOfCurrentWeek(),
            dueDate: this.getFridayOfCurrentWeek(),
        }
        authAxios.post(`${pulseBaseURL}`, newPulse)
        .then(res => {
            this.setState({ initialPulse: res.data })
        })
        .catch((err) => {
            throw err;
        })
    }

    nextPage = () => {
        this.setState({ currentPage: this.state.currentPage + 1 })
    }

    previousPage = () => {
        this.state.currentPage > 0 &&
        this.setState({ currentPage: this.state.currentPage - 1 })
    }

    updatePulseScore = (score) => {
        this.setState({ pulseScore: score })
    }

    addPulseQuestion = () => {
        if(this.state.pulseScore <= 3){
            this.setState({ pulseQuestion: 'Happens to all of us. Anything you need?' })
        } else if(this.state.pulseScore > 3 && this.state.pulseScore <= 5) {
            this.setState({ pulseQuestion: 'Is there anything blocking you that you’d like addressed?'})
        } else if(this.state.pulseScore > 5 && this.state.pulseScore <= 7) {
            this.setState({ pulseQuestion: 'Anything to add?'})
        }else if(this.state.pulseScore > 7 && this.state.pulseScore < 10) {
            this.setState({ pulseQuestion: 'Smooth and steady! Anything to add?'})
        } else if(this.state.pulseScore === 10) {
            this.setState({ pulseQuestion: 'Cue the dance party! What led to you feeling so good?'})
        }
    }

    pulseOnChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: sanitizeData(value) })
    }

    submitSurvey = (e, id) => {
        e.preventDefault()
        const data = {  
            pulseScore: this.state.pulseScore,
            pulseMessage: this.state.pulseMessage.length === 0 ? ' ' : this.state.pulseMessage,
            submittedOn: new Date()
        }

        authAxios.put(`${pulseBaseURL}/${id}`, data)
            .then(() => {
                this.setState({ pulseError: false })
            })
            .catch(() => {
                this.setState({ pulseError: true })
            })
    }

    clearSurvey = () => {
        this.setState({ pulseScore: 0, pulseQuestion: '', pulseMessage: '', pulseError: null })
    }

    updateAvailableWeeks = (directReports) => {
        let weeks = []
        directReports.forEach(employee => weeks.push(employee.weekOf))
        let filteredWeeks = [...new Set(weeks)]
        this.setState({ availableWeeks: filteredWeeks, displayWeekOf: filteredWeeks[0] })
    }

    updateAvailableDepartments = (directReports, loggedInEmployeeData) => {
        let departments = []
        directReports.forEach(employee => departments.push(employee.Employee.department))
        let filteredDepartments = [...new Set(departments)]
        let defaultDepartment = ''
        if(loggedInEmployeeData.canViewAll){
            defaultDepartment = loggedInEmployeeData.department === 'CEO' ? 'Leadership' : 'CompanyWide'
        }

        this.setState({ availableDepartments: filteredDepartments, displayDepartment: defaultDepartment})
    }

    updateInitialDepartments = (employees) => {
        let departments = []
        employees.forEach(employee => departments.push(employee.department))
        let filteredDepartments = [...new Set(departments)]
        this.setState({ initialDepartments: filteredDepartments })
    }

    filterPulses = (directReports) => {
        // filter based on week
        let filteredWeekPulses = directReports.filter(pulse => pulse.weekOf === this.state.displayWeekOf)
        // filter based on department
        let filteredDepartmentPulses = []
        if(this.state.displayDepartment === 'CompanyWide'){
            filteredDepartmentPulses = filteredWeekPulses
        } else if(this.state.displayDepartment === 'Leadership'){
            filteredDepartmentPulses = filteredWeekPulses.filter(pulse => pulse.Employee.isLeadershipTeam)
        } else if(this.state.displayDepartment === '') {
            // if the user cant filter it means they dont have view all access and shouldnt be able to see anyone other than their direct reports.
            filteredDepartmentPulses = filteredWeekPulses
        } else {
            // filter function to work with departments rather than direct reports if the user has access to the department dropdown
            filteredDepartmentPulses = filteredWeekPulses.filter(pulse => pulse.Employee.department === this.state.displayDepartment)
        }
        
        this.setState({ departmentPulses: filteredDepartmentPulses})
    }

    updateLoggedInUsersPulseInfo = async (userId) => {
        try {
            let res = await authAxios.get(`${pulseBaseURL}?userId=${userId}&weekOf=${this.state.displayWeekOf}`)
            let data = res.data[0]
            this.setState({loggedInUsersPulseInfo: {
                id: data.id,
                reviewedEmployees: data.reviewedEmployees
            }})
        } catch(err) {
            this.setState({loggedInUsersPulseInfo: {
                id: null,
                reviewedEmployees: false
            }})
        }
    }

    markEmployeesReviewed = () => {
        const data = { reviewedEmployees: true }

        authAxios.put(`${pulseBaseURL}/${this.state.loggedInUsersPulseInfo.id}`, data)
            .then(() => {
                this.setState({loggedInUsersPulseInfo: {
                    id: this.state.loggedInUsersPulseInfo.id,
                    reviewedEmployees: true
                }})
            })
            .catch((err) => {
                throw err;
            })
    }

    sendPulseReminderEmail = (user, recipient) => {
        const pulseReminderData = { 
            emailRecipient: recipient, 
            iodFrontEndUrl: iodFrontEndUrl,
        }
        
        let pulseReminderBody = pulseReminderEmail(pulseReminderData) 

        let senderBody = { 
            recipient: recipient.user.email, 
            subject: "Pulse Reminder", 
            emailTemplate: pulseReminderBody, 
            emailLogId: '',
            carbonCopy: user.email 
        }
        let logBody = { 
            senderEmail: user.email, 
            receiverEmail: recipient.user.email, 
            purpose: 'Admin Pulse Nudge', 
            userId: user.id
        }

        sendNodeMailer(senderBody, logBody)
        // alert(`Reminder email sent to ${recipient.user.firstName} ${recipient.user.lastName}`)
    }

    isViewingAnalytics = (value) => {
        this.setState({ viewingAnalytics: value, displayDepartment: '', displayWeekOf: ''})
    }

    // Analytics
    // Data used for Trends and Analytics, runs all analytics functions below
    getPulseAnalytics = async () => {
        let showAll = this.state.displayDepartment === 'CompanyWide' || this.state.displayDepartment === ''
        let showLeadership = this.state.displayDepartment === 'Leadership'
        // Fix table joins to be able to use the department of who they report to and check for CEO instead of id
        let query = showAll ? '' : showLeadership ? '?isLeadershipTeam=1' : `?department=${this.state.displayDepartment}` 

        try {
            let response = await authAxios.get(`${pulseBaseURL}/analytics${query}`)
            let res = response.data
            res.forEach(user => {
                user.Pulses.forEach(pulse => {
                    if(pulse.pulseScore !== null)pulse.pulseScore = parseInt(pulse.pulseScore)
                })
            })
            this.setState({ allFilteredPulses: res }, () => {
                this.getAnalyticsData(res)
            })
        } catch(err) {
            console.error(err)
            throw err;
        }
    }

    getAnalyticsData = async (data) => {
        let pulses = []
        data.forEach(user => {
            user.Pulses.length && pulses.push(user.Pulses)
        })
        let combinedPulses = pulses.flat()

        let results = await Promise.all([
            this.runCompletionCounts(combinedPulses),
            this.combinePulses(combinedPulses)
        ])

        return results
    }

    runCompletionCounts = (pulses) => {
        let completionObject = {}
        let countOnTime = 0
        let countLate = 0
        let countMissing = 0

        pulses.forEach(record => {
            if(record.submittedOn === null){ countMissing++ }
            if(record.submittedOn > record.weekOf){ countLate++ }
            if(record.submittedOn <= record.weekOf){ countOnTime++ }
        })

        completionObject.onTime = countOnTime
        completionObject.late = countLate
        completionObject.missing = countMissing

        this.setState({ completionCounts: completionObject })
    }

    combinePulses = (pulses) => {
        let weeks = []
        let averages = []
        const combinedArray = Object.values(pulses.reduce((acc, curr) => {
            curr.pulseScore !== null &&
            (acc[curr.weekOf] || (acc[curr.weekOf] = {weekOf: curr.weekOf, pulseArray: []})).pulseArray.push(curr.pulseScore);
            return acc;
        }, {}));
        // Sort the Array by weekOf
        combinedArray.sort((a, b) =>new Date(a.weekOf) - new Date(b.weekOf))

        // Find the value of each average array
        combinedArray.forEach(week => {
            weeks.push(moment(week.weekOf).format("MMMM Do"))
            let pulseAverage = week.pulseArray.reduce((a,b) => a + b, 0) / week.pulseArray.length
            averages.push(pulseAverage)
        })

        this.setState({averageDates: weeks, averagePulses: averages}, () => {
            this.getPulseAverages(averages)
        });
        
    }

    getPulseAverages = (averages) => {
        const totalAverage = averages.length > 0 && averages.reduce((acc, curr) => acc + curr) / averages.length;
        const roundAverages = Math.round((totalAverage + Number.EPSILON) * 100) / 100

        this.setState({ totalAverage: roundAverages })
    }

    // Send reminder email to everyone who hasent completed it
    massEmailReminder = async () => {
        try {
            await authAxios.get(`${pulseBaseURL}/manual-email-send/all-users`)
            return console.log('Emails Sent')
        } catch(err) {
            console.error(err)
            throw err;
        }
    }

    clearPulseProvider = () => {
        this.setState(defaultPulseState)
    }

    render(){
        return (
            <PulseContext.Provider 
                value={{
                    ...this.state,
                    getUsersPulseHistory: this.getUsersPulseHistory,
                    getUrlPulse: this.getUrlPulse,
                    getDepartmentsAvailableWeeks: this.getDepartmentsAvailableWeeks,
                    nextPage: this.nextPage,
                    previousPage: this.previousPage,
                    updatePulseScore: this.updatePulseScore,
                    addPulseQuestion: this.addPulseQuestion,
                    pulseOnChange: this.pulseOnChange,
                    submitSurvey: this.submitSurvey,
                    clearSurvey: this.clearSurvey,
                    getWeeksPulses: this.getWeeksPulses,
                    isViewingAnalytics: this.isViewingAnalytics,
                    getPulseAverages: this.getPulseAverages,
                    getPulseAnalytics: this.getPulseAnalytics,
                    getAnalyticsData: this.getAnalyticsData,
                    markEmployeesReviewed: this.markEmployeesReviewed,
                    clearPulseProvider: this.clearPulseProvider,
                    sendPulseReminderEmail: this.sendPulseReminderEmail,
                    massEmailReminder: this.massEmailReminder,
                    filterPulses: this.filterPulses,
                    updateAvailableWeeks: this.updateAvailableWeeks,
                    updateAvailableDepartments: this.updateAvailableDepartments,
                    updateLoggedInUsersPulseInfo: this.updateLoggedInUsersPulseInfo,
                    createInitialPulse: this.createInitialPulse,
                    updateInitialDepartments: this.updateInitialDepartments,
                }}>
                { this.props.children }
            </PulseContext.Provider>
        )
    }
}

export default PulseProvider

export const withPulse = C => props => (
    <PulseContext.Consumer>
        {value => <C {...props} {...value}/>}
    </PulseContext.Consumer>
)
