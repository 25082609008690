import { useState } from 'react'
import { withSnapshots } from '../../../Context/SnapshotProvider'
import { useCurrentWidth } from '../../../shared/CustomHooks/useCurrentWidth'

const SnapshotQuizQuestions = ({ 
    question, 
    type, 
    questionNumber, 
    snapshotRecipientName, 
    handleQuestionClick, 
    handleSnapshotQuestionsChange
}) => {
    const [selectedOption, setSelectedOption] = useState(null)
    const windowWidth = useCurrentWidth()

    let isMobile = windowWidth <= 600

    let scoringScale = 10
    let scoringOptions = []
    for( let i = 1; i <= scoringScale; i++) scoringOptions.push(i)


    let mappedAnswerDivs = scoringOptions.map( option => {
        let className = 'lms-snapshot-answer-option'

        let mobileDescription;
        switch(option){
            case 1:
                mobileDescription = '(Almost Never)'
                break;
            case 4:
                mobileDescription = '(Not Consistently)'
                break;
            case 7:
                mobileDescription = '(Somewhat Consistently)'
                break;
            case 10:
                mobileDescription = '(Almost Always)'
                break;
            default:
                break;
        }

        if(selectedOption == option) className = 'lms-snapshot-answer-option-selected'
        return (
            <button 
                key={option}
                className={className}
                id={option}
                onClick={(e) => setSelectedAnswer(e)}
            >
                {option}
                {isMobile && 
                    <p>{mobileDescription}</p>
                }
            </button>
        )
    })

    question = question.replace(/<NAME_HERE>/, snapshotRecipientName )
    let questionName = `question${questionNumber}`

    const setSelectedAnswer = (e) => {
        setSelectedOption(e.target.id)
        handleQuestionClick(questionName, parseInt(e.target.id))
    }


    return (
        <div className='lms-snapshot-question-wrapper'>
            {
                type === 'radio' ? 
                <>
                    <div>
                        <p className='lms-snapshot-question-text'> 
                            {question} 
                        </p>
                    </div>
                    <div className='lms-snapshot-answer-wrapper'>
                        <div className='lms-snapshot-answer-container'>
                            {mappedAnswerDivs}
                        </div>
                    </div>
                    <div className='lms-snapshot-answer-labels'>
                        <div className='lms-snapshot-answer-label-text'>
                            <span>|</span>
                            <span>Almost</span>
                            <span>Never</span>
                        </div>
                        <div 
                        className='lms-snapshot-answer-label-text'>
                            <span>|</span>
                            <span>Not</span>
                            <span>Consistently</span>
                        </div>
                        <div 
                        className='lms-snapshot-answer-label-text'>
                            <span>|</span>
                            <span>Somewhat</span>
                            <span>Consistently</span>
                        </div>
                        <div 
                        className='lms-snapshot-answer-label-text'>
                            <span>|</span>
                            <span>Almost</span>
                            <span>Always</span>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='snapshot-quiz-content-card-survey-question'>
                        <p> {question} </p>
                    </div>
                    <div className='snapshot-quiz-content-card-survey-answers'>
                        <textarea
                            rows={5}
                            cols={80}
                            name={`question${questionNumber}`}
                            onChange={handleSnapshotQuestionsChange}
                            required={true}
                        />
                    </div>
                </>
            }
        </div>
    )
}

export default withSnapshots(SnapshotQuizQuestions)

