function getUrlParams(windowLocationSearch) { 
    let urlParams;
    (window.onpopstate = function () {
        let match,
            pl     = /\+/g,  // Regex for replacing addition symbol with a space
            search = /([^&=]+)=?([^&]*)/g,
            decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
            query  = windowLocationSearch.substring(1);
        urlParams = {};
        while (match = search.exec(query)) //eslint-disable-line
        urlParams[decode(match[1])] = decode(match[2])
    })();
    return urlParams
}

export default getUrlParams;