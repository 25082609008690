import React, { Component } from 'react'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'
import axios from 'axios'

const baseURL = '/v1/feedback'
const FeedbackContext = React.createContext()

class FeedbackProvider extends Component {
    constructor(){
        super()
        this.state = {
            uiScore: 0,
            uxScore: 0,
            functionalityScore: 0,
            recommendationScore: 0,
            interactionType: '',
            email: '',
            positiveFeedback: '',
            negativeFeedback: '',
            requestsFeedback: '',
            feedbackSuccess: '',
            feedbackError: '',
            requiredMessage: false
        }
    }

    handleFeedbackChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: sanitizeData(value) })
    }

    setLocation = (location) => {
        let interactionType = location.slice(10)
        this.setState({ interactionType})
    }

    setUserEmail = (email) => {
        this.setState({ email: email })
    }

    setRequiredFields = (e) => {
        e.preventDefault()
        this.setState({ requiredMessage: true, feedbackError: 'Some required input fields were missed. Please fill them out and try again.' })
    }

    submitFeedback = (e) => {
        e.preventDefault()
        const data = {  uiScore: this.state.uiScore,
                        uxScore: this.state.uxScore,
                        functionalityScore: this.state.functionalityScore,
                        recommendationScore: this.state.recommendationScore,
                        interactionType: this.state.interactionType,
                        email: this.state.email,
                        positiveFeedback: this.state.positiveFeedback,
                        negativeFeedback: this.state.negativeFeedback,
                        requestsFeedback: this.state.requestsFeedback
                    }
        axios.post(`${baseURL}`, data)
        .then(() => {
            this.setState({ requiredMessage: false, feedbackError: '', feedbackSuccess : 'Thank you for your feeback.  It is very important to us!  If you asked to be contacted you can expect an email in 72 hours.'})
        })
        .catch(() => {
            this.setState({ feedbackError : 'We apologize.  Something broke while submitting your feeback.  Please try again and if the problem persists contact our IT team at development@insideoutdev.com'})
        })
    }


    render(){
        return (
            <FeedbackContext.Provider 
                value={{
                    ...this.state,
                    handleFeedbackChange: this.handleFeedbackChange,
                    setLocation: this.setLocation,
                    setUserEmail: this.setUserEmail,
                    setRequiredFields: this.setRequiredFields,
                    submitFeedback: this.submitFeedback,
                }}>
                { this.props.children }
            </FeedbackContext.Provider>
        )
    }
}

export default FeedbackProvider

export const withFeedback = C => props => (
    <FeedbackContext.Consumer>
        {value => <C {...props} {...value}/>}
    </FeedbackContext.Consumer>
)