import { useState, useEffect } from 'react'
import { Redirect, Route, useLocation, useParams } from "react-router-dom"
import { withAuth } from '../../Context/AuthProvider'
import ErrorBoundary from '../ErrorBoundary'
import decode from 'jwt-decode'
import GlobalHeader from '../../GlobalComponents/MainHeader/GlobalHeader'
import Footer from '../../GlobalComponents/Footer/Footer'

const ProtectedRouteV2 = ({ component: Component, token, logOut, user, setCommunityAccess }) => {
    const [community, setCommunity] = useState('insideout')
    let decodedJwt = null;
    let canAccessModules = false
    const location  = useLocation()
    const fromQuery = new URLSearchParams(location.search).get('from')
    
    const checkAccessRights = (user) => {
        if (
            user.isIodSuperAdmin ||
            user.hasA2b ||
            user.hasCoaching ||
            user.hasBreakthroughs ||
            user.hasFacilitator ||
            user.hasIocDf ||
            user.hasLmsSnapshots ||
            user.hasGrowCoaching
        ) {
            canAccessModules = true
        } else {
            canAccessModules = false
        }
    }

    // display correct nav options
    const checkCommunity = () => {
        let path = location.pathname
        if(path === '/' || path === '/dashboard'){
            setCommunity('insideout')
        }
        if(path.includes('participant-portal') || fromQuery === 'participant-portal'){
            setCommunity('participant-portal')
        }
        if(path.includes('a2b') || fromQuery === 'a2b'){
            setCommunity('a2b')
        }
    }
    
    // check if the user has a valid token
    if(token) {
        decodedJwt = decode(token)
        // check if token should be expired
        if(decodedJwt.exp < Date.now() / 1000) {
            logOut()
            canAccessModules = false
        } else {
            // set the access rights the user has
            checkAccessRights(decodedJwt.user)
        }
    } else {
        decodedJwt = null;
    }

    // keep admin rights and community access on refresh
    useEffect(() => {
        if(!user.id && decodedJwt) {
            setCommunityAccess(decodedJwt.user)
            // checkIfUsersCompanyAllowsSnapshots(decodedJwt.user.companyId)
        }
        checkCommunity()
    }, [decodedJwt])

    return (
        <Route
            render={(props) =>
                <ErrorBoundary>
                    {/* check to make sure the user has access to at least one community */}
                    {canAccessModules ? 
                        <>
                            <GlobalHeader community={community} isPrework={location.pathname.includes('prework')} />
                            <Component {...props} /> 
                            <Footer />
                        </>
                        : 
                        <Redirect to={{
                            pathname: '/portal/login',
                            state: { from: location }
                        }} />
                    }
                </ErrorBoundary>
            }
        />
    )
}

export default withAuth(ProtectedRouteV2)
